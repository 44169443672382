<!-- Enfermero puede cargar un usuario -->
<div *ngIf="identity">
    <form #createSectorForm="ngForm" (ngSubmit)="onSubmit()">
        <div class="card">
            <div class="card-header">
                <div class="page-head py-3">
                    <h3>{{title}}</h3>
                </div>
            </div>
            <div class="card-body">
                <div class="alert alert-danger" *ngIf="errorMessage">
                    {{errorMessage}}
                </div>

                <div class="row">
                    <div class="col d-none">
                        <div class="form-group">
                            <label for="idHospital">Hospital: {{hospital.nombre}}</label>
                            <input type="text" hidden class="form-control" #idHospital="ngModel" name="idHospital" [(ngModel)]="sector.idHospital"
                                readonly>
                        </div>
                    </div>

                    <div class="col">
                        <div class="form-group">
                            <label for="idSector">Sector:</label>
                            <input type="text" class="form-control" #idSector="ngModel" name="idSector"
                                [(ngModel)]="sector.idSector" required [attr.readonly]="this.id ? '' : null">
                            <span *ngIf="!idSector.valid && idSector.touched" class="text-danger">
                                El Sector es obligatorio
                            </span>
                        </div>
                    </div>

                    <div class="col">
                        <div class="form-group">
                            <label for="descripcion">Descripción:</label>
                            <input type="text" class="form-control" #descripcion="ngModel" name="descripcion"
                                [(ngModel)]="sector.descripcion">

                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <p>
                            <button *ngIf="!id" class="btn btn-primary" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                              <i class="fa fa-plus mr-3"></i>Cargar camas
                            </button>
                          </p>
                          <div [class]="id ? 'collapse show' : 'collapse'" id="collapseExample">
                            <div class="card card-body">
                                <div *ngIf="camas.length > 0">

                                    <p *ngFor="let c of camas; let i = index" class="my-2">
                                        <span *ngIf="!id" class="mr-5">{{c}}</span><span  *ngIf="!id" class="p-3" (click)="deleteCama(c)"><i class="fa fa-times"></i></span>
                                        <span *ngIf="id" class="mr-5">
                                          <div class="form-inline">

                                            <input type="text" value="{{c}}" class="form-control mx-3" name="idCama-{{i}}" id="idCama-{{i}}" readonly>

                                            <span class="p-3" (click)="deleteCama(c)"><i class="fa fa-times"></i></span>
                                          </div>
                                        </span>
                                    </p>

                                </div>
                                <form #camaForm="ngForm" class="form-inline" (submit)="onCamaSubmit()">
                                  <label for="idCama">Nombre cama</label>
                                  <input type="text" class="form-control mx-3" #cama="ngModel" name="cama" [(ngModel)]="idCama">
                                  <button type="submit" class="btn btn-sm btn-primary"><i class="fa fa-plus mr-3"></i>Guardar</button>

                                </form>
                                <p *ngIf="errorCama" class="text-danger">{{errorCama}}</p>
                            </div>
                          </div>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <div class="form-group d-flex justify-content-end">
                    <input type="submit" class="btn btn-primary" value="Ingresar">
                    <a [routerLink]="[ '/sectores' ]" class="btn btn-secondary ml-3">Cancelar</a>
                </div>
            </div>
        </div>

    </form>
{{createSectorForm.value | json }}<br>
{{camas | json }}

</div>

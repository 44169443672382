import { Component, OnInit } from '@angular/core';

import { UsuarioService } from 'src/app/services/usuario.service';
import { UsuarioSectorService } from 'src/app/services/usuario-sector.service';

@Component({
  selector: 'app-usuario-sector',
  templateUrl: './usuario-sector.component.html',
  styleUrls: ['./usuario-sector.component.css'],
  providers: [ UsuarioService, UsuarioSectorService]
})
export class UsuarioSectorComponent implements OnInit {

  title;
  errorMessage = '';
  usuarioSectores;
  identity;
  hospital;

  constructor(
    private _usuarioService: UsuarioService,
    private _usuarioSectorService: UsuarioSectorService
  ) { }

  ngOnInit(): void {
    this.title = 'Lista de Usuario-sector';
    this.identity = this._usuarioService.getIdentity();
    this.hospital = this._usuarioService.getHospital();
    this.getUsuarioSectores();
  }

  getUsuarioSectores(){
    this._usuarioSectorService.getAll().subscribe(
      response => {
        
        if (response['result'].data) {
          this.usuarioSectores = response['result'].data;
        }else{
          //this._usuarioService.logout()
        }
      }, error => {
        this.errorMessage = 'Error al obtener los usuarios por sector';
        console.log(error.message)
        //this._usuarioService.logout();
      }
    )
  }
}

import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router'


import { UsuarioService } from 'src/app/services/usuario.service'
import { UsuarioHospitalService} from 'src/app/services/usuario-hospital.service'

import { Usuario} from 'src/app/models/usuario'
import { UsuarioHospital} from 'src/app/models/usuario-hospital'
import { ToastrService } from 'ngx-toastr';
import { Hospital } from 'src/app/models/hospital';
import { ABMBaseService } from 'src/app/services/ABMBase.service';

@Component({
  selector: 'app-create-usuario',
  templateUrl: './create-usuario.component.html',
  styleUrls: ['./create-usuario.component.css'],
  providers: [ ABMBaseService, UsuarioService, UsuarioHospitalService, ToastrService ]
})
export class CreateUsuarioComponent implements OnInit {

  title:string;
  identity;
  usuario: Usuario;
  usuarioHospital;
  hospital: any;
  verPass:boolean;
  errorMessage;

  constructor(
    public _usuSvc: UsuarioService,
    public _abmSvc: ABMBaseService,
    private _usuarioHospitalService: UsuarioHospitalService,
    private _router: Router,
    private _toastr: ToastrService
    ) { }

  ngOnInit(): void {
    this.title = 'Carga de usuario';
    this.identity = this._usuSvc.getIdentity();
    this.usuario = new Usuario('','','','','','');
    this.hospital = this._usuSvc.getHospital();
    this.usuarioHospital = new UsuarioHospital(this.hospital.idHospital,'',1,0)
    this.verPass = false;
  }

  showPass(){
    this.verPass = !this.verPass;
  }

  onSubmit(){
    console.log('submitido');

      //Primero guarda el usuario y despues completa y guarda el usuario Hospital, donde se indica rol y estado laboral del usuario
    var verif = this.verifyUsuario(this.usuario);
    if(!this.usuario.nombre || this.usuario.nombre == ''
      || !this.usuario.apellido || this.usuario.apellido == ''
      || !this.usuario.telefono || this.usuario.telefono == ''
    ){
      this._toastr.error('Faltan datos que son obligatorios. Completar todos los datos marcados con *')
    }else{
      if(!verif.valid){
        this._toastr.error(verif.msg)
      }else{

        this._abmSvc.getAll('usuarios').subscribe(resp => {

          var usus = resp['result'].sync_id.filter(item => item.cuil == this.usuario.cuil);


          if (usus.length > 0 ) {
            this._toastr.error('Ya existe un usario con ese número de cuil')
          } else {
            console.log('usuario a cargar', this.usuario);

            this._abmSvc.create('usuario', this.usuario).subscribe(
              response => {

                this.usuario = response['result'].usuario;
                this.usuarioHospital.cuil = this.usuario.cuil;
                this.usuarioHospital.idRol = parseInt(this.usuarioHospital.idRol);

                console.log('UsuarioHospi:::', this.usuarioHospital);

                //Cuando se traigan los roles desde el formulario de carga habra que recorrer el array y guardar cada caso. Por el momento el usuario tiene solamente un rol, que por defecto sera de enfermero

                this._usuarioHospitalService.create(this.usuarioHospital).subscribe(
                  response => {
                    this._router.navigate(['usuarios'])
                  }, error => {
                    this.errorMessage = 'Error al guardar el rol del usuario'
                    console.log(error)
                  }
                );

              },error => {
                console.log(error);
                this._toastr.error(error['error'].message)
              }
            );
          }

        })


      }
    }


  }


  verifyUsuario(usuario:Usuario) {

    if(!(/^[2-9]{1}[0-9]{10}$/i).test(this.usuario.cuil)){
      return {valid:false, msg: 'El cuil no tiene un formato correcto' }
    }

    if(!(/^(?:[^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*|"[^\n"]+")@(?:[^<>()[\].,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,63}$/i).test(this.usuario.email)){
      return {valid:false, msg: 'El email no tiene un formato correcto' }
    }

    return {valid: true, msg:'Ingreso de usuario correcto'}

  }

}

<div class="card alert-card-container shadow">
    <div class="card-header bg-danger text-light text-center">
        Alertas
    </div><!-- end Card-header -->
    <div class="card-body">
        <ul class="list-group p-0">
            <li class="alerta-item-card list-group-item mb-1 shadow" *ngFor="let item of alertas">
                <span class="alert-close-btn" (click)="hideAlert(item)" style="cursor: pointer;z-index: 1000;" >&times;</span>
                <div class="row">
                    <div class="col-3">
                        <div [ngClass]='{"nulo": item.gravedadAlerta == 1, "bajo": item.gravedadAlerta == 2, "medio": item.gravedadAlerta == 3, "alto": item.gravedadAlerta == 4}' >
                            <div class='pt-4 text-center' >
                                <h3 >{{item.gravedadAlerta}}</h3>
                            </div>
                        </div>
                    </div>
                    <div class="col alert-text-container">
                        <p class="p-0 m-0"><strong>{{item.numeroHC}}</strong> </p>

                        <small>Cama: <strong>1</strong></small><br>
                        <small>Hist. Clínica: <strong>{{item.numeroHC}}</strong></small><br>
                        <small>Fecha: <strong>{{item.fechaAlerta | date:'dd-MM-yyyy HH:mm:ss'}}</strong></small><br>
                        <small>Revisión cada 12 horas</small>
                        <br>
                        <div class="no-actual-tag" *ngIf="item.actual==0">No-Actual</div>
                    </div>
                </div>
            </li>
        </ul>

    </div><!-- end Card-body -->
</div><!-- end Card Alertas -->

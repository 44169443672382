<div class="container" *ngIf="identity">
    <div class="card">
        <div class="card-header">
            <h3>{{title}}</h3>
        </div>
        <div class="card-body">
            <div class="alert alert-danger" *ngIf="errorMessage" style="float: right;top:40px;right: 40px">
                {{errorMessage}}
            </div>
            <div *ngIf="!paciente">
                <div class="form-inline">
                    <div class="input-group mb-2 mr-sm-2">
                        <label for="filtrarPaciente" class="mr-2">Buscar Paciente</label>
                        <input type="text" class="form-control" placeholder="Buscar..." name="filtrarPaciente"
                            id="filtrarPaciente" [(ngModel)]="filtrarPaciente">

                        <div class="input-group-append">
                            <div class="input-group-text"><i class="fas fa-search"></i></div>
                        </div>
                    </div>
                </div>
                <div class="paciente-list-container">
                    <ul class="list-group">
                        <a (click)="selPaciente(paciente)" class="paciente-list"
                            *ngFor="let paciente of pacientes | filterPaciente:filtrarPaciente ">
                            <li class="list-group-item paciente-list">
                                {{paciente.apellido}} {{paciente.nombre}} - {{paciente.dni}}
                            </li>
                        </a>
                    </ul>
                </div>
            </div><!-- /Seleccion paciente -->
            <div *ngIf="paciente">
                <div class="">

                    <app-detalle-paciente [paciente]="paciente"></app-detalle-paciente>

                    <form #createRxForm="ngForm" (ngSubmit)="onSubmit(createRxForm.value)">
                        <div class="card">
                            <div class="card-body">
                                <div class="form-group">
                                	<label for="resultadoRad">Resultados<span class="text-danger">*</span></label>


                                    <div class="form-group ">

                                        <div class="form-check ">
                                            <input class="form-check-input" type="radio"
                                                name="resultadoRad" [(ngModel)]="rxTorax.resultadoRad"
                                                value="infiltrado bilateral" id="resultadoRad1">
                                            <label class="form-check-label" for="resultadoRad1">Infiltrado bilateral</label>
                                        </div>

                                        <div class="form-check ">
                                            <input class="form-check-input" type="radio"
                                                name="resultadoRad" [(ngModel)]="rxTorax.resultadoRad"
                                                value="Infiltrado unilateral" id="resultadoRad2">
                                            <label class="form-check-label" for="resultadoRad2">Infiltrado unilateral</label>
                                        </div>

                                        <div class="form-check ">
                                            <input class="form-check-input" type="radio"
                                                name="resultadoRad" [(ngModel)]="rxTorax.resultadoRad"
                                                value="Consolidación bilateral" id="resultadoRad3">
                                            <label class="form-check-label" for="resultadoRad3">Consolidación bilateral</label>
                                        </div>

                                        <div class="form-check ">
                                            <input class="form-check-input" type="radio"
                                                name="resultadoRad" [(ngModel)]="rxTorax.resultadoRad"
                                                value="Consolidación unilateral" id="resultadoRad4">
                                            <label class="form-check-label" for="resultadoRad4">Consolidación unilateral</label>
                                        </div>
                                        <div class="form-check ">
                                            <input class="form-check-input" type="radio"
                                                name="resultadoRad" [(ngModel)]="rxTorax.resultadoRad"
                                                value="Sin infiltrado"  id="resultadoRad5">
                                            <label class="form-check-label" for="resultadoRad5">Sin infiltrado</label>
                                        </div>

                                    </div>


	                            </div>

                            </div>
                            <div class="card-footer">
                                <div class="form-group">
                                    <input type="submit" class="btn btn-primary" value="Ingresar">
                                    <button (click)="cancelCarga()" class="btn btn-secondary ml-3">Cancelar</button>
                                </div>
                                <p class="text-secondary"><small>Los datos identificados con <span
                                            class="text-danger">*</span> son obligatorios</small></p>
                            </div>
                        </div><!-- /Card -->
                    </form>

                </div>

            </div><!-- /Datos paciente -->


        </div><!-- /Card-body -->
    </div><!-- /Card -->


</div>

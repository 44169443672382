export class Usuario {
    constructor(
        public cuil: string,
        public nombre: string,
        public apellido: string,
        public email: string,
        public clave: string,
        public telefono: string,
        public estado?: any,
        public extra?: any
    ){}
}

<!-- Enfermero puede cargar un paciente -->
<div *ngIf="identity">
    <form #createPacienteForm="ngForm" (ngSubmit)="onSubmit(createPacienteForm.value)" *ngIf="paciente">
        <div class="card">
            <div class="card-header">
                <div class="page-head">
                    <h3>{{title}}</h3>
                </div>
            </div>
            <div class="card-body">
                <div class="alert alert-danger" *ngIf="errorMessage">
                    {{errorMessage}}
                </div>
                <div class="row">
                    <div class="form-group  col-lg-2 col-md-3 col-sm-6 col-6">
                        <label for="numeroHC">Hist.Clínica<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" #numeroHC="ngModel" name="numeroHC"
                            [(ngModel)]="paciente.numeroHC" required>
                        <span *ngIf="!numeroHC.valid && numeroHC.touched" class="text-danger">
                            El N° de historia clínica es obligatorio
                        </span>
                    </div>

                    <div class="form-group  col-lg-2 col-md-4 col-sm-6 col-6"><label for="tipoDocumento">Tipo
                            Doc.<span class="text-danger">*</span></label>
                        <select name="tipoDocumento" #tipoDocumento='ngModel' [(ngModel)]='paciente.tipoDocumento'
                            class="form-control">
                            <option value="DNI">DNI</option>
                          <!--   <option value="Cedula">Cédula</option>
                            <option value="LC">Libreta Cívica</option> -->
                            <option value="Pasaporte">Pasaporte</option>
                        </select>
                    </div>

                    <div class="form-group  col-lg-2 col-md-5 col-sm-6 col-xs-12">
                        <label for="dni">DNI<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" #dni="ngModel" name="dni"
                            [(ngModel)]="paciente.dni" required>
                        <!-- <span *ngIf="!dni.valid && dni.touched" class="text-danger">
                    El dni es obligatorio
                </span> -->
                    </div>

                    <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <label for="paisExp">País de Exp.<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" #paisExp="ngModel" name="paisExp"
                            [(ngModel)]="paciente.paisExp" required>
                        <!-- <span *ngIf="!paisExp.valid && paisExp.touched" class="text-danger">
                    El nombre es obligatorio
                </span> -->
                    </div>

                    <div class="form-group col-lg-3 col-md-6 offset-md-0 col-sm-6 offset-sm-3 col-xs-12">
                        <label for="fechaNac">F. Nacimiento<span class="text-danger">*</span></label>
                        <input type="date" class="form-control" #fechaNac="ngModel" name="fechaNac"
                            [(ngModel)]="paciente.fechaNac" required>
                        <span *ngIf="!fechaNac.valid && fechaNac.touched" class="text-danger">
                            La fecha de nacimiento es obligatoria
                        </span>
                    </div>
                </div>


                <div class="row">
                    <div class="form-group col-lg-3 col-md-6 col-12">
                        <label for="nombre">Nombres<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" #nombre="ngModel" name="nombre"
                            [(ngModel)]="paciente.nombre" required>
                        <span *ngIf="!nombre.valid && nombre.touched" class="text-danger">
                            El nombre es obligatorio
                        </span>
                    </div>
                    <div class="form-group col-lg-3 col-md-6 col-12">
                        <label for="apellido">Apellidos<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" #apellido="ngModel" name="apellido"
                            [(ngModel)]="paciente.apellido" required>
                        <span *ngIf="!apellido.valid && apellido.touched" class="text-danger">
                            El apellido es obligatorio
                        </span>
                    </div>

                    <div class="form-group col-lg-3 col-6">
                        <label for="nacionalidad">Nacionalidad<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" #nacionalidad="ngModel" name="nacionalidad"
                            [(ngModel)]="paciente.nacionalidad" required>
                        <span *ngIf="!nacionalidad.valid && nacionalidad.touched" class="text-danger">
                            El nacionalidad es obligatorio
                        </span>
                    </div>

                    <div class="form-group col-lg-3 col-6">
                        <label for="genero">Sexo Biológico<span class="text-danger">*</span></label>
                        <select name="genero" #genero='ngModel' [(ngModel)]='paciente.genero'
                            class="form-control">
                            <option value="F">Femenino</option>
                            <option value="M">Masculino</option>
                            <option value="X">Otro</option>
                        </select>
                    </div>
                </div>

                <div class="row">
                    <div class="form-group col-12 col-sm-9 col-lg-6">
                        <label for="calle">Calle<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" #calle="ngModel" name="calle"
                            [(ngModel)]="paciente.calle" required>
                        <span *ngIf="!calle.valid && calle.touched" class="text-danger">
                            El calle es obligatorio
                        </span>
                    </div>

                    <div class="form-group col-4 col-sm-3 col-lg-2">
                        <label for="numero">Número<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" #numero="ngModel" name="numero"
                            [(ngModel)]="paciente.numero" required>
                        <span *ngIf="!numero.valid && numero.touched" class="text-danger">
                            El numero es obligatorio
                        </span>
                    </div>


                    <div class="form-group col-4 offset-sm-2 col-lg-2 offset-lg-0">
                        <label for="piso">Piso y Dpto:</label>
                        <input type="text" class="form-control" #piso="ngModel" name="piso"
                            [(ngModel)]="paciente.piso">
                        <!-- <span *ngIf="!piso.valid && piso.touched" class="text-danger">
                    El piso y dpto es obligatorio
                </span> -->
                    </div>

                    <div class="form-group col-4 col-lg-2">
                        <label for="CP">Cod.postal<span class="text-danger">*</span></label>
                        <input type="text" class="form-control" #CP="ngModel" name="CP" [(ngModel)]="paciente.CP"
                            required>
                        <span *ngIf="!CP.valid && CP.touched" class="text-danger">
                            El código postal es obligatorio
                        </span>
                    </div>
                </div>

                <div class="row">

                  <div class="form-group col-12 col-sm-6 col-lg-4">
                    <label for="id_provincia">Provincia</label>
                    <select class="form-control" #id_provincia="ngModel" name="id_provincia"[(ngModel)]="paciente.id_provincia" (change)="onProvinciaChange(paciente.id_provincia)">
                      <option *ngFor="let provincia of provincias" value="{{provincia.id_provincia}}">{{provincia.nombre || 'Seleccionar...'}}</option>
                    </select>

                  </div>
                  <div class="form-group col-12 col-sm-6 offset-sm-3 col-lg-4 offset-lg-0">
                    <label for="id_loc">Localidad</label>
                    <select class="form-control" #id_loc="ngModel" name="id_loc"[(ngModel)]="paciente.id_loc" [disabled]="localidadesPorProvincia.length == 0">
                      <option *ngFor="let localidad of localidadesPorProvincia, let i = index" value="{{localidad.id_loc}}" [selected]="i == 0">{{localidad.nombre}}</option>
                    </select>

                  </div>

                </div>

                <div class="row">
                    <div class="form-group col-12 col-sm-6 col-lg-4">
                        <label for="telefono">Teléfono<span class="text-danger">*</span></label>
                        <input type="tel" class="form-control" #telefono="ngModel" name="telefono"
                            [(ngModel)]="paciente.telefono" required>
                        <span *ngIf="!telefono.valid && telefono.touched" class="text-danger">
                            El teléfono es obligatorio
                        </span>
                    </div>

                    <div class="form-group col-12 col-sm-6 col-lg-4">
                        <label for="telefono_familiar_1">Teléfono Familiar 1:</label>
                        <input type="tel" class="form-control" #telefono_familiar_1="ngModel" name="telefono_familiar_1"
                            [(ngModel)]="paciente.telefonoFamiliar">

                    </div>

                    <div class="form-group col-12 col-sm-6 offset-sm-3 col-lg-4 offset-lg-0">
                        <label for="telefono_familiar2">Teléfono Familiar 2:</label>
                        <input type="tel" class="form-control" #telefono_familiar_2="ngModel" name="telefono_familiar_2"
                            [(ngModel)]="paciente.telefonoFamiliar2">

                    </div>
                </div>


            </div>
            <div class="card-footer">
                <div class="form-group">
                    <input type="submit" class="btn btn-primary" value="Ingresar">
                    <a [routerLink]="[ '/pacientes' ]" class="btn btn-secondary ml-3">Cancelar</a>
                </div>
                <p class="text-secondary"><small>Los datos identificados con <span class="text-danger">*</span> son obligatorios</small></p>
            </div>
        </div>
    </form>

    {{paciente | json   }}

</div>

import { Component, OnInit, Input } from '@angular/core';

import { Paciente } from 'src/app/models/paciente';
import { ABMBaseService } from 'src/app/services/ABMBase.service';
import { UsuarioService } from 'src/app/services/usuario.service'

@Component({
  selector: 'app-detalle-paciente',
  templateUrl: './detalle-paciente.component.html',
  styleUrls: ['./detalle-paciente.component.css'],
  providers: [ ABMBaseService, UsuarioService ]
})
export class DetallePacienteComponent implements OnInit {

	@Input() paciente: Paciente;
	edad: number;
	identity;

	constructor(
  		private _abmSvc: ABMBaseService,
  		private _usuarioService: UsuarioService
	) { }

	ngOnInit(): void {
		this.edad = this._abmSvc.getEdad(this.paciente.fechaNac);
		this.identity = this._usuarioService.getIdentity();
	}

}

import { Component, OnInit } from '@angular/core';

import { UsuarioService } from 'src/app/services/usuario.service';

import { Router, ActivatedRoute, Params } from '@angular/router';
import { UsuarioHospitalService } from 'src/app/services/usuario-hospital.service';
import { Usuario } from 'src/app/models/usuario';
import { UsuarioHospital } from 'src/app/models/usuario-hospital';
import { Hospital } from 'src/app/models/hospital';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-edit-usuario',
  templateUrl: './edit-usuario.component.html',
  styleUrls: ['./edit-usuario.component.css'],
  providers: [ UsuarioService ]
})
export class EditUsuarioComponent implements OnInit {

	title: string;
	cuil: string;
	usuario: Usuario;
	usuarios: Usuario[];
	usuarioHospital: UsuarioHospital;
	identity: Usuario;
	hospital: Hospital;
	errorMessage: string;
	verPass:boolean = false;
	passPropia:boolean = false;
  	confirm:boolean

  constructor(
	private _usuarioService: UsuarioService,
	private _usuarioHospitalService: UsuarioHospitalService,
	private _activatedRoute: ActivatedRoute,
	  private _router: Router,
	  private _toastr: ToastrService
  ) { }

  ngOnInit(): void {
  	this.title = 'Editar Usuario';
	this.identity = this._usuarioService.getIdentity();
	this.hospital = this._usuarioService.getHospital();
  	this.cuil = this._activatedRoute.snapshot.params.cuil;
  	this.getUsuario();

  }

  getUsuario(){
	  
	this._usuarioService.getAll().subscribe(
		response => {
			this.usuarios = response['result'].sync_id;
			this.usuario = this.usuarios.filter(item => item.cuil === this.cuil)[0];
			this._usuarioHospitalService.getAll().subscribe(
				res => {
					
					if(res['result'].data.filter(item => item.cuil === this.usuario.cuil)[0]){
					this.usuarioHospital = res['result'].data.filter(item => item.cuil === this.usuario.cuil)[0];
					console.log('UsuHospi=>',this.usuarioHospital.estadoLaboral);

					}else{
						this._toastr.warning('El usuario no tiene datos suficientes para editarlo')
						this._router.navigate(['/usuarios'])
					}
					//Adaptar el estado laboral para que refleje lo correcto
					
				}, error => {
					this.errorMessage = 'Error al obtener el usuario';
        			console.log(error.message);
				}
			)
				
		}, error => {
			this.errorMessage = 'Error al obtener el usuario';
        	console.log(error.message);
		}
	)

  }


  onSubmit(){
  	this.confirm = true;
    console.log(this.usuario)
    if (this.usuarioHospital.estadoLaboral === 2) {
    	this.confirm = confirm('Está seguro que el usuario no trabaja mas en esta entidad?')

	}
	
    if (this.confirm) {
		alert('Generar la edición del usuario con Rodrigo');
    	/* this._usuarioService.editUsuario(this.usuario).subscribe(
			response => {
				this._router.navigate(['/usuarios'])
			},error => {
				this.errorMessage = <any>error.message;
				if (this.errorMessage != null) {
				console.log(error);
				} 
			} 
		);*/

    }
    
  }

  showPass(){
  	this.verPass = !this.verPass
  }
}

import { Component, OnInit } from '@angular/core';

import { Isla } from 'src/app/models/isla';
import { ABMBaseService } from 'src/app/services/ABMBase.service';
import { IslaService } from 'src/app/services/isla.service'
import { SectorService } from 'src/app/services/sector.service';
import { UsuarioService } from 'src/app/services/usuario.service'

@Component({
  selector: 'app-isla',
  templateUrl: './isla.component.html',
  styleUrls: ['./isla.component.css'],
  providers: [UsuarioService, SectorService, IslaService]
})
export class IslaComponent implements OnInit {

  public title: string;
  public identity;
  public islas;
  public sectores;
  public errorMessage: string = '';
  public hospital;

  //Paginator
  public page: any;
  public itemsPerPage: number;
  public totalItems: number;
  public previousPage: any;
  public verPaginator: boolean = false;

  constructor(
    private _usuarioService: UsuarioService,
    private _abmBaseSvc: ABMBaseService
  ) { }

  ngOnInit(): void {
    this.title = 'Listado de Islas'
    this.identity = this._usuarioService.getIdentity();
    this.hospital = this._usuarioService.getHospital();
    this.getIslas();

    this.page = 1;
    this.itemsPerPage = 15;
  }

  getIslas(){
    this._abmBaseSvc.getAll('islas').subscribe(
      response => {

        if (response['result'].data) {

          this.islas = response['result'].data;
          this.totalItems = this.islas.length;
          if(this.totalItems > this.itemsPerPage){this.verPaginator = true}
          this._abmBaseSvc.getAll('sectores').subscribe(
            resp => {
              this.sectores = resp['result'].data;
              this.islas.forEach(isla => {
                isla.sectores = this.sectores.filter(item => item.idIsla == isla.idIsla)
              });
            }, error => {
              console.log(error);
              
            }
          )

        }else{

          this.errorMessage = 'No se encontraron islas';
          //this._usuarioService.logout()
          
        }
      }, error => {
        this.errorMessage = 'Error al obtener las islas';
        console.log(error.message)
        //this._usuarioService.logout();
      }
    )
  }

  confirmDel(isla){
    let borrar = confirm('Seguro que quiere borrar esta isla?');
    if (borrar) {
      alert(`Generar el borrado de la isla. ${isla.idIsla} borrada`)
    }
  }

}

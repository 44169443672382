import { Component, OnInit } from '@angular/core';

import * as Highcharts from 'highcharts';
import { isNull } from 'lodash';
import { Observable } from 'rxjs';
import { Alerta } from 'src/app/models/alerta';

import { Episodio } from 'src/app/models/episodio';
import { ABMBaseService } from 'src/app/services/ABMBase.service';
import { AlertaService } from 'src/app/services/alerta.service';

@Component({
  selector: 'app-estado-x-paciente',
  templateUrl: './estado-x-paciente.component.html',
  styleUrls: ['./estado-x-paciente.component.css']
})
export class EstadoXPacienteComponent implements OnInit {

  Highcharts: typeof Highcharts = Highcharts;

  chartOptions: Highcharts.Options
  public episodios: Episodio[];
  public alertas: Alerta[];
  public alerta$: Observable<Alerta[]>;

  /* Traer los episodios y graficar cuantos pacientes abiertos y cuantos cerrados hay en el sector */
  constructor(
    private _abmBaseSvc: ABMBaseService,
    private _alertaSvc: AlertaService,
  ) { }

  ngOnInit(): void {
    this.alerta$ = this._alertaSvc.getAlertas$();
    this.alerta$.subscribe( alertas => {
      this.alertas = alertas;
      this.setDataAlertas();
    });
    // this.setDataAlertas();
  }

  setDataAlertas(): void {
    var gravedades: number[] = [];
    let total = this.alertas.length;
    if(total > 0 ) {

      gravedades.push(this.alertas.filter(item => item.gravedadAlerta == 1).length *100 / total);
      gravedades.push(this.alertas.filter(item => item.gravedadAlerta == 2).length *100 / total);
      gravedades.push(this.alertas.filter(item => item.gravedadAlerta == 3).length *100 / total);
      gravedades.push(this.alertas.filter(item => item.gravedadAlerta == 4).length *100 / total);


      console.log('Gravedades', gravedades);


      this.chartOptions  = {

        // colors: Highcharts.map(Highcharts.getOptions().colors, function (color) {
        colors: Highcharts.map(['rgb(25, 158, 7)', 'rgb(255, 255, 00)','rgb(255, 132, 16)', 'rgb(187, 14, 14)'], function (color) {
          return {
              radialGradient: {
                  cx: 0.5,
                  cy: 0.3,
                  r: 0.7
              },
              stops: [
                [0, color],
                [1, Highcharts.color(color).brighten(-0.5).get('rgb')], // darken
              ]
          };
        }),
          series: [{
            data: [{name:'Nula',y: gravedades[0]}, {name:'Baja',y:gravedades[1]}, {name:'Media',y:gravedades[2]}, {name:'Alta',y:gravedades[3]}],
            type: 'pie'
          }],
          title: {
            text:'Alertas por Gravedad'
          },
          plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                    connectorColor: 'silver'
                }
            }
        }
      };

    }

  }


}

<!-- Enfermero puede cargar un paciente -->
<div *ngIf="identity">
  <form #editEpisodioForm="ngForm" (ngSubmit)="onSubmit()" class="form-inline">
    <div class="card">
      <div class="card-header">
        <div class="page-head">
          <h3>{{title}}</h3>
        </div>
      </div>
      <div class="card-body">
        <div class="alert alert-danger" *ngIf="errorMessage">
          {{errorMessage}}
        </div>

     
          <!-- Datos paciente -->

          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col">
                  <p><strong>Apellido y Nombre: </strong>{{paciente.apellido}} {{paciente.nombre}}</p>
                  <p><strong>Historia Clínica: </strong>{{paciente.numeroHC}}</p>
                  <p><strong>Dirección: </strong>{{paciente.calle}} Nro: {{paciente.numero}}</p>
                  <p *ngIf="paciente.piso != ''"><strong>Piso y departamento:</strong>
                    {{paciente.piso}},</p>
                  <p><strong>CP:</strong> {{paciente.CP}}</p>

                </div>
                <div class="col border-left">
                  <p><strong>Fecha de nacimiento: </strong>{{paciente.fechaNac}}</p>
                  <p><strong>Nacionalidad: </strong>{{paciente.nacionalidad}}</p>
                  <p><strong>Sexo Biológico: </strong>{{paciente.genero}}</p>
                </div>
                <div class="col border-left">
                  <p><strong>Edad: </strong>{{edad}}</p>

                  <p><strong>Teléfono:</strong> {{paciente.telefono}}</p>
                  <p *ngIf="paciente.telefono_familiar_1 != ''"><strong>Teléfono familiar 1:</strong>
                    {{paciente.telefono_familiar_1}}</p>
                  <p *ngIf="paciente.telefono_familiar_2 != ''"><strong>Teléfono familiar 2:</strong>
                    {{paciente.telefono_familiar_2}}</p>

                </div>
              </div>

            </div>
          </div><!-- /Datos paciente -->

        <div class="row">
          <div class="form-group col justify-content-end mt-3">
            <label for="razon">Razón del Cierre:</label>
            <select name="razon" #razon='ngModel' [(ngModel)]='episodio.razon' placeholder=""
              class="form-control w-50 ml-2">
              <option value="" class="text-secondary">Seleccionar razon...</option>
              <option value="{{razon}}" *ngFor="let razon of razones">{{razon}}</option>
            </select>
          </div>
        </div>


      </div>
      <div class="card-footer">
        <div class="form-group d-flex justify-content-end">
          <input type="submit" class="btn btn-primary" value="Aceptar" [disabled]="episodio.razon == ''">
          <a [routerLink]="[ '/episodios' ]" class="btn btn-secondary ml-3">Cancelar</a>
        </div>
      </div>
    </div>
  </form>
</div>
import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class CamaService {

  url: string;
  headers;
  token;

  constructor(
    private _http: HttpClient
  ) {
    this.url = GLOBAL.url;
    this.token = localStorage.getItem('token');
    this.headers = {
      'content-type': 'application/json'
    };
  }

  getAll() {

    var data = {
      "version": "0.0",
      "id": Math.floor((Math.random() * 10000) + 1),
      "params": {
        "sync_id": 0
      },
      "method": "get_camas",
      "token": this.token
    }
    
    return this._http.post(this.url, data, { headers: this.headers }).pipe(map(res => res));
  }

  getById(idHospital: number, idIsla: string, idSector: string, id_cama: string) {
    return this._http.get(this.url + 'cama/' + idHospital + '/' + idIsla + '/' + idSector + '/' + id_cama, { headers: this.headers }).pipe(map(res => res));
  }

  create(model) {

    var data = {
      "version": "0.0",
      "method": "new_cama",
      "params": {
        "data": {
          "idHospitalCama": model.idHospitalCama,
          "idIsla": model.idIsla,
          "idSector": model.idSector,
          "idCama": model.idCama,
          "numeroHCPac": model.numeroHCPac,
          "ubicacionX": null,
          "ubicacionY": null,
          "orientacion": null
        }
      },
      "id": Math.floor((Math.random() * 10000) + 1),
      "token": this.token
    }

    return this._http.post(this.url, data, { headers: this.headers }).pipe(map(res => res));
  }
  update(model) {

    var data = {
      "version": "0.0",
      "method": "modify_cama",
      "params": {
        "data": {
          "idHospitalCama": model.idHospitalCama,
          "idIsla": model.idIsla,
          "idSector": model.idSector,
          "idCama": model.idCama,
          "numeroHCPac": model.numeroHCPac,
          "ubicacionX": model.ubicacionX,
          "ubicacionY": model.ubicacionY,
          "orientacion": model.orientacion
        }
      },
      "id": Math.floor((Math.random() * 10000) + 1),
      "token": this.token
    }

    return this._http.post(this.url, data, { headers: this.headers }).pipe(map(res => res));
  }


  }

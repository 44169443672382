import { Component, OnInit } from '@angular/core';


import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { UsuarioService } from '../../services/usuario.service';
import { ToastrService } from 'ngx-toastr';
import { Paciente } from 'src/app/models/paciente';
import { Episodio } from 'src/app/models/episodio';
import { Router } from '@angular/router';
import { ABMBaseService } from 'src/app/services/ABMBase.service';
import { Cama } from 'src/app/models/cama';

declare var $:any;

@Component({
  selector: 'app-paciente',
  templateUrl: './paciente.component.html',
  styleUrls: ['./paciente.component.css'],
  providers: [UsuarioService, ABMBaseService, ToastrService]
})
export class PacienteComponent implements OnInit {

  public title;
  public pacientes;
  public todosPacientes;
  public identity;
  public token;
  public errorMessage;
  public filtrarPaciente = '';
  public orderAsc: boolean;
  public orderColumn: string;
  public orderDirection: string;

  public totalItems:number;
  public page: any;
  public itemsPerPage;
  public showPag: boolean = false;

  public episodios: Episodio[];
  public losEpisodios: any[];
  public unPaciente: Paciente;
  public numeroHC: string;
  public camas: Cama[];

  public verTodosPacientes: boolean = false;

  constructor(
    public _usuSvc: UsuarioService,
    private _abmSvc: ABMBaseService,
    private _toastr: ToastrService,
    private _modal: NgbModal,
    private _router: Router
  ){}

  ngOnInit(): void {

    this.title = 'Listado de Pacientes Internados';
    this.identity = this._usuSvc.getIdentity();
    this.token = this._usuSvc.getToken();

    this.getPacientes();


    this.itemsPerPage = 15;
    this.page = 1;
  }

  /* Obtiene todos los pacientes cargados */
  getPacientes(){
    this._abmSvc.getAll('hcpacientes').subscribe(
      response => {
        // console.log('respuesta de pacientes=>',response);

        if(response['result'].data){
          this.todosPacientes = response['result'].data.sort((a, b) => {
            let resp;
            (a.sync_id < b.sync_id) ? resp = 1 : resp = -1;
            return resp;
          });
          this.totalItems = this.todosPacientes.length
          if(this.totalItems > this.itemsPerPage) this.showPag = true;

          this.getCamas();
        }else{
          //this._usuSvc.logout();
          this._toastr.warning('No se pueden obtener los pacientes')
        }

      },error => {

        console.log(error.message);
        //this._usuSvc.logout();
        this._toastr.error('Error al buscar los pacientes' + error.message)
      }
    )
  }



  getCamas(){
    this._abmSvc.getAll('camas').subscribe(
      resp => {
        // console.log('Camas=>', resp);

        this.camas = [];
        if(resp['result'].data){
          this.camas = resp['result'].data;
        }

        this.getPacientesInternados();

      }, error => {
        console.log(error)
      }
    )
  }

  getPacientesInternados(){
    var internado = [];
    if(this.camas.length > 0){

      this.todosPacientes.forEach(pac => {

        if (this.camas.filter(epi => epi.numeroHCPac == pac.numeroHC).length > 0 ) {
          internado.push(pac)
        }

      });

      this.pacientes = internado

      // console.log('Pacientes internados=>', this.pacientes);

    }else {
      this._toastr.error('Error al obtener los pacientes internados. Los pacientes mostrados son todos los registrados', 'Cuidado',{timeOut: 30000});
      this.pacientes = this.todosPacientes;
      this.title = "Listado de Pacientes Registrados"
    }
  }



  /** Ordena los datos de acuerdo a las columnas que se seleccione
  */
  order(column: string){
    this.orderAsc = !this.orderAsc;

    if(this.orderAsc){
      this.orderDirection = 'asc'
    }else{
      this.orderDirection = 'desc'
    }

    this.orderColumn = column;

  }



  showPacienteDetail(content, paciente){

      this.unPaciente = paciente;
      this.losEpisodios = this.episodios.filter(item => item.numeroHC == paciente.numeroHC);


      this._modal.open(content,{ size: 'lg' }).result.then((result) => {

        console.log(result);

      }, (reason) => {

        console.log('cerrado con ',reason);

      });

  }

  showPacienteStatus(paciente){

    this._abmSvc.getAll('alertas').subscribe(
      resp => {
        console.log('alerta desde paciente:::',resp.result.data );
        let alerta = resp.result.data.filter(item => item.numeroHC == paciente.numeroHC)[0];
        let grav: string;

        switch (alerta.gravedadAlerta) {
          case 1:
            grav = 'nula'
            this._toastr.info(`Gravedad ${grav}`,`${paciente.apellido} ${paciente.nombre}`)
            break;

          case 2:
            grav = 'baja'
            this._toastr.success(`Gravedad ${grav}`,`${paciente.apellido} ${paciente.nombre}`)
            break;

          case 3:
            grav = 'media'
            this._toastr.warning(`Gravedad ${grav}`,`${paciente.apellido} ${paciente.nombre}`)
            break;

          case 4:
            grav = 'alta'
            this._toastr.error(`Gravedad ${grav}`,`${paciente.apellido} ${paciente.nombre}`)
            break;

          default:
            break;
        }

      }, error => {
        console.log(error);
        this._toastr.error('No fue posible obtener el estado del paciente. Intente mas tarde.');

      }
    );

  }

  muestraDetallePaciente(content){

    if(this.numeroHC){
      this.unPaciente = this.pacientes.filter(item => item.numeroHC == this.numeroHC);
      this.losEpisodios = this.episodios.filter(item => item.numeroHC == this.numeroHC);

      this._modal.open(content, { size: 'lg' }).result.then((result) => {

        console.log(result);

      }, (reason) => {

        console.log('cerrado con ',reason);

      });

    }else{
      this._toastr.warning('Por favor seleccionar antes un paciente');
    }


  }


  seleccionaPaciente(id:string){
    this.numeroHC = id;
    $(`.paciente-item`).removeClass('selected');
    $(`#paciente-${id}`).addClass('selected');
  }

  abreEposidio(){
    if(this.numeroHC){

      let cama =this.camas.filter(cama => cama.numeroHCPac == this.numeroHC);
      if (cama.length > 0 ) {
        this._toastr.error('El paciente ya se encuentra interndo en la cama: ' + cama[0].idCama );
      }else{
        console.log('internar');

        // this._router.navigate(['/episodio-create', this.numeroHC])
      }


      // ARREGLAR LA CREACION DEL EPISODIO. lA RUTA NO CONTEMPLA AL PACIENTE, cambiar la ruta y la creación

    }else{
      this._toastr.warning('Por favor seleccionar antes un paciente');
    }
  }

  cierraEposidio(){
    if(this.numeroHC){
      this._router.navigate(['/episodio-close', this.numeroHC])

    }else{
      this._toastr.warning('Por favor seleccionar antes un paciente');
    }
  }

  editaPaciente(){
    this._toastr.warning('Esta opción no esta disponible en esta versión');
    if(this.numeroHC){
      this._router.navigate(['/paciente-edit', this.numeroHC])

    }else{
      this._toastr.warning('Por favor seleccionar antes un paciente');
    }

  }

  cargaComorbilidad(){
    if(this.numeroHC){
          this._router.navigate(['/paciente-comorbilidad', this.numeroHC])

    }else{
      this._toastr.warning('Por favor seleccionar antes un paciente');
    }
  }

  cargaLaboratorio(){
    if(this.numeroHC){
          this._router.navigate(['/laboratorio-create', this.numeroHC])

    }else{
      this._toastr.warning('Por favor seleccionar antes un paciente');
    }
  }

  cargaRx(){
    if(this.numeroHC){
          this._router.navigate(['/rx-torax-create', this.numeroHC])

    }else{
      this._toastr.warning('Por favor seleccionar antes un paciente');
    }
  }

  cargaControlEnfermeria(){
    if(this.numeroHC){
          this._router.navigate(['/control-enfermeria', this.numeroHC])

    }else{
      this._toastr.warning('Por favor seleccionar antes un paciente');
    }
  }

  onVerTodosChange(){

    (this.verTodosPacientes) ? this.pacientes = this.todosPacientes : this.getPacientesInternados();

  }

}

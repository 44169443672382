export class ControlEnfermeria {
    constructor(
      public id_hospital: number,
      public numeroHCSignosVitales:string,
      public fechaSignosVitales: number, // Unix time
      public auditoria: string,
      public frec_resp: number,
    	public sat_oxi: number,
    	public disnea: string,
      public oxigenoSuplementario: string,
      public fraccionInsOxigeno: number,
    	public presSist: number,
    	public presDiast: number,
    	public frec_card: number,
    	public temp: number,
      public nivelConciencia: string, // vigil / somnolencia / coma
      public sync_id?: number
    ){}
}

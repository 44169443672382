import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GLOBAL } from './global';
import { map } from 'rxjs/operators';

import { Alerta } from 'src/app/models/alerta'
import { ToastrService } from 'ngx-toastr';

import * as moment from 'moment';
import { ABMBaseService } from './ABMBase.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertaService extends ABMBaseService {

  public alertasEliminadas: Alerta[] = [];
  public alertas$ = new BehaviorSubject<Alerta[]>([]);

  constructor(
    protected _http: HttpClient,
    protected _toastr: ToastrService,
  ) {
    super(_http, _toastr);
    this.pedirAlertas();
  }

  pedirAlertas(nuevoPedido: boolean = false): void{
    if(nuevoPedido) clearInterval(intervalo);
    this.setAlertas();
    var intervalo = setInterval(() => {
      this.setAlertas();
      }, 300000)
    }

    setAlertas(): void {
      //this.alertas$.next([]);
      this.getAll('alertas').subscribe(
        resp => {

          console.log('respuesta de pedido de alertas', resp);
          console.log(Date());


          var alertasNuevas = resp.result.data;
          var alertasDisponibles: Alerta[] = alertasNuevas;
          // console.log('Alertas eliminadas', this.alertasEliminadas);

          if(this.alertasEliminadas.length > 0){
            let ids = this.alertasEliminadas.map(item => item.sync_id);
            ids.forEach(id => {
              alertasDisponibles = alertasDisponibles.filter(item => item.sync_id != id);
            });
          }
          //console.log('Pedido periodico de alerta==>',alertasDisponibles);
          this.alertas$.next(alertasDisponibles);
        },
        error => console.log(error)
      )
    }

  getAlertas$(): Observable<Alerta[]> {
    return this.alertas$.asObservable();
  }

  hideAlerta(alerta: Alerta){

    var data = {
      "version": "0.0",
      "method": `alerta_vista`,
      "params": {
        "data": {...alerta}
      },
      "id": Math.floor((Math.random() * 10000) + 1),
      "token": this.token
    }

    console.log('datos en servicio', data);

    return this._http.post(this.url, data, { headers: this.headers }).pipe(map(res => res));
  }

}

<div class="card shadow mb-3">
  <div class="card-body">
    <h4>Cantidad de alertas activas: {{alertas.length}}</h4>
    <div style="display: block" *ngIf="alertas; else loading">
      <highcharts-chart
        [Highcharts]="Highcharts"
        [options]="chartOptions"

        style="width: 100%; height: 400px; display: block;"
      ></highcharts-chart>
    </div>
  </div>
</div>

  <ng-template class="text-center" #loading>
    <div class="d-flex justify-content-center align-items-center p-5 m-5">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Cargando...</span>
      </div>
    </div>
  </ng-template>

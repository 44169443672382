import { Component, OnInit } from '@angular/core';

import * as Highcharts from 'highcharts';
import { isNull } from 'lodash';
import { Cama } from 'src/app/models/cama';

import { CamaService } from 'src/app/services/cama.service';

@Component({
  selector: 'app-camas-libres',
  templateUrl: './camas-libres.component.html',
  styleUrls: ['./camas-libres.component.css']
})
export class CamasLibresComponent implements OnInit {
  
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options;

  public camas: Cama[];
  public total: number = 0;
  public libres: number = 0;
  public ocupadas: number = 0;

  constructor(
    private _camaSvc: CamaService
  ) {

    this.setData();
   }

  ngOnInit() {
  }

  setData(){
    this._camaSvc.getAll().subscribe(resp => {
      this.camas = resp['result'].data;

      this.total = this.camas.length;
      this.ocupadas = this.camas.filter(item => item.numeroHCPac !='' && !isNull(item.numeroHCPac)).length;
      this.libres = this.total - this.ocupadas;

      this.chartOptions  = {
        colors: Highcharts.map(Highcharts.getOptions().colors, function (color) {
          return {
              radialGradient: {
                  cx: 0.5,
                  cy: 0.3,
                  r: 0.7
              },
              stops: [
                  [0, color],
                  [1, Highcharts.color(color).brighten(-0.3).get('rgb')] // darken
              ]
          };
      }),
        series: [{
          data: [{name:'Libres',y: this.libres}, {name:'Ocupadas',y:this.ocupadas}],
          type: 'pie'
        }],
        title: {
          text:''
        },
        plotOptions: {
          pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                  enabled: true,
                  format: '<b>{point.name}</b>: {point.percentage:.1f} %',
                  connectorColor: 'silver'
              }
          }
      }
      };
      
    }, error => {})
  }


}

export class Episodio {
    constructor(
        public idHospital: number,
        public numeroHC:string,
        public fechaIngreso: number, //Ojo estaba como Date
        public fechaEgreso: number, //Ojo estaba como Date
        public razon: string, //UTI / UVI / Fallecimiento / LTC / Recuperado / Derivación
        public cuil: string,
        public sync_id?: number
    ){}
}

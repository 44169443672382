import { Pipe, PipeTransform } from '@angular/core';
import { Paciente } from '../models/paciente';

@Pipe({
  name: 'filterPaciente'
})
export class FilterPacientePipe implements PipeTransform {

  transform(value: any, arg: any): any {

    if(arg == '' || arg.length < 2){return value}

    var res = [];

    value.forEach(paci => {
      if(paci.apellido.toLowerCase().indexOf(arg.toLowerCase()) > -1 || paci.nombre.toLowerCase().indexOf(arg.toLowerCase())> -1 ||  paci.dni.toString().includes(arg) ||  paci.numeroHC.toString().includes(arg)){
        res.push(paci);
      }
    });

    return res;
  }

}

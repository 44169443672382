import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { Isla } from 'src/app/models/isla'
import { UsuarioService } from 'src/app/services/usuario.service'
import { IslaService } from 'src/app/services/isla.service'
import { Hospital } from 'src/app/models/hospital';
import { ABMBaseService } from 'src/app/services/ABMBase.service';

@Component({
  selector: 'app-edit-isla',
  templateUrl: './edit-isla.component.html',
  styleUrls: ['./edit-isla.component.css']
})
export class EditIslaComponent implements OnInit {

  public title;
  public identity;
  public hospital: any;
  public errorMessage = '';
  public isla: Isla;
  public idIsla: string;

  constructor(
    private _usuarioService: UsuarioService,
    private _islaService: IslaService,
    private _router: Router,
    private _toastr: ToastrService,
    private _abmBaseSvc: ABMBaseService,
    private _activedRoute: ActivatedRoute
    
  ) { }

  ngOnInit(): void {
    this.title = 'Carga de isla';
    this.hospital = this._usuarioService.getHospital();
    this.identity = this._usuarioService.getIdentity();
    this.idIsla = this._activedRoute.snapshot.params.idIsla;

    this.getIsla();
  }

  getIsla():void {
    this._abmBaseSvc.getAll('islas').subscribe(
      resp => {
        console.log(resp);
        

      }, error => console.log(error)
      
    )
  }

  onSubmit(){
    
    if(this.isla.idHospital == null || this.isla.idIsla == ''/*  || this.isla.idLider == null */){
      this.errorMessage = 'Todos los datos son necesarios';
    }else{
      this.errorMessage = '';
      this._islaService.getAll().subscribe(resp => {
        var islas: Isla[] = resp['result'].data;
        
        if (islas.length > 0) {

          var i = islas.filter(item => item.idIsla == this.isla.idIsla && item.idHospital == this.isla.idHospital);

          if(i.length > 0 ){
            this._toastr.error('Ya hay una Isla con ese nombre en este Hospital. Por favor, elija otro nombre')
          }else{
            this.storeIsla();
          }
          
        } else {
          this.storeIsla();
        }
        
      }, error => {
        console.log(error);
      });

    }
  }
  
  storeIsla(){
      this._abmBaseSvc.update('isla',this.isla).subscribe(
        response => {
          this._router.navigate(['islas']);
        }, error => {
          this.errorMessage = 'Error al intentar guardar datos de la isla'
          console.log(error)
        }
      )
  }

}

<!-- Enfermero puede cargar un usuario -->
<div *ngIf="identity">
  <div class="card">
    <div class="card-header">
      <div class="page-head py-3">
        <h3>{{ title }}</h3>
      </div>
    </div>
    <form #createUsuarioForm="ngForm" (ngSubmit)="onSubmit()">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="cuil">Cuil <span class="text-danger">*</span></label>
              <input
                type="text"
                class="form-control"
                #cuil="ngModel"
                name="cuil"
                [(ngModel)]="usuario.cuil"
                required
                title="Ingresar los números sin espacios ni guiones"
              />
              <span *ngIf="!cuil.valid && cuil.touched" class="text-danger">
                El cuil es obligatorio
              </span>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="nombre"
                >Nombre <span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control"
                #nombre="ngModel"
                name="nombre"
                [(ngModel)]="usuario.nombre"
                required
              />
              <span *ngIf="!nombre.valid && nombre.touched" class="text-danger">
                El nombre es obligatorio
              </span>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <label for="apellido"
                >Apellido <span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control"
                #apellido="ngModel"
                name="apellido"
                [(ngModel)]="usuario.apellido"
                required
              />
              <span
                *ngIf="!apellido.valid && apellido.touched"
                class="text-danger"
              >
                El apellido es obligatorio
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="form-group">
              <label for="email"
                >Email <span class="text-danger">*</span></label
              >
              <input
                type="email"
                class="form-control"
                #email="ngModel"
                name="email"
                [(ngModel)]="usuario.email"
                required
                title="ejemplo@dominio.com"
              />
              <span *ngIf="!email.valid && email.touched" class="text-danger">
                El email es obligatorio
              </span>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <label for="clave"
                >Contraseña <span class="text-danger">*</span></label
              >
              <div class="input-group mb-2">
                <input
                  [type]="verPass ? 'text' : 'password'"
                  class="form-control"
                  #clave="ngModel"
                  name="clave"
                  [(ngModel)]="usuario.clave"
                  required
                />
                <div class="input-group-prepend">
                  <div type="button" class="btn" (click)="showPass()">
                    <i class="fas fa-eye"></i>
                  </div>
                </div>
              </div>

              <span *ngIf="!clave.valid && clave.touched" class="text-danger">
                La clave es obligatorio
              </span>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <label for="telefono"
                >Teléfono <span class="text-danger">*</span></label
              >
              <input
                type="text"
                class="form-control"
                #telefono="ngModel"
                name="telefono"
                [(ngModel)]="usuario.telefono"
                required
              />
              <span
                *ngIf="!telefono.valid && telefono.touched"
                class="text-danger"
              >
                El telefono es obligatorio
              </span>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="idRol">Rol <span class="text-danger">*</span>:</label>
              <select
                class="form-control"
                #idRol
                name="idRol"
                [(ngModel)]="usuarioHospital.idRol"
              >
                <option value="0" *ngIf="_usuSvc.isAdmin">Administrador</option>
                <option value="4" *ngIf="_usuSvc.isAdmin || _usuSvc.isJefeEnfermeria">Enfermero</option>
                <option value="1" *ngIf="_usuSvc.isAdmin || _usuSvc.isJefeEnfermeria">Jefe Enfermeria</option>
                <option value="6" *ngIf="_usuSvc.isAdmin || _usuSvc.isJefeLaboratorio">Laboratorio</option>
                <option value="3" *ngIf="_usuSvc.isAdmin || _usuSvc.isJefeLaboratorio">Jefe Laboratorio</option>
                <option value="5" *ngIf="_usuSvc.isAdmin || _usuSvc.isJefeClinicaMedica">Médico</option>
                <option value="2" *ngIf="_usuSvc.isAdmin || _usuSvc.isJefeClinicaMedica">Jefe Clínica Médica</option>
                <option value="7"*ngIf="_usuSvc.isAdmin">Administrativo</option>
              </select>
            </div>
          </div>
        </div>
        <div class="my-2">
          <small>* Campos obligatorios</small>
        </div>
      </div>

      <div class="card-footer text-right">
        <div class="form-group">
          <input type="submit" class="btn btn-primary" value="Ingresar" />
          <a [routerLink]="['/usuarios']" class="btn btn-secondary ml-3"
            >Cancelar</a
          >
        </div>
      </div>
    </form>

   Usuario {{usuario | json}}
   <br>
   Usu-Hospi {{usuarioHospital | json}}
  </div>
</div>

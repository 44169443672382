import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { GLOBAL } from './global';
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ABMBaseService {

  public url: string;
  public headers: any;
  public token: string;

  constructor(
    protected _http: HttpClient,
    protected _toastr: ToastrService
  ) {
    this.url = GLOBAL.url;
    this.token = localStorage.getItem('token');
    this.headers = {
      'content-type': 'application/json'
    };
  }

  getAll(items: string, sync_id: number = 0):Observable<any> {

    var data = {
      "version": "0.0",
      "id": Math.floor((Math.random() * 10000) + 1),
      "params": {
        "sync_id": sync_id
      },
      "method": `get_${items}`,
      "token": this.token
    }
    
    return this._http.post(this.url, data, { headers: this.headers }).pipe(map(res => res));
  }


  create(item: string, model: any): Observable<any> {

    var data = {
      "version": "0.0",
      "method": `new_${item}`,
      "params": {
        "data": model
      },
      "id": Math.floor((Math.random() * 10000) + 1),
      "token": this.token
    }
    console.log('datos en servicio', data);
    

    return this._http.post(this.url, data, { headers: this.headers }).pipe(map(res => res));
  }


  update(item: string, model: any): Observable<any> {

    var data = {
      "version": "0.0",
      "method": `modify_${item}`,
      "params": {
        "data": model
      },
      "id": Math.floor((Math.random() * 10000) + 1),
      "token": this.token
    }

    return this._http.post(this.url, data, { headers: this.headers }).pipe(map(res => res));
  }


  /* Calcula la edad segun la fecha de naciemiento */
  getEdad(fecha) {
    var hoy = new Date();
    var cumpleanos = new Date(fecha);
    var edad = hoy.getFullYear() - cumpleanos.getFullYear();
    var m = hoy.getMonth() - cumpleanos.getMonth();

    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
      edad--;
    }

    return edad;
  }

  /** Muestra un toast avisando de cualquier error que se pueda tener en la conección
  * con la base de datos
  * @param e any
  */
   handleError(e) {
    this._toastr.error('Error al obtener los datos', e.message, { timeOut: 5000 })
  }



  }

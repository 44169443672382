export var GLOBAL = {
  /*   url: 'https://covindex.fi.uncoma.edu.ar:8081' */
    /* url: 'http://170.210.83.83:60171/lider/' */
    
    // url: 'https://covindexcloud.fi.uncoma.edu.ar/lider/'
    // url: 'https://170.210.81.149:8082/lider/',
    url: 'https://covindex.uncoma.edu.ar:8082/lider/',
    webServicePacientes: "https://salud.rionegro.gov.ar/mpi/webservice/datos_personales_dni.php?dni="


}
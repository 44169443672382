import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { Sector } from 'src/app/models/sector';
import { Cama } from 'src/app/models/cama';
import { Hospital } from 'src/app/models/hospital';
import { Usuario } from 'src/app/models/usuario';

import { UsuarioService } from 'src/app/services/usuario.service';
import { ABMBaseService } from 'src/app/services/ABMBase.service';
import { IslaService } from 'src/app/services/isla.service';


@Component({
    selector: 'app-create-sector',
    templateUrl: './create-sector.component.html',
    styleUrls: ['./create-sector.component.css'],
    providers: [UsuarioService, ToastrService, IslaService]
})
export class CreateSectorComponent implements OnInit {

    title: string;
    errorMessage: string = '';
    identity: Usuario;
    sector: Sector;
    hospital: Hospital;
    id: string;
    sectores: Sector[];

    idCama: string
    camas: string[] = [];
    camasInicial: string[]=[];
    errorCama: string = '';

    constructor(
        private _usuarioService: UsuarioService,
        private _toastr: ToastrService,
        private _router: Router,
        private _activeRoute: ActivatedRoute,
        private _abmBaseSvc: ABMBaseService
    ) { }

    ngOnInit(): void {
        this.id = this._activeRoute.snapshot.params.id;

        this.identity = this._usuarioService.getIdentity();
        this.hospital = this._usuarioService.getHospital();

        this.sector = new Sector(this.hospital.idHospital, '', '');

        (this.id) ? this.title = 'Editar Sector': this.title = 'Cargar Sector';

        this.getSector();

    }

    getSector() {
        this._abmBaseSvc.getAll('sectores').subscribe(
            resp => {

                this.sectores = resp.result.data;
                if(this.id){
                  let sector = this.sectores.filter(item => item.idSector == this.id)[0];
                  this.sector = new Sector(this.hospital.idHospital, this.id, sector.descripcion);
                  this._abmBaseSvc.getAll('camas').subscribe(
                    resp => {
                      console.log('Camas==>',resp.result.data );

                      this.camas = resp.result.data.filter(cama => cama.idSector == this.id && cama.estado == 1).map(item => item.idCama);
                      this.camasInicial = this.camas;

                    }, error => console.log(error)

                  )

                }

            },
            error => console.log(error)

        )
    }

    onSubmit() {
        try {

            if (!this.sector.idSector || this.sector.idSector == '') {
                this._toastr.error('Faltan cargar datos requeridos')
            } else {
                if(this.id){
                  this.storeSector();
                } else {
                  let existeSector = this.sectores.filter(item => item.idHospital == this.sector.idHospital && item.idSector == this.sector.idSector);

                  if (existeSector.length > 0) {
                      this._toastr.error('Ya existe un Sector con ese nombre en este Hospital. Por favor, elija otro nombre')
                  } else {
                      this.storeSector();
                  }
                }

            }

        } catch (error) {
          console.log('Error en el submit: ', error);

        }

    }

    /**
     * Guarda el sector y las camas en la base de datos
     */
    storeSector(): void {

        if (this.id) {

          var updatedCamas = this.getCamasNuevas();

          this._abmBaseSvc.update('sector', this.sector).subscribe(
              resp => {
                console.log(resp);
                if(resp.status.includes('200')){
                  console.log('Respuesta ok');
                  // Actualiar las camas aca

                }else{
                  this._toastr.error('No se pudo actualizar el sector');
                }

              },
              error => {
                  console.log(error);
                  this._toastr.error('No se pudo actualizar el sector');
              }
          )

        } else {

            this._abmBaseSvc.create('sector', this.sector).subscribe(
                response => {

                    this.camas.forEach(idCama => {

                        var cama = new Cama(this.sector.idHospital, this.sector.idSector, idCama, '', null, null, null, 1);

                        this._abmBaseSvc.create('cama', cama).subscribe(
                            response => {

                                this._toastr.success('datos guardados correctamente')
                                this._router.navigate(['/sectores']);

                            }, error => {

                            }
                        )
                    });

                }, error => {
                    this.errorMessage = 'Error al guardar el sector';
                    console.log(error)
                }
            )

        }
    }

    getCamasNuevas(): string[] {
      var camasNuevas: string[] = [];
      this.camas.forEach(cama => {
        if(this.camasInicial.indexOf(cama) == -1){ camasNuevas.push(cama)} ;

      });
      console.log('Camas que quedaron=>', camasNuevas);
      return camasNuevas;

    }

    onCamaSubmit() {

        if (this.idCama != '') {

            if (this.camas.filter(item => item == this.idCama).length > 0) {
                this.errorCama = 'Ya hay una cama cargada con esa identificación';
            } else {
                this.camas.push(this.idCama);
                this.idCama = '';
                this.errorCama = '';
            }

        }

    }

    deleteCama(cama: string) {
        var i = this.camas.indexOf(cama);
        if (i !== -1) {
            this.camas.splice(i, 1);
        }
    }


}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';


import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class LaboratorioService {

  public url: string;
  public identity;
  public token;
  private headers;
  private hospital;
  private pacientes;

  constructor(private _http: HttpClient) {

    this.url = GLOBAL.url;
    this.identity = JSON.parse(localStorage.getItem('identity'));
    this.token = localStorage.getItem('token');
    this.hospital = localStorage.getItem('hospital');
    this.pacientes = localStorage.getItem('pacientes');

    this.headers = {
      'Content-Type': 'application/json'
    };
  }

  getAll(sync_id = 0) {

    var data = {
      "version": "0.0",
      "id": Math.floor((Math.random() * 10000) + 1),
      "params": {
        "sync_id": sync_id
      },
      "method": "get_laboratorios",
      "token": this.token
    }

    return this._http.post(this.url, data, { headers: this.headers }).pipe(map(res => res));
  }

  create(model) {

    var data = {
      "version": "0.0",
      "id": Math.floor((Math.random() * 10000) + 1),
      "params": {
        "data": {
          "idHospitalLab": model.idHospital,
          "numeroHCLab": model.numeroHC,
          "fecha": model.fecha,
          "cuil": model.auditoria,
          "dimeroD": model.dimerod,
          "linfopenia": model.linfocitos,
          "plaquetas": model.plaquetas,
          "ldh": model.ldh,
          "ferritina": model.ferritina,
          "proteinaC": model.proteina_c_reactiva
        }
      },
      "method": "new_laboratorio",
      "token": this.token
    }

    return this._http.post(this.url, data, { headers: this.headers }).pipe(map(res => res));
  }

}

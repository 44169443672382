<!-- Todos los logueados pueden listar pacientes -->
<div *ngIf="identity">
    <div class="card">
        <div class="card-header">
            <div class="page-head">
                <h3>{{title}}</h3>

            </div>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col">
                    <!-- <div class="form-group">
                        <a [routerLink]="[ '/cama-create', idIsla, idSector ]" class="btn btn-primary"><span class="mr-2"><i
                                    class="fas fa-plus"></i></span>Nueva Cama</a>
                    </div> -->
                    <div class="col-12 mb-3">
                        <p>
                            <button *ngIf="!verTodas" class="btn btn-primary" type="button" data-toggle="collapse" data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                              <i class='fa fa-plus mr-3'></i>Cargar cama
                            </button>
                          </p>
                          <div class="collapse" id="collapseExample">
                            <div class="card card-body">
            
                                <form #camaForm="ngForm" class="form-inline" (submit)="onCamaSubmit()">
                                  <label for="idCama">Nombre cama</label>
                                  <input type="text" class="form-control mx-3" #cama="ngModel" name="cama" [(ngModel)]="idCama">
                                  <button type="submit" class="btn btn-sm btn-primary" data-toggle="collapse" data-target="#collapseExample" ><i class="fa fa-plus mr-3"></i>Guardar</button>
                                  
                                </form>
                               
                            </div>
                          </div>
                    </div>
                </div>
                <div class="row justify-content-between">
                    <div class="col">
                        <div class="col-fit mr-3 ">
                            <a [routerLink]='["/sectores"]' class="btn btn-secondary" *ngIf="!verTodas"><i class="fa fa-arrow-left mr-3"></i><span>Volver a Sector</span></a>
                            <a [routerLink]='["/home"]' *ngIf="verTodas" class="btn btn-secondary"><i class="fa fa-arrow-left mr-3" ></i><span>Volver</span></a>
                        </div>
                       
                    </div>
                    <div class="col">
                        <div class="input-group mb-2 mr-sm-2">
                            <div class="input-group-prepend">
                                <div class="input-group-text"><i class="fas fa-search"></i></div>
                            </div>
                            <input type="text" class="form-control" placeholder="Buscar..." name="filtrarCama"
                                [(ngModel)]="filtrarCama">
                        </div>
                    </div>
                </div>
            </div>
            <table class="table table-hover table-striped table-bordered table-sm table-responsive-md">
                <thead>
                    <tr class="bg-primary text-light text-center">
                        <th scope="col" (click)="order('idCama')">Cama</th>
                        <th scope="col" (click)="order('idSector')" *ngIf="verTodas">Sector</th>
                        <th scope="col" (click)="order('numeroHCPac')">Estado</th>
                        <th scope="col" (click)="order('ubicacionX')">Ubicación X</th>
                        <th scope="col" (click)="order('ubicacionY')">Ubicación Y</th>
                        <th scope="col" (click)="order('orientacion')">Orientación</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let c of camas | filterCama: filtrarCama | orderBy:orderDirection:orderColumn | slice:(page-1)*itemsPerPage:(page-1)*itemsPerPage + itemsPerPage" class="text-center">
                        <th scope="row">{{c.idCama}}</th>
                        <th scope="row" *ngIf="verTodas">{{c.idSector}}</th>
                        <td>{{c.numeroHCPac ? 'Ocupada' :'Libre'}}</td>
                        <td>{{c.ubicacionX}}</td>
                        <td>{{c.ubicacionY}}</td>
                        <td>{{c.orientacion}}</td>
                       
                    </tr>
                </tbody>

            </table>
            <div class="mt-3" *ngIf="verPag">
                <ngb-pagination 
                    [collectionSize]="totalItems" 
                    [(page)]="page" 
                    [pageSize]="itemsPerPage"
                    [maxSize]="7" 
                    [rotate]="true" 
                    [boundaryLinks]="true"
                   
                >
                </ngb-pagination>
            </div>
        </div>
    </div>





</div>
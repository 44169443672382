import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';


import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class HospitalService {

  public url: string;
  public identity;
  public token;
  private headers;

  constructor(private _http: HttpClient) {
    this.url = GLOBAL.url;
    this.identity = JSON.parse(localStorage.getItem('identity'));
    this.token = localStorage.getItem('token');
    this.headers = {
      'Content-Type': 'application/json',
      'token': this.token
    };
  }

  getAll() {

    var data = {
      "version": "0.0",
      "id": Math.floor((Math.random() * 10000) + 1),
      "params": {
        "sync_id": 0
      },
      "method": "get_hospital",
      "token": this.token
    }
    return this._http.post(this.url, data, { headers: this.headers }).pipe(map(res => res));
  }

/*   getById(id:number){
    return this._http.get( this.url + 'hospitales/' + id, {headers: this.headers}).pipe(map(res => res ));
  } */

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';


import { GLOBAL } from './global';

@Injectable()
export class UsuarioHospitalService {

    url: string;
    identity;
    token;
    hospital
    usuarioHospital;
    headers;

    constructor(private _http: HttpClient) {
        this.url = GLOBAL.url;
        this.token = localStorage.getItem('token');
        this.hospital = JSON.parse(localStorage.getItem('hospital'));
        this.headers = {
            'Content-Type': 'application/json'
        };
    }


    /* Recupera de la api todos los usuario-hospital */
    getAll(): Observable<any> {

        var data = {
            version: "0.0",
            id: Math.floor((Math.random() * 10000) + 1),
            params: {
                sync_id: 0
            },
            method: "get_usuarios_hospital",
            token: this.token
        }
        return this._http.post(this.url, data, { headers: this.headers }).pipe(map(res => res));

    }

    /* Crear un usuario-hospital  */
    create(usuario): Observable<any> {

        var data = {
            "version": "0.0",
            "method": "new_usuario_hospital",
            "params": {
                "data": {
                    "idHospital": usuario.idHospital,
                    "cuil": usuario.cuil,
                    "idRol": usuario.idRol,
                    "estadoLaboral": usuario.estadoLaboral
                }
            },
            "id": Math.floor((Math.random() * 10000) + 1),
            "token": this.token
        }

        return this._http.post(this.url, data, { headers: this.headers }).pipe(map(res => res));

    }


}
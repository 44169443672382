<!-- Todos los logueados pueden listar pacientes -->
<div class="container" *ngIf="identity">
    <div class="card">
        <div class="card-header">
            <h3>{{title}}</h3>
        </div>
        <div class="card-body">

            <div *ngIf="!paciente">
                <div class="form-inline">
                    <div class="input-group mb-2 mr-sm-2">
                        <label for="filtrarPaciente" class="mr-2">Buscar Paciente</label>
                        <input type="text" class="form-control" placeholder="Buscar..." name="filtrarPaciente"
                            id="filtrarPaciente" [(ngModel)]="filtrarPaciente">

                        <div class="input-group-append">
                            <div class="input-group-text"><i class="fas fa-search"></i></div>
                        </div>
                    </div>
                </div>
                <div class="paciente-list-container">
                    <ul class="list-group">
                        <a (click)="selPaciente(paciente)" class="paciente-list"
                            *ngFor="let paciente of pacientes | filterPaciente:filtrarPaciente ">
                            <li class="list-group-item paciente-list">
                                {{paciente.apellido}} {{paciente.nombre}} - {{paciente.dni}}
                            </li>
                        </a>
                    </ul>
                </div>
            </div><!-- /Seleccion paciente -->
            <div *ngIf="paciente">

              <app-detalle-paciente [paciente]="paciente"></app-detalle-paciente>


              <form #createLaboratorioForm="ngForm" (ngSubmit)="onSubmit(modalContent)" class="form-inline">
                <div class="card w-100">

                  <div class="card-body">
                    <div class="alert alert-danger" *ngIf="errorMessage">
                      {{errorMessage}}
                    </div>
                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label for="dimeroD">Dimero D:</label>
                          <input type="text" class="form-control ml-2 my-1" #dimeroD="ngModel" name="dimeroD" (blur)="validar($event)"
                            [(ngModel)]="laboratorio.dimeroD">
                          <label for="dimeroD" class="ml-2">ng/mL</label>
                        </div>
                      </div>
                      <div class="col">
                        <div class="form-group">
                          <label for="linfopenia">Linfocitos:</label>
                          <input type="text" class="form-control ml-2 my-1" #linfopenia="ngModel" name="linfopenia" (blur)="validar($event)"
                            [(ngModel)]="laboratorio.linfopenia">
                          <label for="linfopenia" class="ml-2">c&eacute;lulas/mm<sup>3</sup></label>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col">
                        <div class="form-group">
                          <label for="plaquetas">Plaquetas:</label>
                          <input type="text" class="form-control ml-2 my-1" #plaquetas="ngModel" name="plaquetas" (blur)="validar($event)"
                            [(ngModel)]="laboratorio.plaquetas">
                          <label for="dimeroD" class="ml-2">c&eacute;lulas/mm<sup>3</sup></label>
                        </div>
                      </div>

                      <div class="col">
                        <div class="form-group">
                          <label for="ldh">LDH:</label>
                          <input type="text" class="form-control ml-2 my-1" #ldh="ngModel" name="ldh" [(ngModel)]="laboratorio.ldh" (blur)="validar($event)">
                          <label for="dimeroD" class="ml-2">UI/L</label>

                        </div>
                      </div>
                    </div>

                    <div class="row">

                      <div class="col">
                        <div class="form-group">
                          <label for="ferritina">Ferritina:</label>
                          <input type="text" class="form-control ml-2 my-1" #ferritina="ngModel" name="ferritina" (blur)="validar($event)"
                            [(ngModel)]="laboratorio.ferritina" >
                          <label for="dimeroD" class="ml-2">ng/mL</label>
                        </div>
                      </div>

                      <div class="col">
                        <div class="form-group">
                          <label for="proteinaC">PCR:</label>
                          <input type="text" class="form-control ml-2 my-1" #proteinaC="ngModel" (blur)="validar($event)"
                            name="proteinaC" [(ngModel)]="laboratorio.proteinaC" >
                          <label for="dimeroD" class="ml-2">mg/L</label>

                        </div>
                      </div>

                    </div>

                  </div><!-- /Card Body -->

                  <div class="card-footer">
                    <div class="row">

                      <div class="col">
                        <div class="form-group">
                          <input type="submit" class="btn btn-primary" value="Aceptar">
                          <input type="reset" (click)="cancelCarga()" class="btn btn-secondary ml-3" value="Cancel">
                        </div>
                      </div>

                    </div>
                  </div><!-- /Card Footer -->

                </div><!-- /Card-->

              </form>

            </div><!-- /Datos paciente -->

        </div><!-- /Card-body -->
    </div><!-- /Card -->

</div>

<ng-template #modalContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Confirmación de carga</h4>
    <!--<button type="button" class="close" aria-label="Close" (click)="modal.dismiss(false)">
      <span aria-hidden="true">&times;</span>
    </button>-->
  </div>
  <div class="modal-body">
    <p>Alguno de los datos que se guardarán son nulos.</p>
    <p>¿Estás seguro que quiere avanzar?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close(true)">Aceptar</button>
    <button type="button" class="btn btn-light" (click)="modal.close(false)">Cancelar</button>

  </div>
</ng-template>

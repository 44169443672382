
export class Cama {
    constructor(
        public idHospitalCama: number,
        public idSector: string,
        public idCama: string,
        public numeroHCPac: string,
        public ubicacionX: number,
        public ubicacionY: number,
        public orientacion: number,
        public estado: number,
        public sync_id?: number
    ){}
}
<!-- Enfermero puede cargar un usuario -->
<div *ngIf="identity">

    <div class="card">
      <div class="card-header">
        <div class="page-head py-3">
          <h3>{{title}}</h3>
        </div>
      </div>
      <div class="card-body">

        <div class="row d-flex justify-content-between">
          <div class="col-3">
            <div class="form-group d-flex align-items-center justify-content-center">
              <label for="dni" class="mr-2">DNI:</label>
              <div class="input-group">
  
                <input type="text" name="dni" id="dni" class="form-control" [(ngModel)]="dni" (change)='dniChange()'>
                <div class="input-group-append">
                  <div class="input-group-text"><i class="fa fa-search"></i></div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-3" [ngClass]="{invisible: !showEgresoButton}">
            <button type="button" class="form-control btn btn-primary" (click)="onSubmit()" >Cerrar internación</button>
          </div>
        </div>
  
        <div class="row">
          <div class="col">
             
  
            <div class="table table-bordered" *ngIf="verPaciente"><!-- Datos paciente -->
  
              <div class="card">
                <div class="card-body">
                  <div class="row">
                    <div class="col">
                        <div class="btn btn-danger w-100  mb-2" *ngIf="internado">PACIENTE INTERNADO</div>
                      <p><strong>Apellido y Nombre: </strong>{{paciente.apellido}} {{paciente.nombre}}</p>
                      <p><strong>Historia Clínica: </strong>{{paciente.numeroHC}}</p>
                      <p><strong>Dirección: </strong>{{paciente.calle}} Nro: {{paciente.numero}}</p>
                      <p *ngIf="paciente.piso != ''"><strong>Piso y departamento:</strong>
                        {{paciente.piso}},</p>
                      <p><strong>CP:</strong> {{paciente.CP}}</p>
  
                    </div>
                    <div class="col border-left">
                      <p><strong>Fecha de nacimiento: </strong>{{paciente.fechaNac | date:'dd-MM-yyyy'}}</p>
                      <p><strong>Nacionalidad: </strong>{{paciente.nacionalidad}}</p>
                      <p><strong>Sexo Biológico: </strong>{{paciente.genero}}</p>
                    </div>
                    <div class="col border-left">
                      <p><strong>Edad: </strong>{{edad}}</p>
  
                      <p><strong>Teléfono:</strong> {{paciente.telefono}}</p>
                      <p *ngIf="paciente.telefonoFamiliar != ''"><strong>Teléfono familiar 1:</strong>
                        {{paciente.telefonoFamiliar}}</p>
                      <p *ngIf="paciente.telefonoFamiliar2 != ''"><strong>Teléfono familiar 2:</strong>
                        {{paciente.telefonoFamiliar2}}</p>
  
                    </div>
                  </div>
  
                </div>
              </div>
  
  
            </div><!-- /Datos paciente -->
          </div>
        </div>
  
      </div>
    </div>
  
  
  </div>
import { Pipe, PipeTransform } from '@angular/core';
import { isNull } from 'lodash';

@Pipe({
  name: 'filterEpisodio'
})
export class FilterEpisodioPipe implements PipeTransform {

  transform(value: any, arg: any): any {
    if (arg) {
      return value;
    }else{
      return value.filter(item => isNull(item.fechaEgreso))
    }

  }
}

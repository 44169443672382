import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario.service';
import { EpisodioService } from 'src/app/services/episodio.service';
import { Episodio } from 'src/app/models/episodio';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Component({
  selector: 'app-episodio',
  templateUrl: './episodio.component.html',
  styleUrls: ['./episodio.component.css'],
  providers: [UsuarioService, EpisodioService, ToastrService]
})
export class EpisodioComponent implements OnInit {

  title: string;
  errorMessage: string = '';
  episodios: Episodio[];
  identity;
  hospital;
  verRazon: boolean = false;
  razon: string;
  verTodos: boolean = false;

  constructor(
    private _usuarioService: UsuarioService,
    private _episodioService: EpisodioService,
    private _router: Router,
    private _toastr: ToastrService
  ) { }

  ngOnInit() {
    this.title = 'Lista de Episodios';
    this.identity = this._usuarioService.getIdentity();
    this.hospital = this._usuarioService.getHospital();
    this.getEpisodios();
  }

  getEpisodios(){
    this.episodios = [];
    this._episodioService.getAll('episodios').subscribe(
      response => {
        console.log('respuesta', response['resp']);

        if(response['resp']?.includes("401")){ this._usuarioService.logout()}
        
        if(response['result'].data){
          response['result'].data.forEach(el => {
            el.fechaIngreso = moment.unix(el.fechaIngreso);
            el.fechaEngreso = moment.unix(el.fechaEngreso);
          });
          this.episodios = response['result'].data;
          
          console.log('Episodios===>',this.episodios);
          
        }else{
          this._toastr.error("Error al cargar los episodios")
          //this._usuarioService.logout()
        }
      }, error => {
        console.log(error);
        
        this._toastr.error("Error al cargar los episodios")
        //this._usuarioService.logout();
      }
    )
  }

  cerrarEpisodio(episodio: Episodio){
    if (episodio.fechaEgreso == null) {
      this._router.navigate(['/episodio-edit/' + this.hospital.idHospital + '/' + episodio.numeroHC]);
    }else{
      this.errorMessage = 'El episodio ya no puede ser modificado'
    }
  }

}

<!-- Datos paciente -->

                    <div class="card" *ngIf="identity">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                   <!--  <div class="btn btn-danger w-100  mb-2" *ngIf="internado">PACIENTE INTERNADO</div> -->
                                    <p><strong>Apellido y Nombre: </strong>{{paciente.apellido}} {{paciente.nombre}}
                                    </p>
                                    <p><strong>Historia Clínica: </strong>{{paciente.numeroHC}}</p>
                                    <p><strong>Dirección: </strong>{{paciente.calle}} Nro: {{paciente.numero}}</p>
                                    <p *ngIf="paciente.piso != ''"><strong>Piso y departamento:</strong>
                                        {{paciente.piso}},</p>
                                    <p><strong>CP:</strong> {{paciente.CP}}</p>

                                </div>
                                <div class="col border-left">
                                    <p><strong>Fecha de nacimiento: </strong>{{paciente.fechaNac | date:'dd-MM-yyyy'}}</p>
                                    <p><strong>Nacionalidad: </strong>{{paciente.nacionalidad}}</p>
                                    <p><strong>Sexo Biológico: </strong>{{paciente.genero}}</p>
                                </div>
                                <div class="col border-left">
                                    <p><strong>Edad: </strong>{{edad}}</p>

                                    <p><strong>Teléfono:</strong> {{paciente.telefono}}</p>
                                    <p *ngIf="paciente.telefonoFamiliar != ''"><strong>Teléfono familiar 1:</strong>
                                        {{paciente.telefonoFamiliar}}</p>
                                    <p *ngIf="paciente.telefonoFamiliar2 != ''"><strong>Teléfono familiar 2:</strong>
                                        {{paciente.telefonoFamiliar2}}</p>

                                </div>
                            </div><!-- /Datos paciente -->
                        </div>
                    </div>
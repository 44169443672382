import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'

/* Models */
import { Paciente } from '../../../models/paciente';
import { ControlEnfermeria } from '../../../models/control-enfermeria';


/* Services */
import { UsuarioService } from '../../../services/usuario.service';
import { ControlEnfermeriaService } from '../../../services/control-enfermeria.service';
import { ToastrService } from 'ngx-toastr';
import { AlertaService } from '../../../services/alerta.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import * as moment from 'moment';
import { ABMBaseService } from 'src/app/services/ABMBase.service';

@Component({
  selector: 'app-control-enfermeria',
  templateUrl: './control-enfermeria.component.html',
  styleUrls: ['./control-enfermeria.component.css'],
  providers: [ ABMBaseService, UsuarioService, ToastrService, ControlEnfermeriaService, AlertaService]
})
export class ControlEnfermeriaComponent implements OnInit {

	public title: string;
	public errorMessage: string = '';
	public paciente: Paciente;
	public pacientes: Paciente[];
	public controlEnfermeria;
	public filtrarPaciente = '';
 	public edad: number;
 	public identity: any;
 	public hospital: any;
  public numeroHC: string;
  public verFraccion:boolean = false;

  constructor(
  		private _usuarioService: UsuarioService,
    	private _abmSvc: ABMBaseService,
    	private _controlEnfermeriaService: ControlEnfermeriaService,
    	private _router: Router,
      private _toastr: ToastrService,
      private _modal: NgbModal,
      private _alertaService: AlertaService,
      private _activeRoute: ActivatedRoute
	) { }

  ngOnInit(): void {
    this.numeroHC = this._activeRoute.snapshot.params.numeroHC;
  	this.title = 'Carga Control de Enfermería';
    this.identity = this._usuarioService.getIdentity();
    this.hospital = this._usuarioService.getHospital();
    this.getPacientes();
    this.controlEnfermeria = new ControlEnfermeria(null,'',null,'', null,null,'','',null,null,null,null,null,'');

  }

  onSubmit(modalContent){

    var ce = this.controlEnfermeria;

    if (ce.frec_resp == null && ce.oxigenoSuplementario == '' && ce.sat_oxi == null && ce.presSist == null && ce.presDiast == null &&  ce.frec_card == null && ce.temp == null && ce.disnea == '' && ce.nivelConciencia == '' ) {
      this.errorMessage = 'Al menos 1 (un) campo debe contener un valor válido';
    }else{

      if (ce.frec_resp == null || ce.sat_oxi == null || ce.oxigenoSuplementario == '' || ce.presSist == null || ce.presDiast == null || ce.frec_card == null || ce.temp == null || ce.disnea == '' || ce.nivelConciencia == '' ) {
        this._modal.open(modalContent,{backdropClass: 'light-blue-backdrop'}).result.then(
          result=>{
            if (result) {
              this.dataStore();
            }

        }, reason => {

        });
      }else{
        this.dataStore();
      }
    }
  }

  /** Guarda los datos en la base de datos */
  private dataStore(){

    this.controlEnfermeria.id_Hospital = this.hospital.idHospital
    this.controlEnfermeria.auditoria = this.identity.cuil
  	this.controlEnfermeria.fechaSignosVitales = moment().unix() * 1000;

  	console.log(this.controlEnfermeria)
    this._abmSvc.create('signo_vital',this.controlEnfermeria).subscribe(
  		control => {

        console.log('Respuesta de signo vital => ', control);

        if(control.status.includes("200")){
          // this.paciente = null;
          this.controlEnfermeria = new ControlEnfermeria(null,'',null,'', null,null,'','',null,null,null,null,null,'');
          this._alertaService.pedirAlertas(true);
          this._toastr.success('Gracias', 'Datos guardados con éxito');
        }else{
          this._toastr.error('Error', 'Error en la respuesta: ' + control['status']);
        }


  		},error => {
  			this.errorMessage = 'Error al guardar los datos'
  			console.log(error.message);
  		})
  }

  validar(e){

  	let ev = e.target;
  	switch(ev.name){
  		case "frec_resp":
  			if(ev.value < 0 || ev.value > 60){
  				this.errorMessage = "La frecuencia respiratoria debe estar entre 0 y 60"
  				this.controlEnfermeria.frec_resp = null
  			}else{this.errorMessage = ''}
  			break;

		case "sat_oxi":
  			if(ev.value < 0 || ev.value > 100){
  				this.errorMessage = "La frecuencia respiratoria debe estar entre 0 y 100"
  				this.controlEnfermeria.sat_oxi = null
  			}else{this.errorMessage = ''}
  			break;

		case "presSist":
  			if(ev.value < 0 || ev.value > 250){
  				this.errorMessage = "La presión sistólica debe estar entre 0 y 250"
  				this.controlEnfermeria.presSist = null
  			}else{this.errorMessage = ''}
  			break;

  		case "frec_card":
  			if(ev.value < 0 || ev.value > 200){
  				this.errorMessage = "La frecuencia cardíaca debe estar entre 0 y 200"
  				this.controlEnfermeria.frec_card = null
  			}else{this.errorMessage = ''}
  			break;

  		case "temp":
  			if(ev.value < 34 || ev.value > 42){
  				this.errorMessage = "La temp debe estar entre 34 y 42"
  				this.controlEnfermeria.temp = null
  			}else{this.errorMessage = ''}
  			break;
  	}
  }

  selPaciente(valor){
    this.paciente = valor;
    this.errorMessage = '';
    this.edad = this._abmSvc.getEdad(this.paciente.fechaNac)
    this.controlEnfermeria.numeroHCSignosVitales = this.paciente.numeroHC
  }

  cancelCarga(){
    if(this.numeroHC) this._router.navigate(['/pacientes']);
    this.paciente = null;
  }

  getPacientes(){
    this._abmSvc.getAll('hcpacientes').subscribe(
      response => {
        if (response['result'].data) {
          this.pacientes = response['result'].data;
          this.paciente = this.pacientes.filter(item => item.numeroHC == this.numeroHC)[0];
          this.errorMessage = '';
          this.edad = this._abmSvc.getEdad(this.paciente.fechaNac)
          this.controlEnfermeria.numeroHCSignosVitales = this.paciente.numeroHC

        }else{
          //this._usuarioService.logout()
        }
      }, error => {
        this.errorMessage = 'Error al obtener los pacientes';
        console.log(error.message)
        //this._usuarioService.logout();
      }
    )

  }

  onOxigenoSupSi(){
    this.verFraccion = true;
  }

  onOxigenoSupNo(){
    this.verFraccion = false;
  }


}

<div class="container-fluid pt-3 pb-3">
    <div class="row justify-content-center">
        <h3 class="mb-3">Tablero</h3>
    </div>
    <div class="row">
        <div class="col-12 col-lg-4 "><!-- sección 1 -->
            <app-alerta *ngIf="cargarGrafico"></app-alerta>
        </div><!-- end sección 1 -->

        <div class="col-12 col-lg-8"><!-- seccion 2 -->
            <div class="row mb-3">
                <div class="col-12">
                    <app-estado-x-paciente *ngIf="cargarGrafico"></app-estado-x-paciente>
                </div>
                <div class="col-12">
                    <app-camas-libres *ngIf="cargarGrafico"></app-camas-libres>
                </div>
            </div><!-- end graficos paciente -->
            <hr>
            <div class="row justify-content-between flex-wrap mt-4">
                <a [routerLink]="['/pacientes']" class="btn btn-secondary py-3 px-5 mb-2 text-center">Pacientes</a>
                <a [routerLink]="['/sectores']" class="btn btn-secondary py-3 px-5 mb-2 text-center">Recursos Físicos</a>
                <a [routerLink]="['/usuarios']" class="btn btn-secondary py-3 px-5 mb-2 text-center">Recursos Humanos</a>
                <!-- <a [routerLink]="['/camas']" class="btn btn-secondary py-3 px-5 mb-2 text-center">Camas</a> -->
            </div><!--  end botones -->
        </div><!-- end seccion 2 -->

    </div>

</div>

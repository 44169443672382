<div class="container" *ngIf="identity">
    <div class="card">
        <div class="card-header">
            <h3>{{title}}</h3>
        </div>
        <div class="card-body">
            <div class="alert alert-danger" *ngIf="errorMessage">
                {{errorMessage}}
            </div>
            <div *ngIf="!paciente">
                <div class="form-inline">
                    <div class="input-group mb-2 mr-sm-2">
                        <label for="filtrarPaciente" class="mr-2">Buscar Paciente</label>
                        <input type="text" class="form-control" placeholder="Buscar..." name="filtrarPaciente"
                            id="filtrarPaciente" [(ngModel)]="filtrarPaciente">

                        <div class="input-group-append">
                            <div class="input-group-text"><i class="fas fa-search"></i></div>
                        </div>
                    </div>
                </div>
                <div class="paciente-list-container">
                    <ul class="list-group">
                        <a (click)="selPaciente(paciente)" class="paciente-list"
                            *ngFor="let paciente of pacientes | filterPaciente:filtrarPaciente ">
                            <li class="list-group-item paciente-list">
                                {{paciente.apellido}} {{paciente.nombre}} - {{paciente.dni}}
                            </li>
                        </a>
                    </ul>
                </div>
            </div><!-- /Seleccion paciente -->
            <div *ngIf="paciente">
                <div class="">
                    <!-- Datos paciente -->

                    <app-detalle-paciente [paciente]="paciente"></app-detalle-paciente>

                    <form #createPacienteForm="ngForm" (ngSubmit)="onSubmit(createPacienteForm.value)">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="radio-button-container p-3 m-3 col-6 col-md-4 col-lg-2 m-lg-2">
                                        <div class="form-group">
                                            <label for="iccGrado2">ICC grado 2:</label>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="iccGrado2" [(ngModel)]="paciente.iccGrado2"
                                                    id="inlineRadio1" value="1">
                                                <label class="form-check-label" for="inlineRadio1">Si</label>
                                            </div>
                                            <div class="form-check ">
                                                <input class="form-check-input" type="radio" name="iccGrado2" [(ngModel)]="paciente.iccGrado2"
                                                    id="inlineRadio2" value="0">
                                                <label class="form-check-label" for="inlineRadio2">No</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="radio-button-container p-3 m-3 col-4 col-md-3 col-lg-2  m-lg-2">

                                        <div class="form-group">
                                            <label for="epoc">EPOC:</label>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="epoc" [(ngModel)]="paciente.epoc"
                                                    id="inlineRadio3" value="1">
                                                <label class="form-check-label" for="inlineRadio3">Si</label>
                                            </div>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="epoc" [(ngModel)]="paciente.epoc"
                                                    id="inlineRadio4" value="0">
                                                <label class="form-check-label" for="inlineRadio4">No</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="radio-button-container p-3 m-3 col-5 col-md-3 col-lg-2 m-lg-2">
                                        <div class="form-group">
                                            <label for="diabetesDanioOrgano">Diabetes:</label>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="diabetesDanioOrgano" [(ngModel)]="paciente.diabetesDanioOrgano"
                                                    id="inlineRadio5" value=1>
                                                <label class="form-check-label" for="inlineRadio5">Si</label>
                                            </div>
                                            <div class="form-check ">
                                                <input class="form-check-input" type="radio" name="diabetesDanioOrgano" [(ngModel)]="paciente.diabetesDanioOrgano"
                                                    id="inlineRadio6" value=0>
                                                <label class="form-check-label" for="inlineRadio6">No</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="radio-button-container p-3 m-3 col-5 col-md-3 col-lg-2 m-lg-2">
                                        <div class="form-group">
                                            <label for="obesidad">Obesidad:</label>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="obesidad" [(ngModel)]="paciente.obesidad"
                                                    id="inlineRadio7" value="1">
                                                <label class="form-check-label" for="inlineRadio7">Si</label>
                                            </div>
                                            <div class="form-check ">
                                                <input class="form-check-input" type="radio" name="obesidad" [(ngModel)]="paciente.obesidad"
                                                    id="inlineRadio8" value="0">
                                                <label class="form-check-label" for="inlineRadio8">No</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="radio-button-container p-3 m-3 col-5 col-md-3 col-lg-2 m-lg-2">
                                        <div class="form-group">
                                            <label for="hipertension">Hipertensión:</label>
                                            <div class="form-check">
                                                <input class="form-check-input" type="radio" name="hipertension" [(ngModel)]="paciente.hipertension"
                                                    id="inlineRadio12" value="1">
                                                <label class="form-check-label" for="inlineRadio12">Si</label>
                                            </div>
                                            <div class="form-check ">
                                                <input class="form-check-input" type="radio" name="hipertension" [(ngModel)]="paciente.hipertension"
                                                    id="inlineRadio13" value="0">
                                                <label class="form-check-label" for="inlineRadio13">No</label>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="radio-button-container p-3 m-3 col-11 col-md-7 col-lg-3 m-lg-2">
                                        <div class="form-group">
                                            <label for="enfermedadRenalCronica">Enfermedad renal crónica:</label>
                                            <div class="form-check ">
                                                <input class="form-check-input" type="radio"
                                                    name="enfermedadRenalCronica" [(ngModel)]="paciente.enfermedadRenalCronica" id="inlineRadio9"
                                                    value=4 (change)="onRenalChange();">
                                                <label class="form-check-label" for="inlineRadio9">Si</label>
                                            </div>
                                            <div class="radio-button-container  col-12" *ngIf="verRenal">
                                                <div class="form-group ">
                                                    
                                                    <div class="form-check ">
                                                        <input class="form-check-input" type="radio"
                                                            name="enfermedadRenalCronica" [(ngModel)]="paciente.enfermedadRenalCronica" id="inlineRadio14"
                                                            value=1>
                                                        <label class="form-check-label" for="inlineRadio14">Sin diabetes
                                                            crónica</label>
                                                    </div>
                                                    <div class="form-check ">
                                                        <input class="form-check-input" type="radio"
                                                            name="enfermedadRenalCronica" [(ngModel)]="paciente.enfermedadRenalCronica" id="inlineRadio10"
                                                            value=2 >
                                                        <label class="form-check-label" for="inlineRadio10">Con diabetes
                                                            crónica</label>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div class="form-check ">
                                                <input class="form-check-input" type="radio"
                                                    name="enfermedadRenalCronica" [(ngModel)]="paciente.enfermedadRenalCronica" id="inlineRadio11"
                                                    value=0 (click)="onRenalNo()">
                                                <label class="form-check-label" for="inlineRadio11">No</label>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                            <div class="card-footer">
                                <div class="form-group">
                                    <input type="submit" class="btn btn-primary" value="Ingresar">
                                    <button (click)="cancelCarga()" class="btn btn-secondary ml-3">Cancelar</button>
                                </div>
                                <p class="text-secondary"><small>Los datos identificados con <span
                                            class="text-danger">*</span> son obligatorios</small></p>
                            </div>
                        </div><!-- /Card -->
                    </form>
                </div><!-- /Datos paciente -->

            </div><!-- /Datos paciente -->


        </div><!-- /Card-body -->
    </div><!-- /Card -->


</div>
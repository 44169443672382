import { Injectable } from '@angular/core';

import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class IslaService {

  url: string;
  headers;
  token;

  constructor(
    private _http: HttpClient
  ) {
    this.url = GLOBAL.url;
    this.token = localStorage.getItem('token');
    this.headers = {
      'content-type': 'application/json',
    };
  }

  getAll() {

    var data = {
      "version": "0.0",
      "id": Math.floor((Math.random() * 10000) + 1),
      "params": {
        "sync_id": 0
      },
      "method": "get_islas",
      "token": this.token
    }
    return this._http.post(this.url, data, { headers: this.headers }).pipe(map(res => res));
  }

  create(model) {

    var data = {
      "version": "0.0",
      "method": "new_isla",
      "params": {
        "data": {
          "idHospital": model.idHospital,
          "idIsla": model.idIsla,
          "idLider": model.idLider
        }
      },
      "id": Math.floor((Math.random() * 10000) + 1),
      "token": this.token
    }

    return this._http.post(this.url, data, { headers: this.headers }).pipe(map(res => res));
  }

  /* 
  update(model, idHospital: number, idIsla: string) {
    let params = JSON.stringify(model);
    console.log(params);
    return this._http.put(this.url + 'isla/' + idHospital + '/' + idIsla, params, { headers: this.headers }).pipe(map(res => res));
  }

  destroy(idHospital: number, idIsla: string) {
    return this._http.delete(this.url + 'isla/' + idHospital + '/' + idIsla, { headers: this.headers }).pipe(map(res => res));
  }
 */
}

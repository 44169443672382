<!-- Vista de usuario logueado -->
<div class="header" *ngIf="identity">
    <nav class="navbar navbar-expand-lg navbar-dark bg-primary">
        <!-- <a class="navbar-brand" href="#">

            Hospital {{hospital[0].nombre}}</a> -->
        <div class="navbar-brand">
        <div class="d-flex flex-nowrap ">
            <label for="hospital" class="">Hospital </label>
           
                <span class="ml-2" *ngIf="hospitales.length == 1" >{{hospitales[0].nombre}}</span>
                <select *ngIf="hospitales.length > 1" class="form-control ml-3" id="hospital" style="min-width: 300px;" [(ngModel)]="hospital.nombre" (change)="onHospitalChange(hospital.value)">
                    <option  *ngFor="let hospi of hospitales" value="{{hospi.nombre}}">{{hospi.nombre}}</option>
                </select>
         
        </div>
    </div>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse justify-content-end mr-4" id="navbarNavDropdown">
            <ul class="navbar-nav ">
                <li class="nav-item ">
                    <a class="nav-link" [routerLink]="[ '/home' ]" routerLinkActive="active">Inicio <span
                            class="sr-only">(current)</span></a>
                </li>
                
                <li class="nav-item ">
                    <a class="nav-link" [routerLink]="[ '/pacientes' ]" routerLinkActive="active">
                        Pacientes
                    </a>
                </li>

                <!-- <li class="nav-item ">
                    <a class="nav-link" [routerLink]="[ '/alertas' ]" routerLinkActive="active">Alertas</a>
                </li> -->

<!--                 <li class="nav-item dropdown" routerLinkActive="active">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLinkEpisodio" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Pacientes
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLinkEpisodio">
                        <a class="dropdown-item " [routerLink]="[ '/episodio-create']" routerLinkActive="active">
                            Ingreso
                        </a>

                        <a class="dropdown-item " [routerLink]="[ '/episodio-close']" routerLinkActive="active">
                            Egreso
                        </a>

                        <div class="dropdown-divider"></div>
                        <a class="dropdown-item " [routerLink]="[ '/pacientes']" routerLinkActive="active">
                            Listado de pacientes
                        </a>
                    </div>
                </li> --><!-- /Episodios -->

                 <!--<li class="nav-item dropdown" routerLinkActive="active">
     
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLinkPaciente" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Datos
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLinkPaciente">
                        <a class="dropdown-item" [routerLink]="[ '/paciente-comorbilidad']" routerLinkActive="active">Comorbilidades</a>
                        <a class="dropdown-item" [routerLink]="[ '/laboratorios']" routerLinkActive="active">Laboratorio</a>
                        <a class="dropdown-item" [routerLink]="[ '/rx-torax']" routerLinkActive="active" >RX Torax</a>
                        <a class="dropdown-item" [routerLink]="[ '/control-enfermeria']" routerLinkActive="active">Control Enfermería</a>
                    </div> 
                </li>--><!-- /Datos cargados -->

               

                <li class="nav-item dropdown" routerLinkActive="active" *ngIf="_usuSvc.isAdmin || _usuSvc.isJefeEnfermeria || _usuSvc.isJefeClinicaMedica ||_usuSvc.isJefeLaboratorio" >
                    <!-- Sector -->
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLinkIsla" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        Administración
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLinkIsla">
                        <a class="dropdown-item" [routerLink]="[ '/sectores']" routerLinkActive="active">Recursos Físicos</a>
                        <a *ngIf="_usuSvc.isAdmin || _usuSvc.isJefeEnfermeria || _usuSvc.isJefeClinicaMedica || _usuSvc.isJefeLaboratorio" class="dropdown-item" [routerLink]="[ '/usuarios']" routerLinkActive="active">Recursos Humanos</a>
                    </div>
                </li><!-- /Sector -->




                <li class="nav-item dropdown" routerLinkActive="active">
                    <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button"
                        data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {{identity.email}}
                    </a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                        <a class="dropdown-item" href="#" (click)="logout()">Salir</a>
                    </div>
                </li>
            </ul>
        </div>
    </nav>

    <div class="container pt-3 pb-3">
        <router-outlet></router-outlet>
    </div>
</div><!-- /Identificado -->



<!-- Vista en caso de no estar logueado -->
<div class="login-form container pt-5 pb-5" *ngIf="!identity">
    <div class="d-flex justify-content-center pb-2">
        <img src="assets/images/logo.jpeg" width="200" alt="">
    </div>
    <div class="card">
        <div class="card-header">
            <div class="login-title text-center">
                <h2>Ingresar al sistema</h2>
            </div>
        </div>
        <div class="card-body">
            <div class="alert alert-danger" *ngIf="errorMessage">
                {{errorMessage}}
            </div>
            <div class="login-form col-md-8 col-lg-6 d-block mx-auto">
                <form #loginForm="ngForm" (ngSubmit)="onSubmit()">
                    <div class="form-group">
                        <label for="cuil">Cuil:</label>
                        <input type="cuil" class="form-control" #cuil="ngModel" name="cuil" [(ngModel)]="user.cuil"
                            required=true />
                        <span *ngIf="!cuil.valid && cuil.touched" class="text-danger">
                            El cuil es obligatorio
                        </span>
                    </div>

                    <div class="form-group">
                        <label for="clave">Contraseña:</label>
                        <input type="password" class="form-control" #clave="ngModel" name="clave"
                            [(ngModel)]="user.clave" required>
                        <span *ngIf="!clave.valid && clave.touched" class="text-danger">
                            La contraseña es obligatoria
                        </span>
                    </div>

                    <div class="form-group">
                        <input type="submit" class="btn btn-primary w-100" value="Ingresar">
                    </div>
                </form>
            </div>
        </div>
    </div>

</div><!-- /no Identificado -->
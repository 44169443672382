import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GLOBAL } from './global';
import { map } from 'rxjs/operators';
import { ABMBaseService } from './ABMBase.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class EpisodioService extends ABMBaseService {

  constructor(
    protected _http: HttpClient,
    protected _toastr: ToastrService
  ) {
    super(_http, _toastr);
  }

 
}

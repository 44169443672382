export class RxTorax {
    constructor(
        public idHospitalRad: number,
        public numeroHCRad:string,
        public fechaRad: number,
        public cuil: string,
        public resultadoRad: string, //infiltrado bilateral / infiltrado unilateral /consolidación bilateral / consolidación unilateral / sin infiltrado
        public sync_id?: number
    ){}
}

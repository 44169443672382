import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CheckLoginGuard implements CanActivate {
  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

      const usuario = JSON.parse(localStorage.getItem('identity'));
      var resp: boolean = false;
      if(usuario) resp = true;
      console.log('Usuario logueado-->', resp);
            
    return resp;
  }
  
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterUsuario'
})
export class FilterUsuarioPipe implements PipeTransform {

  transform(value: any, arg: any): any {
     if(arg == '' || arg.length < 2){return value}

    const res = [];
    value.forEach(usu =>{ 
      
      if(usu.email.toLowerCase().includes(arg.toLowerCase()) 
        || usu.apellido.toLowerCase().includes(arg.toLowerCase()) 
        || usu.telefono.toLowerCase().includes(arg.toLowerCase()) 
        || usu.cuil.toString().includes(arg)
        ){
        res.push(usu);
      }
    });
    return res;
  }
  

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'

/* Models */
import { Paciente } from '../../../models/paciente';
import { RxTorax } from '../../../models/rx-torax';


/* Services */
import { UsuarioService } from '../../../services/usuario.service';
import { ToastrService } from 'ngx-toastr';
import { AlertaService } from '../../../services/alerta.service';
import { Alerta } from 'src/app/models/alerta';

import * as moment from 'moment';
import { ABMBaseService } from 'src/app/services/ABMBase.service';


@Component({
  selector: 'app-rx-torax',
  templateUrl: './rx-torax.component.html',
  styleUrls: ['./rx-torax.component.css'],
  providers: [ABMBaseService, UsuarioService, ToastrService]
})
export class RxToraxComponent implements OnInit {

  title: string;
  errorMessage: string = '';
  paciente: Paciente;
  pacientes: Paciente[];
  rxTorax: RxTorax;
  filtrarPaciente = '';
  edad: number;
  identity;
  hospital;
  numeroHC;

  constructor(
    private _usuarioService: UsuarioService,
    private _abmSvc: ABMBaseService,
    private _router: Router,
    private _toastr: ToastrService,
    private _alertaService: AlertaService,
    private _activeRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.numeroHC = this._activeRoute.snapshot.params.numeroHC;
    this.title = 'Carga de Rx de Torax';
    this.identity = this._usuarioService.getIdentity();
    this.hospital = this._usuarioService.getHospital();
    this.getPacientes();
    this.rxTorax = new RxTorax(this.hospital.idHospital, '', null, this.identity.cuil, '')
  }

  onSubmit(rx) {
    console.log('manda', rx);

    if (rx.resultadoRad) {
      this.rxTorax.resultadoRad = rx.resultadoRad;
      this.rxTorax.fechaRad = moment().unix();
      this._abmSvc.create('rx_torax', this.rxTorax).subscribe(
        response => {

          console.log('RespuestaRXTorax', response);
          if(response.status.includes("200")){
            // this.paciente = null;
            this.rxTorax = new RxTorax(this.hospital.idHospital, '', null, this.identity.cuil, '')
            this._alertaService.pedirAlertas(true);
          }



          //   this._abmSvc.getAll('alertas').subscribe(resp => {
          //     console.log('alertassss',resp);
          //     this._router.navigate(['/pacientes']);

          // })
          /* var gravedadAlerta = response['result'].triage;
          var alerta = new Alerta(this.rxTorax.idHospitalRad, this.rxTorax.numeroHCRad, this.rxTorax.fechaRad, gravedadAlerta, null, '', '', '', null, '', '', null);

          this._alertaService.create('alerta', alerta).subscribe(Response => {
            this._toastr.success('Gracias', 'Datos guardados con éxito')
            this.paciente = null;
            this.rxTorax = new RxTorax(this.hospital.idHospital, '', null, this.identity.cuil, '')
          }, error => {
            this._toastr.error('Error al guardar el alerta', 'Atención!');
          })
        */

        }, error => {
          this.errorMessage = 'Error al guardar los datos'
          console.log(error.message);
        })
    }
  }


  /**
   * Identifica el paciente seleccionado, se calcula la edad y asigna nro de historia clinica
   * @param valor objeto<Paciente>
   */
  selPaciente(valor) {
    this.paciente = valor;
    this.errorMessage = '';
    this.edad = this._abmSvc.getEdad(this.paciente.fechaNac)
    this.rxTorax.numeroHCRad = this.paciente.numeroHC
  }

  /**
   * Cancela la carga de datos
   */
  cancelCarga() {
    if(this.numeroHC) this._router.navigate(['/pacientes'])
    this.paciente = null;
  }

  /**
   * Busca todos los pacientes cargados en la base de datos
   */
  getPacientes() {
    this._abmSvc.getAll('hcpacientes').subscribe(
      response => {

        var datos = response['result'].data;
        if (datos) {
          this.pacientes = datos;
          this.paciente = this.pacientes.filter(item => item.numeroHC == this.numeroHC)[0];
          this.edad = this._abmSvc.getEdad(this.paciente.fechaNac)
          this.rxTorax.numeroHCRad = this.paciente.numeroHC
        } else {
          //this._usuarioService.logout()
        }
      }, error => {
        this.errorMessage = 'Error al obtener los pacientes';
        console.log(error.message)
        //this._usuarioService.logout();
      }
    )
  }


}

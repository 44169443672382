import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Episodio } from 'src/app/models/episodio';
import { EpisodioService } from 'src/app/services/episodio.service';
import { Paciente } from 'src/app/models/paciente';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { ABMBaseService } from 'src/app/services/ABMBase.service';

@Component({
  selector: 'app-end-episodio',
  templateUrl: './end-episodio.component.html',
  styleUrls: ['./end-episodio.component.css'],
  providers: [ ToastrService, UsuarioService, ABMBaseService, EpisodioService],
})
export class EndEpisodioComponent implements OnInit {

  title: string;
  errorMessage: string = '';
  identity;
  hospital;
  pacientes: any[];
  paciente: Paciente = null;
  numeroHC: string;
  episodios: Episodio[];
  episodio: Episodio;
  dni;
  edad: number;
  cargarPaciente: boolean = false;
  verPaciente: boolean = false;
  internado: boolean = false;
  showEgresoButton: boolean = false

  constructor(
    private _usuarioService: UsuarioService,
    private _abmSvc: ABMBaseService,
    private _episodioService: EpisodioService,
    private _router: Router,
    private _toastr: ToastrService,
    private _activeRoute: ActivatedRoute
  ) { }

  ngOnInit() {

    this.numeroHC = this._activeRoute.snapshot.params.numeroHC;

    this.title = 'Egreso de Paciente';
    this.identity = this._usuarioService.getIdentity();
    this.hospital = this._usuarioService.getHospital();

    this.getPacientes();
    //this.getEpisodios();

  }

  /**
   * Obtiene todos los pacientes cargados en la base de datos
   */
  getPacientes(){
    this._abmSvc.getAll('hcpacientes').subscribe(
      response => {
        if (response['result'].data) {
          this.pacientes = response['result'].data;

          this.getEpisodios();

        }else{
          console.log('Error al obtener los pacientes');
          
        }
      }, error => {
        
        console.log(error.message)
        this._toastr.error(error['error'].message)
      }
    )
  }

  /**
   * Obtiene todos los episodios cargados en la base de datos
   */
  getEpisodios(){
    this._episodioService.getAll('episodios').subscribe(
      response => {
        this.episodios = response['result'].data;

        var pac = this.pacientes.filter(item => item.numeroHC == this.numeroHC);
          if(pac.length > 0){
            this.dni = pac[0].dni
            this.dniChange();
          }

      }, error => {
        console.log(error.message)
        this._toastr.error('Error al buscar los episodios');
      }
    )
  }

  /**
   * Modifica el episodio en la base de datos
   */
  onSubmit(){
    if (!this.paciente || this.paciente.numeroHC == '') {

      this._toastr.error('Por favor, seleccionar un paciente');

    } else {
      
      var fecha = moment().unix();

      alert('Guardar cambios en el episodio del paciente ' + this.paciente.nombre)
    }
  }



  /**
   * Al ingresar el dni del paciente, chequea si ya esta cargado y muestra los datos,
   * de lo contrario, muestra el formulario de carga de un paciente.
   */
  dniChange(): void {

    this.paciente = null;
    if (this.dni != '') {
      this.pacientes.forEach(element => {
        if(element.dni == this.dni){
          this.paciente = element;
          this.edad = this._abmSvc.getEdad(element.fechaNac);
          
        }
      });
      if(this.paciente){

        this.episodios.forEach(element => {
          if (element.numeroHC == this.paciente.numeroHC && element.fechaEgreso == null) {
            this.internado = true;
            this.showEgresoButton = true
          }else{
            this.internado = false;
            this.showEgresoButton = false
          }
        });

        this.verPaciente = true;

      }else{
        this._toastr.error('No hay pacientes con ese número de DNI')
        
      }
    }else{
      this.verPaciente = false;
      
    }
  }
  
  
}

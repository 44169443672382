import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { GLOBAL } from './global';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ControlEnfermeriaService {
  url: string;
  headers;
  token;

  constructor(
    private _http: HttpClient
  ) {
    this.url = GLOBAL.url;
    this.token = localStorage.getItem('token');
    this.headers = {
      'content-type': 'application/json'
    };
  }

  /**
  * So se le pasa un valor de sync_id, devuelve los campos de control de enfermeria correspondientes.
  * Si no se le pasa valor de sync_id, devuelve todos los registros de control de enfermeria de la base de datos
  * @param sync_id number
  */
  getAll(sync_id = 0) {

    var data = {
      "version": "0.0",
      "id": Math.floor((Math.random() * 10000) + 1),
      "params": {
        "sync_id": sync_id
      },
      "method": "get_signos_vitales",
      "token": this.token
    }

    return this._http.post(this.url, data, { headers: this.headers }).pipe(map(res => res));
  }

  /**
   * Carga un registro en la base de datos
   * @param model object<ControlEnfermeria>
   */
  create(model) {

    var data = {
      "version": "0.0",
      "method": "new_signo_vital",
      "params": {
        "data": {
          "id_hospital": model.idHospital,
          "numeroHCSignosVitales": model.numeroHC,
          "fechaSignosVitales": model.fechaNac,
          "auditoria": model.auditoria,
          "frec_resp": model.frec_resp,
          "sat_oxi": model.sat_oxi,
          "disnea": model.disnea,
          "oxigenoSuplementario": model.oxi_suple,
          "fraccionInsOxigeno":model.fraccionInsOxigeno,
          "presSist": model.presion_sist,
          "presDiast": model.presion_diast,
          "frec_card": model.frec_cardiaca,
          "temp": model.temperatura,
          "nivelConciencia": model.nivel_conciencia
        }
      },
      "id": Math.floor((Math.random() * 10000) + 1),
      "token": this.token
    }

    return this._http.post(this.url, data, { headers: this.headers }).pipe(map(res => res));
  }



}

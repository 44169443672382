import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Localidad } from '../models/paciente';
import { ABMBaseService } from './ABMBase.service';

@Injectable({
  providedIn: 'root'
})
export class LocalidadService extends ABMBaseService {

  constructor(
    protected _http: HttpClient,
    protected _toastr: ToastrService,
  ) {
    super(_http, _toastr);
  }

  getLocalidades(id_provincia: number, sync_id = 0): Observable<Object>{


      var data = {
        "version": "0.0",
        "id": Math.floor((Math.random() * 10000) + 1),
        "params": {
          "sync_id": sync_id,
          "id_provincia": id_provincia,
        },
        "method": `get_localidades`,
        "token": this.token
      }

      console.log('peticion localidades', data);



      return this._http.post(this.url, data, { headers: this.headers }).pipe(map(res => res));
    }


}

<!-- Todos los logueados pueden listar pacientes -->
<div *ngIf="identity">
    <div class="card">
        <div class="card-header">
            <div class="page-head">
                <h3>{{title}}</h3>

            </div>
        </div>
        <div class="card-body">
            <div class="d-flex justify-content-between flex-column flex-sm-row">

                <div class="col mb-3 mb-sm-0">
                    <a [routerLink]="['/paciente-create']" class="btn btn-primary mr-3" ><i class="fa fa-plus mr-2"></i>Cargar Paciente</a>
                </div>
                <div class="col">
                    <div class="input-group mb-2 mr-sm-2">
                        <div class="input-group-prepend">
                            <div class="input-group-text"><i class="fas fa-search"></i></div>
                        </div>
                        <input type="text" class="form-control" placeholder="Buscar por Nombre, Apellido, DNI o Hist.Clínica" name="filtrarPaciente"  placement="top" ngbTooltip="Buscar por Nombre, Apellido, DNI o Historia Clínica"
                            [(ngModel)]="filtrarPaciente">
                    </div>
                </div>

            </div>
            <div class="d-flex justify-content-between align-items-center flex-column flex-sm-row">
              <div class="form-group mt-3">
                <div class="custom-control custom-switch">
                  <input type="checkbox" class="custom-control-input" id="customSwitch1" [(ngModel)]="verTodosPacientes" (change)="onVerTodosChange()">
                  <label class="custom-control-label" for="customSwitch1">Ver todos los registrados</label>
                </div>

              </div>
              <div class="d-flex justify-content-between justify-content-sm-end align-items-center flex-wrap">
                  <a (click)="abreEposidio()" class="btn-paciente-option p-1" placement="top" ngbTooltip="Dar ingreso al paciente" *ngIf="_usuSvc.isAdmin || _usuSvc.isAdministrativo">
                      <span style="color:rgb(26, 25, 25)"><i class="fa fa-sign-in-alt" aria-hidden="true"></i></span>
                  </a>
                  <a (click)="cierraEposidio()" class="btn-paciente-option p-1" placement="top" ngbTooltip="Dar salida al paciente" *ngIf="_usuSvc.isAdmin || _usuSvc.isAdministrativo">
                      <span style="color:rgb(26, 25, 25)"><i class="fa fa-ban" aria-hidden="true"></i></span>
                  </a>
                  <a (click)="editaPaciente()" placement="top" ngbTooltip="Tooltip on bottom" class="btn-paciente-option p-1" placement="top" ngbTooltip="Opción no habiliatada en esta versión" *ngIf="_usuSvc.isAdmin || _usuSvc.isAdministrativo">
                      <span style="color:rgb(26, 25, 25)"><i class="far fa-edit"></i></span>
                  </a>
                  <a (click)="muestraDetallePaciente(content)" class="btn-paciente-option p-1"  placement="top" ngbTooltip="Ver lista de internaciones"  *ngIf="!_usuSvc.isLaboratorio && !_usuSvc.isJefeLaboratorio">
                      <span style="color:rgb(26, 25, 25)"><i class="fas fa-file-medical"></i></span>
                  </a>

                  <a (click)="cargaComorbilidad()" class="btn-paciente-option p-1"  placement="top" ngbTooltip="Cargar comorbilidades"  *ngIf="_usuSvc.isAdmin || _usuSvc.isMedico || _usuSvc.isJefeClinicaMedica">
                      <span style="color:rgb(26, 25, 25)"><i class="fa fa-heartbeat" aria-hidden="true"></i></span>
                  </a>

                  <a (click)="cargaLaboratorio()" class="btn-paciente-option p-1"  placement="top" ngbTooltip="Cargar Laboratorio"   *ngIf="_usuSvc.isAdmin || _usuSvc.isLaboratorio || _usuSvc.isJefeLaboratorio">
                      <span style="color:rgb(26, 25, 25)"><i class="fa fa-flask" aria-hidden="true"></i></span>
                  </a>

                  <a (click)="cargaRx()" class="btn-paciente-option p-1"  placement="top" ngbTooltip="Cargar Rx Torax"   *ngIf="_usuSvc.isAdmin || _usuSvc.isMedico || _usuSvc.isJefeClinicaMedica">
                      <span style="color:rgb(26, 25, 25)"><strong>Rx</strong></span>
                  </a>

                  <a (click)="cargaControlEnfermeria()" class="btn-paciente-option p-1"  placement="top" ngbTooltip="Cargar Control de Enfermería"   *ngIf="_usuSvc.isAdmin || _usuSvc.isEnfermero || _usuSvc.isJefeEnfermeria">
                      <span style="color:rgb(26, 25, 25)"><i class="fa fa-stethoscope" aria-hidden="true"></i></span>
                  </a>

              </div>
            </div>

            <table class="table table-hover table-striped table-bordered table-sm table-responsive-md">
                <thead>
                    <tr class="bg-primary text-light text-center">
                        <th scope="col" (click)="order('numeroHC')">Hist.Clínica</th>
                        <th scope="col" (click)="order('nombre')">Nombre</th>
                        <th scope="col" (click)="order('apellido')">Apellido</th>
                        <th scope="col" (click)="order('dni')">DNI</th>
                        <th scope="col" (click)="order('genero')">Sexo</th>

                    </tr>
                </thead>

                <tbody *ngIf="pacientes; else sinPacientes">
                    <tr *ngFor="let paciente of pacientes | filterPaciente:filtrarPaciente | orderBy:orderDirection:orderColumn | slice:(page-1)*itemsPerPage:(page-1)*itemsPerPage + itemsPerPage"
                        class="text-center paciente-item"
                        (click)="seleccionaPaciente(paciente.numeroHC)"
                        (dblclick)="showPacienteStatus(paciente)"
                        id="{{'paciente-' + paciente.numeroHC}}"
                    >
                        <th scope="row" >{{paciente.numeroHC}}</th>
                        <td >{{paciente.nombre}}</td>
                        <td >{{paciente.apellido}}</td>
                        <td >{{paciente.dni}}</td>
                        <td >{{paciente.genero}}</td>

                    </tr>
                </tbody>

            </table>

            <div class="mt-3" *ngIf="showPag">
                <ngb-pagination
                    [collectionSize]="totalItems"
                    [(page)]="page"
                    [pageSize]="itemsPerPage"
                    [maxSize]="7"
                    [rotate]="true"
                    [boundaryLinks]="true"

                >
                </ngb-pagination>
            </div>
        </div>
    </div>
</div>

<ng-template #sinPacientes>
    <tr>
        <td colspan="6">
            <div class="text-center my-5 py-5">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Cargando...</span>
                </div>
            </div>
        </td>
    </tr>
</ng-template>

<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Paciente: {{unPaciente?.apellido}} {{unPaciente?.nombre}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>Historia Clínica: <strong>{{unPaciente?.numeroHC}}</strong></p>
      <table class="table table-sm table-hover table-striped">
          <thead class="bg-primary text-light text-center">
              <tr>
                  <th>Fecha Ingreso</th>
                  <th>Fecha Egreso</th>
                  <th>Razón del egreso</th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let e of losEpisodios">
                  <td>{{e.fechaIngreso | date:'dd-MM-yyyy'}}</td>
                  <td>{{e.fechaEgreso | date:'dd-MM-yyyy' }}</td>
                  <td>{{e.razonEgreso}}</td>
              </tr>
          </tbody>
      </table>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close(true)">Cerrar</button>
    </div>
  </ng-template>

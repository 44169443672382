import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';

/* Services */
import { AlertaService } from 'src/app/services/alerta.service';
import { ABMBaseService } from 'src/app/services/ABMBase.service';

/* Models */
import { Alerta } from 'src/app/models/alerta';
import { Paciente } from 'src/app/models/paciente';

@Component({
  selector: 'app-alerta',
  templateUrl: './alerta.component.html',
  styleUrls: ['./alerta.component.css'],
  providers: [ABMBaseService, AlertaService]
})

export class AlertaComponent implements OnInit {

  alertas: Alerta[];
  pacientes: Paciente[];
  activeAlertas: Alerta[] = [];
  ultimo: any;
  alertas$: Observable<Alerta[]>

  constructor(

    private _alertaService: AlertaService,
    private _abmSvc: ABMBaseService

  ) { }

  ngOnInit(): void {

    this.alertas$ = this._alertaService.getAlertas$();
    this.alertas$.subscribe(alertas => this.alertas = alertas);

  }



  getPacientes(): void {
    this._abmSvc.getAll('hcpacientes').subscribe(
      response => {
        console.log('respuesta de pacientes', response);

        this.pacientes = response['result'].data;
        this.getActiveAlerts();

      }, error => {

      }
    )
  }


  getActiveAlerts(): void {
    this.pacientes.forEach(paciente => {
      if(this.alertas){
        var ale = this.alertas.filter(item => {
          return item.numeroHC === paciente.numeroHC
        })

        ale.forEach((e,key,array) => {
          if(key === 0){
            this.ultimo = e;
          }

          if (e) {
            if (this.ultimo.sync_id <= e.sync_id) {
              this.ultimo = e
            }
          }
          this.ultimo.paciente = paciente;

          if (this.ultimo && e.gravedadAlerta){
            this.activeAlertas.push(this.ultimo);
          }
        });
      }


    });
    console.log('alertas por paciente', this.activeAlertas);
  }

  hideAlert(alerta:Alerta): void {
    console.info('////',alerta);
    let ids = this.alertas.map(item => item.sync_id);
    let position = ids.indexOf(alerta.sync_id);
    if(position > -1){
      this.alertas.splice(position, 1);
    }
    this._alertaService.alertasEliminadas.push(alerta);
    this._alertaService.hideAlerta(alerta).subscribe(
      resp => {
        console.log('respuesta de hideAlerta', resp);

      }
    )
  }

}

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Paciente } from 'src/app/models/paciente';

import { UsuarioService } from 'src/app/services/usuario.service';
import { ToastrService } from 'ngx-toastr';
import { AlertaService } from 'src/app/services/alerta.service';
import { ABMBaseService } from 'src/app/services/ABMBase.service';

@Component({
  selector: 'app-comorbilidad',
  templateUrl: './comorbilidad.component.html',
  styleUrls: ['./comorbilidad.component.css'],
  providers: [ UsuarioService, ABMBaseService, ToastrService, AlertaService ]
})
export class ComorbilidadComponent implements OnInit {

  title: string;
  errorMessage: string = ''
  identity;
  hospital;
  pacientes: Paciente[];
  paciente: Paciente;
  filtrarPaciente = '';
  edad: number;
  numeroHC;
  verRenal: boolean = false;

  constructor(
    private _usuarioService: UsuarioService,
    private _abmSvc: ABMBaseService,
    private _router: Router,
    private _toastr: ToastrService,
    private _alertaService: AlertaService,
    private _activeRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.numeroHC = this._activeRoute.snapshot.params.numeroHC;
    this.title = 'Carga de Comorbilidades';
    this.identity = this._usuarioService.getIdentity();
    this.hospital = this._usuarioService.getHospital();
    this.getPacientes();
  }

  getPacientes(){
    this._abmSvc.getAll('hcpacientes').subscribe(
      response => {

        var datos = response['result'].data;

        if(datos){
        this.pacientes = datos;
        this.paciente = this.pacientes.filter(item => item.numeroHC == this.numeroHC)[0];
        this.edad = this._abmSvc.getEdad(this.paciente.fechaNac)
      }else{
        //this._usuarioService.logout();
      }
      }, error => {
        this.errorMessage = 'Error al buscar los pacientes';
        console.log(error.message)
        //this._usuarioService.logout();
      }
    )
  }


  selPaciente(valor){
    this.paciente = valor;
    this.edad = this._abmSvc.getEdad(this.paciente.fechaNac)
  }

  onSubmit(form){

    form.idHospital = this.hospital.idHospital;
    form.numeroHC = this.paciente.numeroHC;
    if(form.diabetesDanioOrgano) form.diabetesDanioOrgano = parseInt(form.diabetesDanioOrgano);
    form.enfermedadRenalCronica = parseInt(form.enfermedadRenalCronica);
    form.epoc = parseInt(form.epoc);
    form.hipertension = parseInt(form.hipertension);
    form.iccGrado2 = parseInt(form.iccGrado2);
    form.obesidad = parseInt(form.obesidad);


    console.log('form', form);

    // this._toastr.error('Pedir a Rodrigo generar el protocolo para la petición de modificación de datos', 'OJO!!!')

    this._abmSvc.update('hcpaciente',form).subscribe(
      response => {
        console.log('respuesta de edit comorbilidades', response);

        if(response.status.includes("200")){
          // this.paciente = null;

          this._alertaService.pedirAlertas(true);
          this._toastr.success('Gracias', 'Dato guardado con éxito',{ timeOut: 2500, positionClass: 'toast-bottom-right', });
        }

      },error => {
        this.errorMessage = "Error al guardar datos";
        console.log(error.message);
      }
    )
  }

  cancelCarga(){
    if(this.numeroHC) this._router.navigate(['/pacientes']);
    this.paciente = null;
  }

  onRenalChange(){
    this.verRenal = true;
    this.paciente.enfermedadRenalCronica = 1;

  }
  onRenalNo(){
    this.verRenal = false;
  }
}

<!-- Todos los logueados pueden listar pacientes -->
<div *ngIf="identity">
  <div class="page-head py-3">
    <h3>{{ title }}</h3>
    <div class="alert alert-danger" *ngIf="errorMessage">
      {{ errorMessage }}
    </div>
  </div>

  <div class="row">
    <div class="col">
      <div class="form-group">
        <a [routerLink]="['/usuario-sector-create']" class="btn btn-primary"
          ><span class="mr-2"><i class="fas fa-plus"></i></span>Nuevo Usuario
          Sector</a
        >
      </div>
    </div>
    <!-- <div class="col">
            <div class="input-group mb-2 mr-sm-2">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fas fa-search"></i></div>
              </div>
              <input type="text" class="form-control" placeholder="Buscar..." name="filtrarUsuario" [(ngModel)]="filtrarUsuario">
            </div>
        </div> -->
  </div>

  <div class="table-responsive-md">
    <table class="table table-hover table-striped table-bordered">
      <thead>
        <tr class="bg-primary text-light text-center">
          <th scope="col">Hospital</th>
          <th scope="col">Isla</th>
          <th scope="col">Sector</th>
          <th scope="col">Usuario</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let usuarioSector of usuarioSectores" class="text-center">
          <td>{{ hospital.nombre }}</td>
          <td>{{ usuarioSector.idIsla }}</td>
          <td>{{ usuarioSector.idSector }}</td>
          <td>{{ usuarioSector.cuil }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

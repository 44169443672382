import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filterCama'
})
export class FilterCamaPipe implements PipeTransform {

  transform(value: any, arg: any): any {




  /*    if(arg == '' || arg.length < 2){return value} */

    value = value?.filter(item =>  item.idSector.toLowerCase().includes(arg.toLowerCase()) 
    || item.idCama.toLowerCase().includes(arg.toLowerCase()) || (arg.toLowerCase().includes('li') && item.numeroHC == null) ||  (arg.toLowerCase().includes('oc') && item.numeroHC != null))



    return value;
   
  }
  

}

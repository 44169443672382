import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import { isNumber } from 'lodash';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Hospital } from 'src/app/models/hospital';

import { Isla } from 'src/app/models/isla';
import { Sector } from 'src/app/models/sector';
import { Usuario } from 'src/app/models/usuario';
import { UsuarioHospital } from 'src/app/models/usuario-hospital';
import { UsuarioSector } from 'src/app/models/usuario-sector';
import { IslaService } from 'src/app/services/isla.service';
import { SectorService } from 'src/app/services/sector.service';
import { UsuarioHospitalService } from 'src/app/services/usuario-hospital.service';
import { UsuarioSectorService } from 'src/app/services/usuario-sector.service';

import { UsuarioService } from 'src/app/services/usuario.service';

declare var $:any;

@Component({
  selector: 'app-usuario',
  templateUrl: './usuario.component.html',
  styleUrls: ['./usuario.component.css'],
  providers: [UsuarioService, IslaService, SectorService, UsuarioSectorService,UsuarioHospitalService]
})
export class UsuarioComponent implements OnInit {

  title: string;
  identity: any;
  hospital: any;
  todosUsuarios: Usuario[];
  usuarios: Usuario[];
  usuariosHospital: UsuarioHospital[];
  usuario: Usuario;
  cuil:string;
  verTabla$ = new Subject;
  verTabla: any;
 
  selectedUsuario: Usuario;
  
  errorMessage: string;
  confirm: boolean;

  //Filter
  filtrarUsuario: string = '';

  islas: Isla[];
  sectores: Sector[];
  todosSectores: Sector[];
  todasIslas: Isla[];
  filtroIsla: string = '';
  filtroSector: string = '';
  usuarioSectores: UsuarioSector[];

  //Sort
  orderAsc: boolean;
  orderColumn: string;
  orderDirection: string;

  //Paginator
  page: any;
  itemsPerPage: number;
  totalItems: number;
  previousPage: any;
  verPaginate: boolean = false;

  constructor(
    private _usuarioService: UsuarioService,
    private _usuarioHospitalService: UsuarioHospitalService,
    private _islaService: IslaService,
    private _sectorService: SectorService,
    private _usuarioSectorService: UsuarioSectorService,
    private _modal: NgbModal,
    private _router: Router,
    private _toastr:ToastrService
  ) { }

  ngOnInit(): void {
    this.title = 'Usuarios';
    this.identity = this._usuarioService.getIdentity();
    this.hospital = this._usuarioService.getHospital();
    //this.getUsuariosHospital();
    this.getUsuarios();
    this.getIslas();
    this.getSectores();
    this.page = 1;
    this.itemsPerPage = 15;
    this.verTabla$.next(false);
    this.verTabla$.subscribe(resp => this.verTabla = resp);


  }

  getUsuarios() {
    
    this._usuarioService.getAll().subscribe(
      response => {

        if (response['result'].sync_id) {

          this.todosUsuarios = response['result'].sync_id;

          console.log('todos usuarios', this.todosUsuarios);
          

          if(this.filtroIsla == '' && this.filtroSector == ''){
            this.usuarios = this.todosUsuarios
          }
          this.usuarios = this.todosUsuarios
          

        

          this.totalItems = this.usuarios.length;

          if (this.totalItems > this.itemsPerPage) {
            this.verPaginate = true;
          }
        
          this._usuarioHospitalService.getAll().subscribe(
            resp => {
              this.usuariosHospital = resp['result'].data;
              console.log('usuariohospital=>', this.usuariosHospital);
          
              this.getUsuarioSectores();
              
              this.usuarios.forEach(el => {

                var usuHospi = this.usuariosHospital.filter(item => item.cuil === el.cuil && item.idHospital === this.hospital.idHospital)[0];
                var estado: string;
                var rol: string;
                if(usuHospi){
                  var id: number;
                  //Agrega un campo rol a cada usuario
                  if(isNumber(usuHospi.idRol)){id = usuHospi.idRol}else{id = parseInt(usuHospi.idRol)}
                  
                  switch (id) {
                    case 0:
                      rol = 'Administrador'
                      break;
                    case 1:
                      rol = 'Jefe Enfermeria';
                      break;
                    case 2:
                      rol = 'Jefe Clínica Médica';
                      break;
                    case 3: 
                      rol = 'Jefe Laboratorio';
                      break;
                    case 4:
                      rol = 'Enfermero';
                      break;
                    case 5:
                      rol = 'Médico';
                      break;
                    case 6:
                      rol = 'Laboratorio';
                      break;
                    case 7: 
                      rol = 'Administrativo';
                      break;
                    case null:
                      rol = 'Sin rol';
                      break;
                    default:
                      rol = 'Sin rol';
                      break;
                  }
      
                  //Agrega un campo a cada usuario del estado laboral
                  
                  var es = usuHospi.estadoLaboral;
                  switch (es) {
                    case 0:
                      estado = 'Activo'
                      break;
                    case 1:
                      estado = 'Licencia';
                      break;
                    case 2:
                      estado = 'Baja';
                      break;
                    case null:
                      estado = 'Sin estado'
                      break;
                    default:
                      estado = 'Sin estado';
                      break;
                  }
                  
                }else{
                  rol = '';
                  estado = '';
                }
                el.extra = {rol: rol, estadoLaboral: estado};
              });


              this.verTabla$.next(true);

            },error => {
              console.log('Error al obtener los UsuariosHospitales', error);
            }
          )
          
          // NO FUNCIONA BIEN LA ASIGNACIÓN DE ROL Y ESTADO EN LA TABLA DEL FRENTE

     
          
        }else{
          console.log('No hay datos en la respuesta del servidor');
          
          //this._usuarioService.logout()
        }
      }, error => {
        this.errorMessage = 'Error al obtener los usuarios';
        console.log(error.message)
        //this._usuarioService.logout();
      }
    )
  }

  getUsuariosHospital(){
    this._usuarioHospitalService.getAll().subscribe(
      resp => {
        this.usuariosHospital = resp['result'].data;
        console.log('usuario hospital', this.usuariosHospital);
        
        
      },error => {
        console.log('Error al obtener los UsuariosHospitales', error);
      }
    )
  }

  confirmDel() {

    if(this.cuil){
    this.confirm = confirm('Está seguro que el usuario no trabaja mas en esta entidad?')

      if (this.confirm) {
        this.usuario = this.usuarios.filter(item => item.cuil == this.cuil)[0];
        this.usuario.estado = 'inactivo'
        console.log(this.usuario)
        
        this._usuarioService.editUsuario(this.usuario).subscribe(
          response => {
            
            console.log(response)
            
          }, error => {
            this.errorMessage = <any>error.message;
            if (this.errorMessage != null) {
              console.log(error);
            }
          }
        )
      } 
    }else{
      this._toastr.warning('Por favor, seleccionar un usuario');
    }
  }

   /** Ordena los datos de acurdo a las columnas que se seleccione
  */
  order(column: string){
    this.orderAsc = !this.orderAsc;

    if(this.orderAsc){
      this.orderDirection = 'asc'
    }else{
      this.orderDirection = 'desc'
    }

    this.orderColumn = column;

  }  

  getIslas(){
    this._islaService.getAll().subscribe(
      resp => {
        this.islas = resp['result'].data;
        this.todasIslas = this.islas;

        console.log('todas islas', this.islas);
        
      }, error => {
        console.log(error);
        
      }
    )
  }

  getSectores(){
    this._sectorService.getAll().subscribe(
      resp => {
        this.todosSectores = resp['result'].data;
        this.sectores = this.todosSectores;
        console.log('todos sectores', this.sectores);
        
      }, error => {
        console.log(error);
        
      }
    )
  }

  
  getUsuarioSectores(){
    this._usuarioSectorService.getAll().subscribe(
      resp => {
        this.usuarioSectores = resp['result'].data;
        console.log('usuario sectores', this.usuarioSectores);
        
        this.todosUsuarios.forEach(usu => {
          
          var uS = this.usuarioSectores.filter(item => item.cuil == usu.cuil);
          
          if(usu.extra)  usu.extra.sectores = uS;
          
        });

      }, error => {
        console.log(error);
        
      }
    )
  }

   sectorActivo: boolean = true;

   onSectorChange(){

    this.usuarios = this.todosUsuarios;

    var usuariosPorSector = this.usuarioSectores;
    var usuariosFiltrados = [];

    if(this.filtroSector != '') {

      usuariosPorSector = usuariosPorSector.filter(item => item.idSector == this.filtroSector)

      usuariosPorSector.forEach(uS => {
        usuariosFiltrados.push(...this.todosUsuarios.filter(item => item.cuil == uS.cuil));
      });

      this.usuarios = usuariosFiltrados;
    }


    
  }

  
  datos(content, usuario){
    this.selectedUsuario = usuario;

    this._modal.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      
      console.log(result);
      
    }, (reason) => {
      
    });
  }

  editarUsuario(){
    if(this.cuil){
      this._router.navigate(['/usuario-edit', this.cuil]);
    }else{
      this._toastr.warning('Por favor, seleccione un Usuario')
    }
  }

  seleccionaUsuario(id){
    this.cuil = id;
    $(`.usuario-item`).removeClass('selected');
    $(`#usuario-${id}`).addClass('selected');
    // console.log($(`#usuario-${id}`));
    
  
  }

}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './components/home/home.component';

/* Paciente */
import { PacienteComponent } from './components/paciente/paciente.component';
import { CreatePacienteComponent } from './components/paciente/create-paciente/create-paciente.component';
import { ComorbilidadComponent } from './components/paciente/comorbilidad/comorbilidad.component';

/* Laboratorio */
import { LaboratorioComponent } from './components/paciente/laboratorio/laboratorio.component';

/* Usuario */
import { UsuarioComponent } from './components/usuario/usuario.component';
import { CreateUsuarioComponent } from './components/usuario/create-usuario/create-usuario.component';

/* Sector */
import { SectorComponent } from './components/sector/sector.component';
import { CreateSectorComponent } from './components/sector/create-sector/create-sector.component';

/*  Usuario Sector */
import { UsuarioSectorComponent } from './components/usuario-sector/usuario-sector.component';
import { CreateUsuarioSectorComponent } from './components/usuario-sector/create-usuario-sector/create-usuario-sector.component';

/* Episodio */
import { EpisodioComponent } from './components/episodio/episodio.component';
import { CreateEpisodioComponent } from './components/episodio/create-episodio/create-episodio.component';
import { EditEpisodioComponent } from './components/episodio/edit-episodio/edit-episodio.component';

/* Rx Torax */
import { RxToraxComponent } from './components/paciente/rx-torax/rx-torax.component';

/* Control Enfermeria */
import { ControlEnfermeriaComponent } from './components/paciente/control-enfermeria/control-enfermeria.component';

/* Cama */
import { CamaComponent } from './components/cama/cama.component';

/* Alerta */
import { AlertaComponent } from './components/alerta/alerta.component';
import { EndEpisodioComponent } from './components/episodio/end-episodio/end-episodio.component';
import { CheckLoginGuard } from './guards/check-login.guard';
import { IsJefeGuard } from './guards/is-jefe.guard';

const routes: Routes = [

  /* Rutas de Home */
  {path:'', component: HomeComponent},
  {path:'home', component: HomeComponent},

  /* Rutas de paciente */
  {path:'pacientes', component: PacienteComponent, canActivate:[CheckLoginGuard]},
  {path:'paciente-create', component: CreatePacienteComponent, canActivate:[CheckLoginGuard]},
  {path:'paciente-edit/:numeroHC', component: CreatePacienteComponent, canActivate:[CheckLoginGuard] },
  {path:'paciente-comorbilidad', component: ComorbilidadComponent, canActivate:[CheckLoginGuard] },
  {path:'paciente-comorbilidad/:numeroHC', component: ComorbilidadComponent, canActivate:[CheckLoginGuard] },

  /* Rutas de laboratorio */
  {path:'laboratorios', component:LaboratorioComponent, canActivate:[CheckLoginGuard]},
  {path:'laboratorio-create/:numeroHC', component:LaboratorioComponent, canActivate:[CheckLoginGuard]},

  /* Rutas de Usuario */
  {path:'usuarios', component:UsuarioComponent, canActivate:[CheckLoginGuard, IsJefeGuard]},
  {path:'usuario-create', component:CreateUsuarioComponent, canActivate:[CheckLoginGuard]},
  {path:'usuario-edit/:cuil', component:CreateUsuarioComponent, canActivate:[CheckLoginGuard]},

  /* Isla */
/*   {path:'islas', component: IslaComponent, canActivate:[CheckLoginGuard]},
  {path:'isla-create', component: CreateIslaComponent, canActivate:[CheckLoginGuard]},
  {path:'isla-edit/:idIsla', component: EditIslaComponent, canActivate:[CheckLoginGuard]},
   */
  /* Sector */
  // {path:'sectores/:idIsla', component: SectorComponent, canActivate:[CheckLoginGuard]},
  {path:'sectores', component: SectorComponent, canActivate:[CheckLoginGuard]},
  // {path:'sector-create/:idIsla', component: CreateSectorComponent, canActivate:[CheckLoginGuard]},
  {path:'sector-create', component: CreateSectorComponent, canActivate:[CheckLoginGuard]},
  {path:'sector-edit/:id', component: CreateSectorComponent, canActivate:[CheckLoginGuard]},

  /* Usuario Sector */
  {path:'usuario-sectores', component: UsuarioSectorComponent, canActivate:[CheckLoginGuard]},
  {path:'usuario-sector-create', component: CreateUsuarioSectorComponent, canActivate:[CheckLoginGuard]},

  /* Episodio */
  {path:'episodios', component: EpisodioComponent, canActivate:[CheckLoginGuard]},
  {path:'episodio-create', component: CreateEpisodioComponent, canActivate:[CheckLoginGuard]},
  {path:'episodio-edit/:idHospital/:numeroHC', component: EditEpisodioComponent, canActivate:[CheckLoginGuard]},
  {path:'episodio-close/:numeroHC', component: EndEpisodioComponent, canActivate:[CheckLoginGuard]},

  /* Rx Torax */
  {path:'rx-torax', component: RxToraxComponent, canActivate:[CheckLoginGuard]},
  {path:'rx-torax-create/:numeroHC', component: RxToraxComponent, canActivate:[CheckLoginGuard]},

  /* Control Enfermeria */
  {path:'control-enfermeria', component: ControlEnfermeriaComponent, canActivate:[CheckLoginGuard]},
  {path:'control-enfermeria/:numeroHC', component: ControlEnfermeriaComponent, canActivate:[CheckLoginGuard]},

/* Cama */
  // {path:'camas/:idIsla/:idSector', component: CamaComponent, canActivate:[CheckLoginGuard]},
  {path:'camas/:idSector', component: CamaComponent, canActivate:[CheckLoginGuard]},
  {path:'camas', component: CamaComponent},

/* Alerta */
  {path:'alertas', component: AlertaComponent, canActivate:[CheckLoginGuard]},

  {path:'**', component:HomeComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

<!-- Todos los logueados pueden listar pacientes -->
<div *ngIf="identity">
    <div class="card">
        <div class="card-header">
            <div class="page-head">
                <h3>Listado de Sectores</h3>

            </div>
        </div>
        <div class="card-body">
            <div class="alert alert-danger" *ngIf="errorMessage">
                {{errorMessage}}
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <a [routerLink]="[ '/sector-create' ]" class="btn btn-primary"><span class="mr-2"><i
                                    class="fas fa-plus"></i></span>Nuevo Sector</a>
                    </div>
                </div>
                <!-- <div class="col-fit mr-3 ">
                    <a routerLink='/islas' class="btn btn-secondary mt-2"><i class="fa fa-arrow-left mr-3"></i><span>Volver a Islas</span></a>
                </div> -->
                <div class="col-fit mr-3">
                    <div class="alert alert-secondary">
                        Total de camas libres: {{totalCamasLibres}} de {{totalCamas}}
                    </div>
                </div>
                <!-- <div class="col">
            <div class="input-group mb-2 mr-sm-2">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fas fa-search"></i></div>
              </div>
              <input type="text" class="form-control" placeholder="Buscar..." name="filtrarUsuario" [(ngModel)]="filtrarUsuario">
            </div>
        </div> -->
            </div>

            <table class="table table-hover table-striped table-bordered table-sm">
                <thead>
                    <tr class="bg-primary text-light text-center">
                       <!--  <th scope="col">Hospital</th>
                        <th scope="col">Isla</th> -->
                        <th scope="col">Sector</th>
                        <th scope="col">Descripción</th>
                        <th scope="col">Camas Libres</th>
                        <th scope="col">Opciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let sector of sectores
                        | slice
                            : (page - 1) * itemsPerPage
                            : (page - 1) * itemsPerPage + itemsPerPage" class="text-center"
                    >
                        <!-- <td>{{hospital.nombre}}</td>
                        <td>{{sector.idIsla}}</td> -->
                        <td>{{sector.idSector}}</td>
                        <td>{{sector.descripcion}}</td>
                        <td>{{sector.cantCamasLibres}} de {{sector.cantCamas}}</td>
                        <td>
                            <div class="d-flex justify-content-center align-items-center">
                                <a [routerLink]="[ '/camas', sector.idSector]" class="mr-3 text-dark" title="Ver camas">
                                    <span class="icon"> <i class="fas fa-bed"></i></span>
                                </a>

                                <a [routerLink]="[ '/sector-edit', sector.idSector]">
                                    <span class="icon"> <i class="far fa-edit"></i></span>
                                </a>

                                <button (click)="confirmDel(sector)" class="btn  btn-default">
                                    <span class="icon"> <i class="far fa-trash-alt"></i></span>
                                </button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="mt-3" *ngIf='verPag'>
                <ngb-pagination
                  [collectionSize]="totalItems"
                  [(page)]="page"
                  [pageSize]="itemsPerPage"
                  [maxSize]="7"
                  [rotate]="true"
                  [boundaryLinks]="true"
                >
                </ngb-pagination>
              </div>
        </div>
    </div>



</div>

export class Paciente {
    constructor(
        public idHospital: number,
        public numeroHC: string,
        public tipoDocumento: string, //DNI / LC  / LE / PASAPORTE
        public paisExp: string,
        public dni: number,
        public nombre: string,
        public apellido: string,
        public nacionalidad: string,
        public genero:string,
        public calle: string,
        public numero: number,
        public piso: string,
        public id_loc: number,
        public id_provincia: number,
        public CP: string,
        public telefono: string,
        public telefonoFamiliar: string,
        public telefonoFamiliar2: string,
        public fechaNac: number, //ojo que estaba como Date
        public gravedadAlerta: number, // 1...4
        public nivelConfianza: number, // 0...100
        public auditoriaComorbilidades: string,
        public iccGrado2: number, //sí = 1 / no = 0 / sin datos = -1
        public epoc: number, //sí = 1 / no = 0 / sin datos = -1
        public diabetesDanioOrgano: number, //sí = 1 / no = 0 / sin datos = -1
        public hipertension: number, //sí = 1 / no = 0 / sin datos = -1
        public obesidad: number, //sí = 1 / no = 0 / sin datos = -1
        public enfermedadRenalCronica: number, //no =0 / sí, sin diálisis crónica = 1 / sí, con diálisis crónica = 2 / Sin datos = 0
        public sync_id?: number
    ){}
}

export class Provincia {

    public id_provincia: number;
    public nombre: string
}

export class Localidad {
    public id_provincia: number;
    public id_loc: number;
    public nombre: string;

}

<!-- Enfermero puede cargar un usuario -->
<div *ngIf="identity">
    <form #createUsuarioSectorForm="ngForm" (ngSubmit)="onSubmit()">
        <div class="card">
            <div class="card-header">
                <div class="page-head py-3">
                    <h3>{{title}}</h3>
                </div>
            </div>
            <div class="card-body">
                <div class="alert alert-danger" *ngIf="errorMessage">
                    {{errorMessage}}
                </div>

                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label for="idHospital">Hospital: {{hospital.nombre}}</label>
                            <input type="text" hidden class="form-control" #idHospital="ngModel" name="idHospital" [(ngModel)]="usuarioSector.idHospital"
                                readonly>
                            <!-- <span *ngIf="!idHospital.valid && idHospital.touched" class="text-danger">
                                El hospital es obligatorio
                            </span> -->
                        </div>
                    </div>
                </div>
                <div class="row">
                   
                    <div class="col">
                        <div class="form-group">
                            <label for="idSector">Sector:</label>
                            <select name="idSector" #idSector="ngModel" class="form-control"
                                [(ngModel)]="usuarioSector.idSector" (change)='onSectorSelectChange(idSector.value)' autofocus>
                                    <option value={{sector.idSector}} *ngFor='let sector of sectores'>{{sector.idSector}}</option>
                            </select>
                        </div>
                    </div>
                
                    <div class="col">   
                        <div class="form-group">
                            <label for="cuil">Usuario:</label>
                            <select name="cuil" #cuil="ngModel" class="form-control"  [disabled]='idSector.value == "" ? "true" : "false"'
                                [(ngModel)]="usuarioSector.cuil" (change)='onUsuarioSelectChange(cuil.value)'>
                                    <option value={{usuario.cuil}} *ngFor='let usuario of availableUsuarios'>{{usuario.apellido}} {{usuario.nombre}}</option>
                            </select>
                        </div>
                
                    </div>
                    
                </div>
            </div>
            <div class="card-footer">      
                <div class="form-group d-flex justify-content-end">
                    <button type="submit" class="btn btn-primary" [disabled]="!showAceptar">Ingresar</button>
                    <a [routerLink]="[ '/usuarios' ]" class="btn btn-secondary ml-3">Cancelar</a>
                </div>
            </div>
        </div>
    </form>

</div>
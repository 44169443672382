/* Angular */
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';

/* Routing */
import { AppRoutingModule } from './app-routing.module';

/* Bootstrap y FontAwesome */
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
/* Toastr */
import { ToastrModule } from 'ngx-toastr';

/* Pipes */
import { FilterPacientePipe } from './pipes/filter-paciente.pipe';
import { FilterUsuarioPipe } from './pipes/filter-usuario.pipe';
import { FilterEpisodioPipe } from './pipes/filter-episodio.pipe';
import { FilterCamaPipe } from './pipes/filter-cama.pipe';

/* HighCharts */
import { HighchartsChartModule } from 'highcharts-angular';

/* Components */
import { AppComponent } from './app.component';
import { PacienteComponent } from './components/paciente/paciente.component';
import { CreatePacienteComponent } from './components/paciente/create-paciente/create-paciente.component';
import { LaboratorioComponent } from './components/paciente/laboratorio/laboratorio.component';
import { HomeComponent } from './components/home/home.component';
import { HospitalComponent } from './components/hospital/hospital.component';
import { UsuarioComponent } from './components/usuario/usuario.component';
import { CreateUsuarioComponent } from './components/usuario/create-usuario/create-usuario.component';
import { EditUsuarioComponent } from './components/usuario/edit-usuario/edit-usuario.component';
import { IslaComponent } from './components/isla/isla.component';
import { CreateIslaComponent } from './components/isla/create-isla/create-isla.component';
import { EditIslaComponent } from './components/isla/edit-isla/edit-isla.component';
import { SectorComponent } from './components/sector/sector.component';
import { CreateSectorComponent } from './components/sector/create-sector/create-sector.component';
import { UsuarioSectorComponent } from './components/usuario-sector/usuario-sector.component';
import { CreateUsuarioSectorComponent } from './components/usuario-sector/create-usuario-sector/create-usuario-sector.component';
import { EditPacienteComponent } from './components/paciente/edit-paciente/edit-paciente.component';
import { EpisodioComponent } from './components/episodio/episodio.component';
import { CreateEpisodioComponent } from './components/episodio/create-episodio/create-episodio.component';
import { EditEpisodioComponent } from './components/episodio/edit-episodio/edit-episodio.component';
import { ComorbilidadComponent } from './components/paciente/comorbilidad/comorbilidad.component';
import { RxToraxComponent } from './components/paciente/rx-torax/rx-torax.component';
import { ControlEnfermeriaComponent } from './components/paciente/control-enfermeria/control-enfermeria.component';
import { DetallePacienteComponent } from './components/paciente/detalle-paciente/detalle-paciente.component';
import { CamaComponent } from './components/cama/cama.component';
import { OrderByPipe } from './pipes/order-by.pipe';
import { AlertaComponent } from './components/alerta/alerta.component';
import { EstadoXPacienteComponent } from './components/graficos/estado-x-paciente/estado-x-paciente.component';
import { CamasLibresComponent } from './components/graficos/camas-libres/camas-libres.component';
import { EndEpisodioComponent } from './components/episodio/end-episodio/end-episodio.component';
import { UsuarioService } from './services/usuario.service';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';


@NgModule({
  declarations: [
    AppComponent,
    PacienteComponent,
    CreatePacienteComponent,
    LaboratorioComponent,
    FilterPacientePipe,
    HomeComponent,
    HospitalComponent,
    UsuarioComponent,
    FilterUsuarioPipe,
    CreateUsuarioComponent,
    EditUsuarioComponent,
    IslaComponent,
    CreateIslaComponent,
    EditIslaComponent,
    SectorComponent,
    CreateSectorComponent,
    UsuarioSectorComponent,
    CreateUsuarioSectorComponent,
    EditPacienteComponent,
    EpisodioComponent,
    CreateEpisodioComponent,
    EditEpisodioComponent,
    FilterEpisodioPipe,
    ComorbilidadComponent,
    RxToraxComponent,
    ControlEnfermeriaComponent,
    DetallePacienteComponent,
    CamaComponent,
    OrderByPipe,
    FilterCamaPipe,
    AlertaComponent,
    EstadoXPacienteComponent,
    CamasLibresComponent,
    EndEpisodioComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    FontAwesomeModule,
    ToastrModule.forRoot({ timeOut: 3000, positionClass: 'toast-center-center', preventDuplicates: true}),
    NgbModule,
    HighchartsChartModule
  ],
  providers: [UsuarioService,
  {provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }

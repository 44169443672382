import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router'

import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';


import { GLOBAL } from './global';


@Injectable()
export class UsuarioService {

    public url: string;
    public identity;
    public token;
    public hospital;

    constructor(private _http: HttpClient, private _router: Router) {
        this.url = GLOBAL.url;
        this.token = localStorage.getItem('token');
    }

    
    /* Loguea un usuario y devuelve los datos del usuario y el token desde la api */
    /* No realiza validación de usuario */
    singUp(usuario: any) {
        var params = {
            "version": "0.0",
            "id": Math.floor((Math.random() * 10000) + 1),
            "params": {
                "user": usuario.cuil,
                "password": usuario.clave
            },
            "method": "hello",
            "token": "0"
        }
        var headers = { 'Content-Type': 'application/json' };
        
        return this._http.post(this.url, params, { headers: headers }).pipe(map(res => res));
    }

    connect(hospi, sector, isla = null){
        var params = {
            "version": "0.0",
            "id": Math.floor((Math.random() * 10000) + 1),
            "params": {
              "hospital": hospi,
            //   "isla":isla,
              "sector": sector
            },
            "method": "connect",
            "token": this.token
        }
        var headers = { 'Content-Type': 'application/json' };
        
        return this._http.post(this.url, params, { headers: headers }).pipe(map(res => res));
    }

    /** 
    * Saca al usuario del sistema, terminando con la sesion iniciada. Elimina los datos guardados en el localStorage
    */
    logout() {
        localStorage.removeItem('identity');
        localStorage.removeItem('token');
        localStorage.removeItem('hospital');
        localStorage.removeItem('roles');

        localStorage.clear();
        this._router.navigate(['/'])

    }
    
    /* Recupera de la api todos los usuario */
    getAll(): Observable<any> {
        var headers = {
            'Content-Type': 'application/json'
        }

        var data = {
            "version": "0.0",
            "id": Math.floor((Math.random() * 10000) + 1),
            "params": {
                "sync_id": 0
            },
            "method": "get_usuarios",
            "token": this.token
        }

        return this._http.post(this.url, data, { headers: headers }).pipe(map(res => res));
    }

    /* Crear un usuario  */
    crearUsuario(usuario: any): Observable<any> {
        let params = {
            "version": "0.0",
            "id": Math.floor((Math.random() * 10000) + 1),
            "params": {
                "data": {
                    "cuil": usuario.cuil,
                    "clave": usuario.clave,
                    "nombre": usuario.nombre,
                    "apellido": usuario.apellido,
                    "email": usuario.email,
                    "telefono": usuario.telefono
                }
            },
            "method": "new_usuario",
            "token": this.getToken()
        };

        let headers = {
            'Content-Type': 'application/json',
        };

        return this._http.post(this.url, params, { headers: headers }).pipe(map(res => res));

    }

    /* Editar un usuario  */
    editUsuario(usuario): Observable<any> {
        let params = JSON.stringify(usuario);
        let headers = {
            'Content-Type': 'application/json',
            'token': this.getToken()
        };

        return this._http.put(this.url + 'usuarios/' + usuario.cuil, params, { headers: headers }).pipe(map(res => res));

    }



    /* Recupera del localStorage los datos del usuario logueado */
    getIdentity() {
        let identity = JSON.parse(localStorage.getItem('identity'));

        if (identity == 'undefined') {
            this.identity = null;
        } else {
            this.identity = identity;
        }
        return this.identity;
    }

    /* Recupera el token de conexión a la api */
    getToken() {
        let token = localStorage.getItem('token');

        if (token == 'undefined') {
            this.token = null;
        } else {
            this.token = token;
        }

        return this.token;
    }

    getHospital() {

        let hospital = JSON.parse(localStorage.getItem('hospital'));

        if (hospital == 'undefined') {
            this.hospital = null;
        } else {
            this.hospital = hospital;
        }

        return this.hospital
    }

    get isAdmin(){
        var isAdmin = false;
        var roles: any[] = this.getHospital().roles;
        if(roles.length > 0){   
            roles.forEach(item => {

                if(item == 0) isAdmin = true;
            })
        }
        
        return isAdmin;
    }

    get isJefeEnfermeria(){
        var es = false;
        var roles: any[] = this.getHospital().roles;
        if(roles.length > 0){   
            roles.forEach(item => {
                if(item == 1) es = true;
            })
        }
        return es;
    }

    get isJefeClinicaMedica(){
        var es = false;
        var roles: any[] = this.getHospital().roles;
        if(roles.length > 0){   
            roles.forEach(item => {  
                if(item == 2) es = true;
            })
        }
        return es;
    }

    get isJefeLaboratorio(){
        var es = false;
        var roles: any[] = this.getHospital().roles;
        if(roles.length > 0){   
            roles.forEach(item => {
               
                if(item == 3) es = true;
            })
        }
        return es;
    }

    get isEnfermero(){
        var es = false;
        var roles: any[] = this.getHospital().roles;
        if(roles.length > 0){   
            roles.forEach(item => {
                if(item == 4) es = true;
            })
        }
        return es;
    }
    
    get isMedico(){
        var es = false;
        var roles: any[] = this.getHospital().roles;
        if(roles.length > 0){   
            roles.forEach(item => {
               
                if(item == 5) es = true;
            })
        }
        return es;
    }

    get isLaboratorio(){
        var es = false;
        var roles: any[] = this.getHospital().roles;
        if(roles.length > 0){   
            roles.forEach(item => {
               
                if(item == 6) es = true;
            })
        }
        return es;
    }
    get isAdministrativo(){
        var es = false;
        var roles: any[] = this.getHospital().roles;
        if(roles.length > 0){   
            roles.forEach(item => {
                           
                if(item == 7) es = true;
            })
        }
        return es;
    }

}
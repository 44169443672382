import { Component, OnInit } from '@angular/core';
import { SectorService } from 'src/app/services/sector.service';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Sector } from 'src/app/models/sector';
import { CamaService } from 'src/app/services/cama.service';
import { Cama } from 'src/app/models/cama';
import { isNull } from 'lodash';
import { Isla } from 'src/app/models/isla';
import { ActivatedRoute } from '@angular/router';
import { IslaService } from 'src/app/services/isla.service';

@Component({
  selector: 'app-sector',
  templateUrl: './sector.component.html',
  styleUrls: ['./sector.component.css']
})
export class SectorComponent implements OnInit {

  title: string;
  identity: any;
  errorMessage: string = '';
  sectores: Sector[];
  hospital: any;

  totalCamas: number;
  totalCamasLibres: number;


  //Paginator
  page: any;
  itemsPerPage: number;
  totalItems: number;
  previousPage: any;
  verPag: boolean = false;
  
  constructor(
    private _sectorService: SectorService,
    private _usuarioService: UsuarioService,
    private _camaService: CamaService,
    private _activeRoute: ActivatedRoute,
    private _islaService: IslaService
    ) { }

  ngOnInit(): void {
    // this.idIsla = this._activeRoute.snapshot.params.idIsla;
    
    this.title = 'Listado de Sectores';
    this.identity = this._usuarioService.getIdentity();
    this.hospital = this._usuarioService.getHospital();
    this.getSectores();
    this.page = 1;
    this.itemsPerPage = 15;

  }

  getSectores(){
    this._sectorService.getAll().subscribe(
      response => {

        console.log('Sectores', response['result'].data);

        if (response['result'].data) {

          this.sectores = response['result'].data;

          this.totalItems = this.sectores.length;

          if(this.itemsPerPage < this.totalItems){ this.verPag = true}

          this._camaService.getAll().subscribe(
            resp => {
              this.sectores.forEach(async element => {

                this.totalCamas = resp['result'].data.length;
                this.totalCamasLibres = resp['result'].data.filter(item => item.numeroHCPac == '' || isNull(item.numeroHCPac)).length

                var camas: Cama[] = resp['result'].data.filter(item => item.idSector == element.idSector )

                element.cantCamas = camas.length;
                element.cantCamasLibres = camas.filter(item => item.numeroHCPac == '' || isNull(item.numeroHCPac)).length
                
              })
            },error => console.log(error)

          );

        }else{
          this.errorMessage = 'No se obtuvieron los sectores';
          //this._usuarioService.logout()
        }
      }, error => {
        this.errorMessage = 'Error al obtener los sectores';
        console.log(error.message)
        //this._usuarioService.logout();
      }
    )
  }

  confirmDel(sector: Sector){
    let borrar = confirm('Seguro que quiere borrar esta isla?');
    if (borrar) {
      alert(`${sector.idSector} borrada`)
    }
  }


}

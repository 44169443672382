<!-- Todos los logueados pueden listar pacientes -->
<div *ngIf="identity">
  <div class="card">
    <div class="card-header">
      <div class="page-head ">
        <h3>{{ title }}</h3>
      </div>
    </div>
    <div class="card-body">
      <div class="row justify-content-between">
        <div class="col-auto">
          <div class="form-group">
            <a [routerLink]="['/usuario-create']" class="btn btn-primary"
              ><span class="mr-2"><i class="fas fa-plus"></i></span>Nuevo
              Usuario</a
            >
          </div>
        </div>
        <div class="col-auto">
          <div class="form-group">
            <a [routerLink]="['/usuario-sector-create']" class="btn btn-primary"
              ><span class="mr-2"><i class="fas fa-sign-in-alt"></i></span>Asignar a Sector</a
            >
          </div>
        </div>
        
      </div>

      <div class="dropdown-divider"></div>

      <div class="row">
        <!-- <div class="col-12 col-md">
          <div class="form-inline">
            <label for="isla" class="mr-3">Filtrar x Isla</label>
            
              <select name="isla" #isla="ngModel" [(ngModel)]="filtroIsla" class="form-control form-control-sm" (change)="onIslaChange()">
                <option value="" selected>Todas</option>
                <option value="{{i.idIsla}}" *ngFor="let i of islas">{{i.idIsla}}</option>
 
              </select>
      
          </div>
        </div> -->
        <div class="col-12 col-md">
          <div class="form-inline" >
            <label for="sector" class="mr-3">Filtrar x Sector</label>
            
              <select name="sector" #sector="ngModel" [(ngModel)]="filtroSector" [disabled]="!sectorActivo"  class="form-control form-control-sm"  (change)="onSectorChange()">
                <option  value="" selected>Todos</option>
                <option value="{{i.idSector}}" *ngFor="let i of sectores">{{i.idSector}}</option>
              </select>
      
          </div>
        </div>

        <div class="col-12 col-md">
          <div class="input-group mb-2 mr-sm-2">
            <div class="input-group-prepend">
              <div class="input-group-text"><i class="fas fa-search"></i></div>
            </div>
            <input
              type="text"
              class="form-control form-control-sm"
              placement="top" ngbTooltip="Buscar por Cuil, Nombre, Email o Teléfono"
              placeholder="Buscar por Cuil, Nombre, Email o Teléfono"
              name="filtrarUsuario"
              [(ngModel)]="filtrarUsuario"
            />
          </div>
        </div>
      </div>

      <div class="dropdown-divider"></div>
      <div class="d-flex justify-content-end align-items-center">
        <a (click)="editarUsuario()"
          class="btn-paciente-option"
          placement="top" ngbTooltip="Editar Usuario"
          > <i class="far fa-edit"></i></a>
        <a (click)="confirmDel()" class="btn-paciente-option"  placement="top" ngbTooltip="Eliminar Usuario">
          <span class=""> <i class="far fa-trash-alt"></i></span>
        </a>
      </div>


  
      <div class="table-responsive-md">
      <table class="table table-hover table-striped table-bordered table-sm">
        <thead>
          <tr class="bg-primary text-light text-center">
            <th scope="col" (click)="order('cuil')">Cuil</th>
            <th scope="col" (click)="order('apellido')">Nombre</th>
            <th scope="col" (click)="order('email')">Email</th>
            <th scope="col" (click)="order('telefono')">Teléfono</th>
            <th scope="col" (click)="order('sector')">Sector</th>
            <th scope="col" (click)="order('rol')">Roles</th>
            <th scope="col" (click)="order('estadoLaboral')">Estado</th>
          </tr>
        </thead>
        <tbody *ngIf="verTabla; else noValor2">
          <tr
            *ngFor="
              let usuario of usuarios
                | filterUsuario: filtrarUsuario
                | orderBy: orderDirection:orderColumn
                | slice
                  : (page - 1) * itemsPerPage
                  : (page - 1) * itemsPerPage + itemsPerPage
            "
            class="text-center usuario-item"
            (click)="seleccionaUsuario(usuario.cuil)"
            (dblclick)="datos(content, usuario)"
            id="{{'usuario-' + usuario.cuil}}"
          >
            <th scope="row" >{{ usuario.cuil }}</th>
            <td >{{ usuario.apellido }} {{ usuario.nombre }}</td>
            <td >{{ usuario.email }}</td>
            <td >{{ usuario.telefono }}</td>
            <td >
              <span *ngFor="let item of usuario.extra?.sectores">{{item.idSector}}<br></span>
            </td>
            <td>
              <span *ngIf="usuariosHospital; else noValor">{{
                usuario.extra?.rol
              }}</span>
            </td>
            <td>
              <span *ngIf="usuariosHospital; else noValor">{{
                usuario.extra?.estadoLaboral
              }}</span>
            </td>
            
          </tr>
        </tbody>
      </table>
    </div>
      <div class="mt-3" *ngIf="verPaginate">
        <ngb-pagination
          [collectionSize]="totalItems"
          [(page)]="page"
          [pageSize]="itemsPerPage"
          [maxSize]="7"
          [rotate]="true"
          [boundaryLinks]="true"
        >
        </ngb-pagination>
      </div>
    </div>
  </div>

</div>

<ng-template #noValor>
  <div class="text-center">
    <div class="spinner-border spinner-border-sm text-primary" role="status">
      <span class="sr-only">Cargando...</span>
    </div>
  </div>
</ng-template>

<ng-template #noValor2>
  <tr>
    <td colspan="7">
      <div class="text-center">
        <div class="spinner-border spinner-border-sm text-primary" role="status">
          <span class="sr-only">Cargando...</span>
        </div>
      </div>
    </td>
  </tr>
</ng-template>

<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Info de Usuario</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h5>Usuario {{selectedUsuario.nombre}} {{selectedUsuario.apellido}}</h5>
    <p>Datos del usuario seleccionado</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Cerrar</button>
  </div>
</ng-template>

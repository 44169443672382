export class Laboratorio {
    constructor(
        public idHospitalLab: number,
        public numeroHCLab: string,
        public fecha: number,
        public cuil: string,
        public dimeroD: number,
        public linfopenia: number,
        public plaquetas: number,
        public ldh: number,
        public ferritina: number,
        public proteinaC: number,
        public sync_id?: number
    ){}

}

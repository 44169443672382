<!-- Enfermero puede cargar un usuario -->
<div *ngIf="identity">
  <div class="card">
    <div class="card-header">
      <div class="page-head py-3">
        <h3>{{ title }}</h3>
      </div>
    </div>
    <div class="card-body" *ngIf="usuario; else spinner">
      <div class="alert alert-danger" *ngIf="errorMessage">
        {{ errorMessage }}
      </div>
      <form #editUsuarioForm="ngForm" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="cuil">Cuil:</label>
              <input
                type="text"
                class="form-control"
                #cuil="ngModel"
                name="cuil"
                [(ngModel)]="usuario.cuil"
                required
              />
              <span *ngIf="!cuil.valid && cuil.touched" class="text-danger">
                El cuil es obligatorio
              </span>
            </div>
          </div>
          <div class="col">
            <div class="form-group">
              <label for="nombre">Nombre:</label>
              <input
                type="text"
                class="form-control"
                #nombre="ngModel"
                name="nombre"
                [(ngModel)]="usuario.nombre"
                required
              />
              <span *ngIf="!nombre.valid && nombre.touched" class="text-danger">
                El nombre es obligatorio
              </span>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <label for="apellido">Apellido:</label>
              <input
                type="text"
                class="form-control"
                #apellido="ngModel"
                name="apellido"
                [(ngModel)]="usuario.apellido"
                required
              />
              <span
                *ngIf="!apellido.valid && apellido.touched"
                class="text-danger"
              >
                El apellido es obligatorio
              </span>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <div class="form-group">
              <label for="email">Email:</label>
              <input
                type="email"
                class="form-control"
                #email="ngModel"
                name="email"
                [(ngModel)]="usuario.email"
                required
              />
              <span *ngIf="!email.valid && email.touched" class="text-danger">
                El email es obligatorio
              </span>
            </div>
          </div>

          <div class="col" *ngIf="passPropia">
            <div class="form-group">
              <label for="clave">Contraseña:</label>
              <div class="input-group mb-2">
                <input
                  [type]="verPass ? 'text' : 'password'"
                  class="form-control"
                  #clave="ngModel"
                  name="clave"
                  [(ngModel)]="usuario.clave"
                  required
                />
                <div class="input-group-prepend">
                  <button class="" (click)="showPass()">
                    <i class="fas fa-eye"></i>
                  </button>
                </div>
              </div>

              <span *ngIf="!clave.valid && clave.touched" class="text-danger">
                La clave es obligatoria
              </span>
            </div>
          </div>

          <div class="col">
            <div class="form-group">
              <label for="telefono">Teléfono:</label>
              <input
                type="text"
                class="form-control"
                #telefono="ngModel"
                name="telefono"
                [(ngModel)]="usuario.telefono"
                required
              />
              <span
                *ngIf="!telefono.valid && telefono.touched"
                class="text-danger"
              >
                El telefono es obligatorio
              </span>
            </div>
          </div>

          <div class="form-group">
            <label for="idRol">Rol:</label>
            <select class="form-control" #idRol name="idRol" [(ngModel)]="usuarioHospital.idRol">
                <option value="0">Administrador</option>
                <option value="1">Enfermero</option>
                <option value="2">Médico</option>
                <option value="3">Laboratorio</option>
                <option value="4">Jefe UTI</option>
            </select>
        </div>

          <div class="col">
            <div class="form-group">
              <label> Estado Laboral:</label>
              <div class="form-control">
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="estadoLaboral"
  
                      value= 0
                      [(ngModel)]="usuarioHospital.estadoLaboral"
                      
                    />
                    Activo
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="estadoLaboral"
               
                      value= 1
                      [(ngModel)]="usuarioHospital.estadoLaboral"
                    />
                    Licencia
                  </label>
                </div>
                <div class="form-check form-check-inline">
                  <label class="form-check-label">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="estadoLaboral"
                      
                      value= 2
                      [(ngModel)]="usuarioHospital.estadoLaboral"
                    />
                    Baja
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="form-group">
          <input type="submit" class="btn btn-primary" value="Ingresar" />
          <a [routerLink]="['/usuarios']" class="btn btn-secondary ml-3"
            >Cancelar</a
          >
        </div>
      </form>
<!-- {{editUsuarioForm.value  | json}} -->
    </div>
  </div>
</div>

<ng-template #spinner>
  <div class="col-12 text-center py-5">
    <div class="spinner-border text-primary " role="status">
      <span class="sr-only">Cargando...</span>
    </div>
  </div>
</ng-template>

/* Angular */
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

/* Servicios */
import { UsuarioService } from 'src/app/services/usuario.service';
import { EpisodioService } from 'src/app/services/episodio.service';

/* Models */
import { Episodio } from 'src/app/models/episodio';

import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

@Component({
  selector: 'app-edit-episodio',
  templateUrl: './edit-episodio.component.html',
  styleUrls: ['./edit-episodio.component.css'],
  providers: [ UsuarioService, EpisodioService, ToastrService ]
})
export class EditEpisodioComponent implements OnInit {

  title: string;
  errorMessage: string = '';
  episodio: Episodio;
  identity;
  idHospital: number;
  numeroHC: string;
  razones: string[];
  paciente;
  edad;

  constructor(
    private _usuarioService: UsuarioService,
    private _episodioService: EpisodioService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _toastr: ToastrService
  ) { }

  ngOnInit() {
    this.title = ' Registrar Cierre de Episodio';
    this.identity = this._usuarioService.getIdentity();
    this.idHospital = this._activatedRoute.snapshot.params.idHospital;
    this.numeroHC = this._activatedRoute.snapshot.params.numeroHC;
    this.razones = ["Recuperado", "Derivación", "LTC", "UTI", "UVI", "Fallecimiento"]
    this.getEpisodio();
  }
 
  onSubmit(){
    this.episodio.fechaEgreso = moment().unix();
    this._episodioService.update( 'episodio', this.episodio).subscribe(
      response => {
        
        this._toastr.success('Gracias', 'Episodio generado con éxito',{ timeOut: 2500, positionClass: 'toast-bottom-right', });
        this._router.navigate(['/episodios'])

      }, error => {
        this.errorMessage = 'Error al buscar el paciente';
        console.log(error.message)
      }
    )
  }



  getEpisodio(){
    /* this._episodioService.getOne(this.idHospital, this.numeroHC).subscribe(
      response => {
        this.episodio = response['model'];
      }, error => {
        this.errorMessage = 'Error al buscar el episodio';
        console.log(error.message)
      }
    ) */
  }


  getEdad(fecha) {
    var hoy = new Date();
    var cumpleanos = new Date(fecha);
    var edad = hoy.getFullYear() - cumpleanos.getFullYear();
    var m = hoy.getMonth() - cumpleanos.getMonth();

    if (m < 0 || (m === 0 && hoy.getDate() < cumpleanos.getDate())) {
        edad--;
    }

    return edad;
  }

}

<!-- Enfermero puede cargar un paciente -->
<div *ngIf="identity">
    <form #editPacienteForm="ngForm" (ngSubmit)="onSubmit()">
        <div class="card">
            <div class="card-header">
                <div class="page-head">
                    <h3>{{title}}</h3>
                </div>
            </div>
            <div class="card-body">
                <div class="alert alert-danger" *ngIf="errorMessage">
                    {{errorMessage}}
                </div>
                <div class="row">
                    <div class="form-group  col-lg-2 col-md-3 col-sm-6 col-6">
                        <label for="numeroHC">Hist.Clínica:</label>
                        <input type="text" class="form-control" #numeroHC="ngModel" name="numeroHC"
                            [(ngModel)]="paciente.numeroHC" required>
                        <span *ngIf="!numeroHC.valid && numeroHC.touched" class="text-danger">
                            El N° de historia clínica es obligatorio
                        </span>
                    </div>

                    <div class="form-group  col-lg-2 col-md-4 col-sm-6 col-6"><label for="tipoDocumento">Tipo
                            Doc.:</label>
                        <select name="tipoDocumento" #tipoDocumento='ngModel' [(ngModel)]='paciente.tipoDocumento'
                            class="form-control">
                            <option value="DNI">DNI</option>
                            <option value="Cedula">Cédula</option>
                            <option value="LC">Libreta Cívica</option>
                            <option value="Pasaporte">Pasaporte</option>
                        </select>
                    </div>

                    <div class="form-group  col-lg-2 col-md-5 col-sm-6 col-xs-12">
                        <label for="dni">DNI:</label>
                        <input type="text" class="form-control" #paisExp="ngModel" name="dni"
                            [(ngModel)]="paciente.dni" required>
                        <!-- <span *ngIf="!dni.valid && dni.touched" class="text-danger">
                    El dni es obligatorio
                </span> -->
                    </div>

                    <div class="form-group col-lg-3 col-md-6 col-sm-6 col-xs-12">
                        <label for="paisExp">País de Exp.:</label>
                        <input type="text" class="form-control" #paisExp="ngModel" name="paisExp"
                            [(ngModel)]="paciente.paisExp" required>
                        <!-- <span *ngIf="!paisExp.valid && paisExp.touched" class="text-danger">
                    El nombre es obligatorio
                </span> -->
                    </div>

                    <div class="form-group col-lg-3 col-md-6 offset-md-0 col-sm-6 offset-sm-3 col-xs-12">
                        <label for="fechaNac">F. Nacimiento:</label>
                        <input type="date" class="form-control" #fechaNac="ngModel" name="fechaNac"
                            [(ngModel)]="paciente.fechaNac" required>
                        <span *ngIf="!fechaNac.valid && fechaNac.touched" class="text-danger">
                            La fecha de nacimiento es obligatoria
                        </span>
                    </div>
                </div>

                
                <div class="row">
                    <div class="form-group col-lg-3 col-md-6 col-12">
                        <label for="nombre">Nombres:</label>
                        <input type="text" class="form-control" #nombre="ngModel" name="nombre"
                            [(ngModel)]="paciente.nombre" required>
                        <span *ngIf="!nombre.valid && nombre.touched" class="text-danger">
                            El nombre es obligatorio
                        </span>
                    </div>
                    <div class="form-group col-lg-3 col-md-6 col-12">
                        <label for="apellido">Apellidos:</label>
                        <input type="text" class="form-control" #apellido="ngModel" name="apellido"
                            [(ngModel)]="paciente.apellido" required>
                        <span *ngIf="!apellido.valid && apellido.touched" class="text-danger">
                            El apellido es obligatorio
                        </span>
                    </div>

                    <div class="form-group col-lg-3 col-6">
                        <label for="nacionalidad">Nacionalidad:</label>
                        <input type="text" class="form-control" #nacionalidad="ngModel" name="nacionalidad"
                            [(ngModel)]="paciente.nacionalidad" required>
                        <span *ngIf="!nacionalidad.valid && nacionalidad.touched" class="text-danger">
                            El nacionalidad es obligatorio
                        </span>
                    </div>

                    <div class="form-group col-lg-3 col-6">
                        <label for="genero">Sexo Biológico:</label>
                        <select name="genero" #genero='ngModel' [(ngModel)]='paciente.genero'
                            class="form-control">
                            <option value="Femenino">Femenino</option>
                            <option value="Masculino">Masculino</option>
                        </select>
                    </div>
                </div>
                
                <div class="row">
                <div class="form-group col-12 col-sm-9 col-lg-6">
                    <label for="calle">Calle:</label>
                    <input type="text" class="form-control" #calle="ngModel" name="calle" [(ngModel)]="paciente.calle"
                        required>
                    <span *ngIf="!calle.valid && calle.touched" class="text-danger">
                        El calle es obligatorio
                    </span>
                </div>

                <div class="form-group col-4 col-sm-3 col-lg-2">
                    <label for="numero">Número:</label>
                    <input type="text" class="form-control" #numero="ngModel" name="numero"
                        [(ngModel)]="paciente.numero" required>
                    <span *ngIf="!numero.valid && numero.touched" class="text-danger">
                        El numero es obligatorio
                    </span>
                </div>

                <div class="form-group col-4 offset-sm-2 col-lg-2 offset-lg-0">
                    <label for="piso">Piso y Dpto:</label>
                    <input type="text" class="form-control" #piso="ngModel" name="piso"
                        [(ngModel)]="paciente.piso" required>

                </div>

                <div class="form-group col-4 col-lg-2">
                    <label for="CP">Cod.postal:</label>
                    <input type="text" class="form-control" #CP="ngModel" name="CP" [(ngModel)]="paciente.CP" required>

                </div>
            </div>

            <div class="row">
                <div class="form-group col-12 col-sm-6 col-lg-4">
                    <label for="telefono">Teléfono:</label>
                    <input type="tel" class="form-control" #telefono="ngModel" name="telefono"
                        [(ngModel)]="paciente.telefono" required>
                    <span *ngIf="!telefono.valid && telefono.touched" class="text-danger">
                        El teléfono es obligatorio
                    </span>
                </div>

                <div class="form-group col-12 col-sm-6 col-lg-4">
                    <label for="telefonoFamiliar1">Teléfono Familiar 1:</label>
                    <input type="tel" class="form-control" #telefonoFamiliar1="ngModel" name="telefonoFamiliar1"
                        [(ngModel)]="paciente.telefonoFamiliar1" required>

                </div>

                <div class="form-group col-12 col-sm-6 offset-sm-3 col-lg-4 offset-lg-0">
                    <label for="telefonoFamiliar2">Teléfono Familiar 2:</label>
                    <input type="tel" class="form-control" #telefonoFamiliar2="ngModel" name="telefonoFamiliar2"
                        [(ngModel)]="paciente.telefonoFamiliar2" required>

                </div>
            </div>
                

            </div>
            <div class="card-footer">
                <div class="form-group text-right">
                    <input type="submit" class="btn btn-primary" value="Guardar">
                    <a [routerLink]="[ '/pacientes' ]" class="btn btn-secondary ml-3">Cancelar</a>
                </div>
            </div>
        </div>
    </form>
    
</div>
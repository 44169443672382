import { Component, OnInit } from '@angular/core';

import { Router, ActivatedRoute } from '@angular/router';
import { UsuarioService } from 'src/app/services/usuario.service';
import { ABMBaseService } from 'src/app/services/ABMBase.service';



@Component({
  selector: 'app-edit-paciente',
  templateUrl: './edit-paciente.component.html',
  styleUrls: ['./edit-paciente.component.css'],
  providers: [ UsuarioService, ABMBaseService ]
})
export class EditPacienteComponent implements OnInit {

  title;
  errorMessage;
  paciente;
  identity;
  hospital;
  numeroHC;

  constructor(
    private _usuarioService: UsuarioService,
    private _abmSvc: ABMBaseService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
  ) { }

  ngOnInit(): void {
    this.title = 'Editar Paciente';
    this.numeroHC = this._activatedRoute.snapshot.paramMap.get('numeroHC');
    this.identity = this._usuarioService.getIdentity();
    this.hospital = this._usuarioService.getHospital();
    //this.getPaciente();
    this.getPacientes();
   
  }

  
  pacientes;
  getPacientes(): void {
    this._abmSvc.getAll('hcpacientes').subscribe(
      resp => {

        this.pacientes = resp['result'].data;
        this.paciente = this.pacientes.filter(item => item.numeroHC == this.numeroHC)[0];
        console.log('Paciente==>' , this.paciente);
        

      },
      error => {

        console.log(error);
        
      }
    )
  }
  
  onSubmit(): void {
    this._abmSvc.update('hcpaciente', this.paciente).subscribe(
      response => {
        this._router.navigate(['/pacientes']);
      }, error => {
        this.errorMessage = 'Error al actualizar el paciente';
        console.log(error);
      }
    )
  }

}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { map } from 'rxjs/operators';

import { GLOBAL } from './global';

@Injectable({
  providedIn: 'root'
})
export class SectorService {

  url: string;
  headers;
  token;

  constructor(
    private _http: HttpClient
  ) {
    this.url = GLOBAL.url;
    this.token = localStorage.getItem('token');
    this.headers = {
      'content-type': 'application/json'
    };
  }

  getAll() {

    var data = {
      "version": "0.0",
      "id": Math.floor((Math.random() * 10000) + 1),
      "params": {
        "sync_id": 0
      },
      "method": "get_sectores",
      "token": this.token
    }
    return this._http.post(this.url, data, { headers: this.headers }).pipe(map(res => res));
  }

  create(model) {

    //var camas = model.cama_hasta - model.cama_desde + 1;

    var data = {
      "version": "0.0",
      "method": "new_sector",
      "params": {
        "data": {
          "idHospital": model.idHospital,
          "idIsla": model.idIsla,
          "idSector": model.idSector,
          "descripcion": model.descripcion,
          "camaDesde": model.camaDesde,
          "camaHasta": model.camaHasta
        }
      },
      "id": Math.floor((Math.random() * 10000) + 1),
      "token": this.token
    }

    return this._http.post(this.url, data, { headers: this.headers }).pipe(map(res => res));
  }
  /* 
    update(model, idHospital: number, idIsla: string, idSector: string) {
      let params = JSON.stringify(model);
      console.log(params);
      return this._http.put(this.url + 'sector/' + idHospital + '/' + idIsla + '/' + idSector, params, { headers: this.headers }).pipe(map(res => res));
    }
  
    destroy(idHospital: number, idIsla: string, idSector: string) {
      return this._http.delete(this.url + 'sector/' + idHospital + '/' + idIsla + '/' + idSector, { headers: this.headers }).pipe(map(res => res));
    } */
}

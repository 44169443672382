import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ToastrService } from 'ngx-toastr';

import { Isla } from 'src/app/models/isla'
import { UsuarioService } from 'src/app/services/usuario.service';
import { ABMBaseService } from 'src/app/services/ABMBase.service';

@Component({
  selector: 'app-create-isla',
  templateUrl: './create-isla.component.html',
  styleUrls: ['./create-isla.component.css']
})
export class CreateIslaComponent implements OnInit {

  title;
  identity;
  hospital: any;
  errorMessage = '';
  isla: Isla;

  constructor(
    private _usuarioService: UsuarioService,
    private _router: Router,
    private _toastr: ToastrService,
    private _abmBaseSvc: ABMBaseService
  ) { }

  ngOnInit(): void {
    this.title = 'Carga de isla';
    this.hospital = this._usuarioService.getHospital();
    this.identity = this._usuarioService.getIdentity();
    this.isla = new Isla(this.hospital.idHospital,'',null,null)
  }

  onSubmit(){
    
    if(this.isla.idHospital == null || this.isla.idIsla == ''/*  || this.isla.idLider == null */){
      this.errorMessage = 'Todos los datos son necesarios';
    }else{
      this.errorMessage = '';
      this._abmBaseSvc.getAll('islas').subscribe(resp => {
        var islas: Isla[] = resp['result'].data;
        
        if (islas.length > 0) {

          var i = islas.filter(item => item.idIsla == this.isla.idIsla && item.idHospital == this.isla.idHospital);

          if(i.length > 0 ){
            this._toastr.error('Ya hay una Isla con ese nombre en este Hospital. Por favor, elija otro nombre')
          }else{
            this.storeIsla();
          }
          
        } else {
          this.storeIsla();
        }
        
      }, error => {
        console.log(error);
      });

    }
  }
  
  storeIsla(){

      this._abmBaseSvc.create('isla',this.isla).subscribe(
        response => {
          this._router.navigate(['islas']);
        }, error => {
          this.errorMessage = 'Error al intentar guardar datos de la isla'
          console.log(error)
        }
      )
  }

}

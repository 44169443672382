import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { Localidad, Paciente, Provincia } from '../../../models/paciente';
import { UsuarioService } from '../../../services/usuario.service';
import { ABMBaseService } from 'src/app/services/ABMBase.service';
import { LocalidadService } from 'src/app/services/localidad.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';

import { Hospital } from 'src/app/models/hospital';
@Component({
  selector: 'app-create-paciente',
  templateUrl: './create-paciente.component.html',
  styleUrls: ['./create-paciente.component.css'],
  providers: [ ABMBaseService, LocalidadService, UsuarioService ]
})
export class CreatePacienteComponent implements OnInit {

  public paciente: Paciente;
  public title: string;
  public identity: any;
  public token: string;
  public errorMessage: string;
  public hospital: Hospital;
  public localidades: Localidad[];
  public localidadesPorProvincia: Localidad[] = [];
  public provincias: Provincia[];
  public numeroHC: string;

  constructor(
    private _usuarioService: UsuarioService,
    private _localidadSvc: LocalidadService,
    private _abmSvc: ABMBaseService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _toastr: ToastrService
  ) { }

  ngOnInit(): void {

    this.numeroHC = this._activatedRoute.snapshot.paramMap.get('numeroHC');

    this.identity = this._usuarioService.getIdentity();
    this.token = this._usuarioService.getToken();
    this.hospital = this._usuarioService.getHospital();

    if(this.numeroHC){
      this.title = 'Editar paciente';
      this._abmSvc.getAll('hcpacientes').subscribe(
        resp => {
          let p = resp['result'].data.filter(item => item.numeroHC == this.numeroHC)[0];
          this.paciente = new Paciente(this.hospital.idHospital, p.numeroHC, p.tipoDocumento,p.paisExp,p.dni,p.nombre,p.apellido,p.nacionalidad,p.genero,p.calle,p.numero,p.piso,p.id_loc,p.id_provincia,p.CP,p.telefono,p.telefonoFamiliar,p.telefonoFamiliar2,p.fechaNac,p.gravedadAlerta,p.nivelConfianza,p.auditoriaComorbilidades,null,null,null,null,null,null)
        }, error => console.log(error)
      )
    }else{
      this.title = 'Cargar nuevo paciente';
      this.paciente = new Paciente(this.hospital.idHospital,'','DNI','Argentina',null,'','','Argentina','','',null,'', null, null,'','','','',null,null,null,this.identity.cuil,null,null,null,null,null,null)
    }
    this.getProvincias();
  }

  getProvincias():void {
    this._abmSvc.getAll('provincias').subscribe(
      resp => {
        this.provincias = resp.result.provincias;
      }
    )
  }

  onProvinciaChange(id_provincia): void {
    let id = parseInt(id_provincia);
    this._localidadSvc.getLocalidades(id).subscribe(
      resp => {
        this.localidadesPorProvincia = resp['result'].localidades;
      }, error => {
        console.log(error);
      }
    )

  }

  /* Guarda el paciente cargado */
  onSubmit(unPaciente){
    unPaciente.idHospital = this.hospital.idHospital;
    unPaciente.auditoria = this.identity.cuil;

    let fecha = moment(unPaciente.fechaNac).unix();
    unPaciente.fechaNac = fecha * 1000;

    console.log('------ paciente en onsubmit -----------')
    console.log(unPaciente);

    if(this.numeroHC){
      this._abmSvc.update('hcpaciente', unPaciente).subscribe(
        response => {
          console.log('respuesta de edicion de paciente=>', response);

          if(response['status'].includes('200')){
            this._toastr.success('Paciente modificado correctamente');
            this._router.navigate(['/pacientes']);
          } else {
            this._toastr.error('El paciente no pudo ser modificado en este momento');
          }
        },error => {
          this._toastr.error('El paciente no pudo ser modificado en este momento');
          this.errorMessage = <any>error.error.message;
          if (this.errorMessage != null) {
            console.log(error.error);
          }
        }
      )
    }else{
      this._abmSvc.create('hcpaciente', unPaciente).subscribe(
        response => {
          this._toastr.success('Paciente creado correctamente');
          this._router.navigate(['/pacientes']);
        },error => {
          this._toastr.error('El paciente no pudo ser creado en este momento');
          this.errorMessage = <any>error.error.message;
          if (this.errorMessage != null) {
            console.log(error.error);
          }
        }
      )
    }
  }
}

import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router'

/* Models */
import { Paciente } from 'src/app/models/paciente';
import { Laboratorio } from 'src/app/models/laboratorio';


/* Services */
import { UsuarioService } from 'src/app/services/usuario.service';
import { LaboratorioService } from 'src/app/services/laboratorio.service';
import { ABMBaseService } from 'src/app/services/ABMBase.service';
import { ToastrService } from 'ngx-toastr';
import { AlertaService } from 'src/app/services/alerta.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Alerta } from 'src/app/models/alerta';

import * as moment from 'moment'

@Component({
  selector: 'app-laboratorio',
  templateUrl: './laboratorio.component.html',
  styleUrls: ['./laboratorio.component.css'],
  encapsulation: ViewEncapsulation.None,
  providers: [ABMBaseService, UsuarioService, ToastrService, LaboratorioService, AlertaService]
})
export class LaboratorioComponent implements OnInit {

  title: string;
  errorMessage: string = '';
  paciente: Paciente;
  pacientes: Paciente[];
  laboratorio: Laboratorio;
  filtrarPaciente = '';
  identity;
  hospital;
  numeroHCLab;

  constructor(
    private _usuarioService: UsuarioService,
    private _abmSvc: ABMBaseService,
    private _laboratorioService: LaboratorioService,
    private _router: Router,
    private _toastr: ToastrService,
    private _alertaService: AlertaService,
    private _modal: NgbModal,
    private _activeRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.numeroHCLab = this._activeRoute.snapshot.params.numeroHC;
    this.title = 'Carga Laboratorio';
    this.identity = this._usuarioService.getIdentity();
    this.hospital = this._usuarioService.getHospital();
    this.getPacientes();
    this.laboratorio = new Laboratorio(null, '', null, '', null, null, null, null, null, null)
  }

  /**
   * Chequea que tenga datos cargados y sin no estan todos, solicita confirmación de carga.
   * @param modalContent string(Contenido HTML)
   */
  onSubmit(modalContent) {

    var l = this.laboratorio;
    if (l.dimeroD == null && l.linfopenia == null && l.plaquetas == null && l.ldh == null && l.ferritina == null && l.proteinaC == null) {
      this.errorMessage = 'Al menos 1 (un) campo debe contener un valor válido';
    } else {

      if (l.dimeroD == null || l.linfopenia == null || l.plaquetas == null || l.ldh == null || l.ferritina == null || l.proteinaC == null) {
        this._modal.open(modalContent, { backdropClass: 'light-blue-backdrop' }).result.then(
          result => {
            if (result) {
              this.dataStore();
            } else {
              this.errorMessage = ""
            }

          }, reason => {

          });
      } else {
        this.dataStore();
      }
    }

  }

  /**
   * Guarda los datos de laboratorio en la base de datos
   * */
  private dataStore() {

    this.laboratorio.idHospitalLab = this.paciente.idHospital;
    this.laboratorio.numeroHCLab = this.paciente.numeroHC;
    this.laboratorio.fecha = moment().unix();
    this.laboratorio.cuil = this.identity.cuil;
    this.laboratorio.sync_id = null;

    console.log('Laboratorio a guardar=>', this.laboratorio);

    this._abmSvc.create('laboratorio',this.laboratorio).subscribe(
      response => {
        console.log('Respuesta de Laboratorio', response);

        if(response.status.includes("200")){

          this._toastr.success('Datos guardados con éxito')
          //this.paciente = null;
          this.laboratorio = new Laboratorio(null, '', null, '', null, null, null, null, null, null);
          this._alertaService.pedirAlertas(true);
          //this._router.navigate(['pacientes'])
        }

      },
      error => {
        this._toastr.error('Si el error continua, avisar al administrador del sistema', 'Error al guardar los datos')
        if (this.errorMessage != null) {
          console.log(error);
        }
      }

    )
  }

  /**
   * Chequea que el valor ingresado se encuentre dentro de los parametros aceptados para cada caso
   * @param e any
   */
  validar(e) {


    this.errorMessage = '';
    let ev = e.target;
    switch (ev.name) {
      case "dimeroD":
        if (ev.value < 0) {
          this.errorMessage = "Dimero D debe ser mayor o igual a cero"
          this.laboratorio.dimeroD = null;
        } else { this.errorMessage = '' }
        break;

      case "plaquetas":
        if (ev.value < 0 || ev.value > 1000000) {
          this.errorMessage = "Las plaquetas deben estar entre 0 y 1.000.000"
          this.laboratorio.plaquetas = null;
        } else { this.errorMessage = '' }
        break;

      case "ldh":
        if (ev.value < 0) {
          this.errorMessage = "LDH debe ser mayor o igual a cero"
          this.laboratorio.ldh = null;
        } else { this.errorMessage = '' }
        break;

      case "ferritina":
        if (ev.value < 0) {
          this.errorMessage = "Ferritina debe ser mayor o igual a cero"
          this.laboratorio.ferritina = null;
        } else { this.errorMessage = '' }
        break;

      case "proteinaC":
        if (ev.value < 0) {
          this.errorMessage = "Proteina C Reactiva debe mayor o igual a cero"
          this.laboratorio.proteinaC = null;
        } else { this.errorMessage = '' }
        break;
    }
  }

  /**
   * Toma el paciente seleccionado y asigna el numero de historia clínica al nuevo laboratorioa a cargar
   * @param valor objeto<Paciente>
   */
  selPaciente(valor) {
    this.paciente = valor;
    this.errorMessage = '';
    //this.edad = this._abmSvc.getEdad(this.paciente.fechaNac)
    this.laboratorio.numeroHCLab = this.paciente.numeroHC
  }

  /**
   * Cancela la carga que esta siendo realizada
   */
  cancelCarga() {
    if(this.numeroHCLab) this._router.navigate(['/pacientes']);
    this.paciente = null;
  }

  /**
   * Busca todos los pacientes que estan cargados en la base de datos
   */
  getPacientes() {
    this._abmSvc.getAll('hcpacientes').subscribe(
      response => {

        var datos = response['result'].data;

        if (datos) {
          this.pacientes = datos;
          this.paciente = this.pacientes.filter(item => item.numeroHC == this.numeroHCLab)[0];
          this.laboratorio.numeroHCLab = this.paciente.numeroHC
        } else {
          //this._usuarioService.logout()
        }
      }, error => {
        this.errorMessage = 'Error al obtener los pacientes';
        console.log(error.message)
        //this._usuarioService.logout();
      }
    )
  }


}

<div class="container" *ngIf="identity">
    <div class="card">
        <div class="card-header">
            <h3>{{title}}</h3>
        </div>
        <div class="card-body">
            
            <div *ngIf="!paciente">
                <div class="form-inline">
                    <div class="input-group mb-2 mr-sm-2">
                        <label for="filtrarPaciente" class="mr-2">Buscar Paciente</label>
                        <input type="text" class="form-control" placeholder="Buscar..." name="filtrarPaciente"
                            id="filtrarPaciente" [(ngModel)]="filtrarPaciente">

                        <div class="input-group-append">
                            <div class="input-group-text"><i class="fas fa-search"></i></div>
                        </div>
                    </div>
                </div>
                <div class="paciente-list-container">
                    <ul class="list-group">
                        <a (click)="selPaciente(paciente)" class="paciente-list"
                            *ngFor="let paciente of pacientes | filterPaciente:filtrarPaciente ">
                            <li class="list-group-item paciente-list">
                                {{paciente.apellido}} {{paciente.nombre}} - {{paciente.dni}}
                            </li>
                        </a>
                    </ul>
                </div>
            </div><!-- /Seleccion paciente -->
            <div *ngIf="paciente">
                <div class="table table-bordered">
                    <!-- Datos paciente -->

                    <div class="card">
                        <div class="card-body">
                            <div class="row">
                                <div class="col">
                                   <!--  <div class="btn btn-danger w-100  mb-2" *ngIf="internado">PACIENTE INTERNADO</div> -->
                                    <p><strong>Apellido y Nombre: </strong>{{paciente.apellido}} {{paciente.nombre}}
                                    </p>
                                    <p><strong>Historia Clínica: </strong>{{paciente.numeroHC}}</p>
                                    <p><strong>Dirección: </strong>{{paciente.calle}} Nro: {{paciente.numero}}</p>
                                    <p *ngIf="paciente.piso != ''"><strong>Piso y departamento:</strong>
                                        {{paciente.piso}},</p>
                                    <p><strong>CP:</strong> {{paciente.CP}}</p>

                                </div>
                                <div class="col border-left">
                                    <p><strong>Fecha de nacimiento: </strong>{{paciente.fechaNac | date:'dd-MM-yyyy'}}</p>
                                    <p><strong>Nacionalidad: </strong>{{paciente.nacionalidad}}</p>
                                    <p><strong>Sexo Biológico: </strong>{{paciente.genero}}</p>
                                </div>
                                <div class="col border-left">
                                    <p><strong>Edad: </strong>{{edad}}</p>

                                    <p><strong>Teléfono:</strong> {{paciente.telefono}}</p>
                                    <p *ngIf="paciente.telefonoFamiliar != ''"><strong>Teléfono familiar 1:</strong>
                                        {{paciente.telefonoFamiliar}}</p>
                                    <p *ngIf="paciente.telefonoFamiliar2 != ''"><strong>Teléfono familiar 2:</strong>
                                        {{paciente.telefonoFamiliar2}}</p>

                                </div>
                            </div><!-- /Datos paciente -->
                        </div>
                    </div>
                    <form #createControlForm="ngForm" (ngSubmit)="onSubmit(modalContent)">
                        <div class="card">
                            <div class="card-body">
                            	<div class="alert alert-danger" *ngIf="errorMessage">
					                {{errorMessage}}
					            </div>
					            
                            	<div class="row">
	                                <div class="form-group col-6 col-sm-3">
	                                	<label for="frec_resp">Frecuencia Respiratoria</label>
		                            	<input type="number" name="frec_resp"
		                            		#frec_resp="ngModel"
		                            		[(ngModel)]="controlEnfermeria.frec_resp"
		                            		class="form-control"
		                            		min=0
		                            		max=60
		                            		(change)="validar($event)" 
		                            	>
		                            </div>

		                            <div class="form-group col-6 col-sm-3">
	                                	<label for="sat_oxi">Saturación Oxígeno</label>
		                            	<input type="number" name="sat_oxi"
		                            		#sat_oxi="ngModel"
		                            		[(ngModel)]="controlEnfermeria.sat_oxi"
		                            		class="form-control"
		                            		min=0 
		                            		max=100
		                            		(change)="validar($event)"
		                            	/>
		                            </div>

		                            <div class="form-group col-6 col-sm-3">
		                            	<label for="presSist">Presión Sistólica</label>
		                            	<input type="number" name="presSist"
		                            		#presSist="ngModel"
		                            		[(ngModel)]="controlEnfermeria.presSist"
		                            		class="form-control"
		                            		min=0 
		                            		max=250
		                            		(change)="validar($event)"
		                            	>
		                            	
		                            </div>

		                            <div class="form-group col-6 col-sm-3">
		                            	<label for="presion_sist">Presión Diastólica</label>
		                            	<input type="number" name="presDiast"
		                            		#presDiast="ngModel"
		                            		[(ngModel)]="controlEnfermeria.presDiast"
		                            		class="form-control"
		                            		min=0 
		                            		max=250
		                            		(change)="validar($event)"
		                            	>
		                            	
		                            </div>
		                        

		                            <div class="form-group col-6 col-sm-3">
		                            	<label for="frec_cardiaca">Frecuencia cardíaca</label>
		                            	<input type="number" name="frec_card"
		                            		#frec_card="ngModel"
		                            		[(ngModel)]="controlEnfermeria.frec_card"
		                            		class="form-control"
		                            		min=0 
		                            		max=200
		                            		(change)="validar($event)"
		                            	>
		                            </div>

		                            <div class="form-group col-6 col-sm-3">
		                            	<label for="temperatura">Temperatura (°C)</label>
		                            	<input type="number" name="temp"
		                            		#temp="ngModel"
		                            		[(ngModel)]="controlEnfermeria.temp"
		                            		class="form-control"
		                            		min=34
		                            		max=42
		                            		(change)="validar($event)"
		                            	>
		                            </div>

		                            <div class="form-group col-6 col-sm-3">
										<label for="oxigenoSuplementario">Oxígeno Sup.</label>
										<div class="d-flex justify-content-around align-items-center border rounded py-2">
											<div class="form-check ">
												<input class="form-check-input" type="radio"
													name="oxigenoSuplementario" [(ngModel)]="controlEnfermeria.oxigenoSuplementario" id="oxigenoSuplementario-1"
													value="Si" (click)="onOxigenoSupSi();">
												<label class="form-check-label" for="oxigenoSuplementario-1">Si</label>
											</div>											
											<div class="form-check ">
												<input class="form-check-input" type="radio"
													name="oxigenoSuplementario" [(ngModel)]="controlEnfermeria.oxigenoSuplementario" id="oxigenoSuplementario-2"
													value="No" (click)="onOxigenoSupNo();">
												<label class="form-check-label" for="oxigenoSuplementario-2">No</label>
											</div>
											
										</div>

									
									
		                            	
		                            </div>
									
		                            <div class="form-group col-6 col-sm-3">
		                            		<label for="disnea">Disnea</label>
											<div class="d-flex justify-content-around align-items-center border rounded py-2">
												<div class="form-check ">
													<input class="form-check-input" type="radio"
														name="disnea" [(ngModel)]="controlEnfermeria.disnea" id="disnea-1"
														value="Si">
													<label class="form-check-label" for="disnea-1">Si</label>
												</div>											
												<div class="form-check ">
													<input class="form-check-input" type="radio"
														name="disnea" [(ngModel)]="controlEnfermeria.disnea" id="disnea-2"
														value="No">
													<label class="form-check-label" for="disnea-2">No</label>
												</div>
											</div>
									
									
		                            	
		                            </div>
									<div class="form-group col-6">

										
										<div>		                            
											<label class="ml-3">Nivel de conciencia</label>
										</div>
										<div id="nivelConciencia" class="d-flex justify-content-around align-items-center border rounded py-2">
	
											<div>
	
												<div class="form-check form-check-inline ml-3">
													<input type="radio" name="nivelConciencia"
														#nivelConciencia1="ngModel"
														[(ngModel)]="controlEnfermeria.nivelConciencia"
														class="form-check-input"
														value="Vigil" 
													>
													<label for="nivelConciencia1">Vigil</label>
												</div>
	
												<div class="form-check form-check-inline ml-3">
													<input type="radio" name="nivelConciencia"
														#nivelConciencia2="ngModel"
														[(ngModel)]="controlEnfermeria.nivelConciencia"
														class="form-check-input"
														value="Somnolencia" 
													>
													<label for="nivelConciencia2">Somnolencia</label>
												</div>
	
												<div class="form-check form-check-inline ml-3">
													<input type="radio" name="nivelConciencia"
														#nivelConciencia3="ngModel"
														[(ngModel)]="controlEnfermeria.nivelConciencia"
														class="form-check-input"
														value="Coma" 
													>	    
													<label for="nivelConciencia3">Coma</label>                        	
												</div>
	
											</div>
										</div>
									</div>


									<div class="form-group col-6" *ngIf="verFraccion">
										<label for="temperatura">Fracci&oacute;n inspirada de Ox&iacute;geno (%)</label>
										<input type="number" name="fraccionInsOxigeno"
											#fraccionInsOxigeno="ngModel"
											[(ngModel)]="controlEnfermeria.fraccionInsOxigeno"
											class="form-control col-4"
											min=0
											max=100
										
										>
									</div>

	                            </div>



                            </div>
                            <div class="card-footer">
                                <div class="form-group">
                                    <input type="submit" class="btn btn-primary" value="Ingresar">
                                    <button (click)="cancelCarga()" class="btn btn-secondary ml-3">Cancelar</button>
                                </div>
                                <p class="text-secondary"><small>Los datos identificados con <span
                                            class="text-danger">*</span> son obligatorios</small></p>
                            </div>
                        </div><!-- /Card -->
                    </form>

                </div>

            </div><!-- /Datos paciente -->


        </div><!-- /Card-body -->
    </div><!-- /Card -->

{{controlEnfermeria | json}}
</div>

<ng-template #modalContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title">Confirmación de carga</h4>
    <!--<button type="button" class="close" aria-label="Close" (click)="modal.dismiss(false)">
      <span aria-hidden="true">&times;</span>
    </button>-->
  </div>
  <div class="modal-body">
    <p>Alguno de los datos que se guardarán son nulos.</p>
    <p>¿Estás seguro que quiere avanzar?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="modal.close(true)">Aceptar</button>
    <button type="button" class="btn btn-light" (click)="modal.close(false)">Cancelar</button>
  
  </div>
</ng-template>
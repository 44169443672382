import { Component, OnInit } from '@angular/core';

import { UsuarioService } from 'src/app/services/usuario.service';
import { UsuarioSectorService } from 'src/app/services/usuario-sector.service';
import { IslaService } from 'src/app/services/isla.service';
import { SectorService } from 'src/app/services/sector.service';

import { UsuarioSector } from 'src/app/models/usuario-sector';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Hospital } from 'src/app/models/hospital';
import { Usuario } from 'src/app/models/usuario';
import { Sector } from 'src/app/models/sector';
import { ABMBaseService } from 'src/app/services/ABMBase.service';

@Component({
  selector: 'app-create-usuario-sector',
  templateUrl: './create-usuario-sector.component.html',
  styleUrls: ['./create-usuario-sector.component.css'],
  providers: [ UsuarioSectorService, UsuarioService, SectorService, IslaService, ToastrService ]
})
export class CreateUsuarioSectorComponent implements OnInit {

  title:string;
  hospital: Hospital;
  errorMessage: string;
  identity: Usuario;
  usuarioSector: UsuarioSector;

  sectores: Sector[];

  usuarios: Usuario[];
  usuarioSectores: UsuarioSector[];
  showAceptar: boolean = false;

  constructor(
    private _usuarioSectorService: UsuarioSectorService,
    private _usuarioService:UsuarioService,
    private _abmBaseSvc: ABMBaseService, 
    private _router: Router,
    private _toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.title = ' Carga de Usuario-sector'
    this.identity = this._usuarioService.getIdentity();
    this.hospital = this._usuarioService.getHospital();
    this.usuarioSector = new UsuarioSector(this.hospital.idHospital,'','','',1);
    this.getSectores();
    
    console.log('hospital===>', this.hospital);
    
  }


  onSubmit(){

    console.log(this.usuarioSector);
    
    this._usuarioSectorService.create(this.usuarioSector).subscribe(
      response => {
        this._router.navigate(['/usuarios']);
      }, error => {
        this.errorMessage = 'Error al guardar el usuario-sector'
        console.log(error)
      }
    )

  }

  getUsuarioSectores(){
    this._abmBaseSvc.getAll('usuarios_sector').subscribe(
      response => {   
        console.log('respuesta de usuario sectores', response);
             
        if (response['result'].data) {
          this.usuarioSectores = response['result'].data;
        }else{
          //this._usuarioService.logout()
        }
      }, error => {
        this.errorMessage = 'Error al obtener los usuarios por sector';
        console.log(error.message)
        //this._usuarioService.logout();
      }
    )
  }

 
  /** Busca todos los sectores de la base de datos */
  getSectores(): void {
    this._abmBaseSvc.getAll('sectores').subscribe(
      response => {
        
        console.log('respuesta de sectores', response);
 
        this.sectores = response['result'].data;
        this.getUsuarios();
        
      }, error => {
        this.errorMessage = 'Error al obtener los sectores'
        console.log(error)
      }
    )
  }

  /** Busca todos los usuarios de la base de datos */
  getUsuarios(): void {
    this._abmBaseSvc.getAll('usuarios').subscribe(
      response => {
        console.log('Respuesta de usuarios', response);
        
        this.usuarios = response['result'].sync_id;
        this.getUsuarioSectores();
      }, error => {
        this.errorMessage = 'Error al obtener los usuarios'
        console.log(error)
      }
    )
  }



  availableUsuarios: any[] = [];

  onSectorSelectChange(idSector){
    this.availableUsuarios = []
    var us = this.usuarioSectores.filter(item => item.idHospital == this.hospital.idHospital && item.idSector == idSector);
    
    if(us.length > 0){

      us.forEach(usuario => {
        let u = this.usuarios.filter(item => item.cuil != usuario.cuil);
        this.availableUsuarios.push(...u);
      });
    } else {
      this.availableUsuarios = this.usuarios
    }
    console.log(this.availableUsuarios)
    
  }

  
  onUsuarioSelectChange(usuario){
    console.log(usuario);
    this.showAceptar = true;

    this.usuarioSectores.forEach(usSec => {
      if(this.usuarioSector.idHospital == usSec.idHospital  && this.usuarioSector.idSector == usSec.idSector && usuario == usSec.cuil){
        this._toastr.error('El usuario seleccionado ya se encuentra registrado en este Sector. Por favor, elija otro usuario o cancele la operación')
        this.showAceptar = false

      }
    });

  }
}

<!-- Todos los logueados pueden listar pacientes -->
<div *ngIf="identity">
    <div class="card">
        <div class="card-header">
            <div class="page-head">
                <h3>{{title}}</h3>

            </div>
        </div>
        <div class="card-body">
            <div class="alert alert-danger" *ngIf="errorMessage">
                {{errorMessage}}
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <a [routerLink]="[ '/isla-create' ]" class="btn btn-primary"><span class="mr-2"><i
                                    class="fas fa-plus"></i></span>Nueva Isla</a>
                    </div>
                </div>
                <!-- <div class="col">
            <div class="input-group mb-2 mr-sm-2">
              <div class="input-group-prepend">
                <div class="input-group-text"><i class="fas fa-search"></i></div>
              </div>
              <input type="text" class="form-control" placeholder="Buscar..." name="filtrarUsuario" [(ngModel)]="filtrarUsuario">
            </div>
        </div> -->
            </div>

            <table class="table table-hover table-striped table-bordered table-sm">
                <thead>
                    <tr class="bg-primary text-light text-center">
                        <th scope="col">Isla</th>
                        <th scope="col">Sectores</th>
                        <th scope="col">Acción</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let isla of islas | slice: (page - 1) * itemsPerPage : (page - 1) * itemsPerPage + itemsPerPage" class="text-center">

                        <td>{{isla.idIsla}}</td>
                        <td><span *ngFor="let s of isla.sectores, let i = index">{{s.idSector}}<span *ngIf="i != isla.sectores.length-1"> - </span></span></td>
                        <td>
                            <div class="d-flex justify-content-center align-items-center">
                                <a [routerLink]="[ '/sectores', isla.idIsla]" class="mr-3"><span class="icon">Ver Sectores</span></a>

                               <!--  <a [routerLink]="[ '/islas']"><span class="icon"> <i class="far fa-edit"></i></span></a> -->

                                <button (click)="confirmDel(isla)" class="btn  btn-default"><span class="icon"> <i class="far fa-trash-alt"></i></span></button>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="mt-3" *ngIf="verPaginator">
                <ngb-pagination
                  [collectionSize]="totalItems"
                  [(page)]="page"
                  [pageSize]="itemsPerPage"
                  [maxSize]="7"
                  [rotate]="true"
                  [boundaryLinks]="true"
                >
                </ngb-pagination>
              </div>
        </div>
    </div>



</div>
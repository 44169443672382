<!-- Todos los logueados pueden listar pacientes -->
<div *ngIf="identity">
    <div class="card">
        <div class="card-header">
            <div class="page-head">
                <h3>{{title}}</h3>
            </div>
        </div>
        <div class="card-body">
            <div class="alert alert-danger" *ngIf="errorMessage">
                {{errorMessage}}
            </div>
            <div class="row">
                <div class="col">
                    <div class="form-group">
                        <a [routerLink]="[ '/episodio-create' ]" class="btn btn-primary">
                            <span class="mr-2"><i class="fa fa-plus"></i></span>
                            Nuevo Episodio
                        </a>
                    </div>
                </div>
                <div class="col">
                    <div class="row justify-content-end m-0">
                        <div>
                            <button  id="ver" name="ver"  class=" btn btn-secondary btn-sm"
                                (click)="verTodos=!verTodos"
                                >
                                {{ verTodos ? 'Ver Solo Activos' : 'Ver Todos' }}
                            </button>
                           
                            
                        </div>
                    </div>
                </div>
                <!-- <div class="col">
                  <div class="input-group mb-2 mr-sm-2">
                      <div class="input-group-prepend">
                          <div class="input-group-text"><i class="fas fa-search"></i></div>
                      </div>
                      <input type="text" class="form-control" placeholder="Buscar..." name="filtrarEpisodio"
                          [(ngModel)]="filtrarEpisodio">
                  </div>
              </div> -->
            </div>
            <table class="table table-hover table-striped table-bordered">
                <thead>
                    <tr class="bg-primary text-light text-center">
                        <th scope="col">Hist.Clínica</th>
                        <th scope="col">Ingreso</th>
                        <th scope="col">Egreso</th>
                        <th scope="col">Razón Egreso</th>
                        <th scope="col">Opciones</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let episodio of episodios | filterEpisodio:verTodos" class="text-center">
                        <th scope="row">{{episodio.numeroHC}}</th>
                        <td>{{episodio.fechaIngreso | date:'dd-MM-yyyy'}}</td>
                        <td>{{episodio.fechaEgreso | date:'dd-MM-yyyy'}}</td>
                        <td>{{episodio.razonEgreso}}</td>
                        <td>
                            <div class="d-flex justify-content-around">
                                <a (click)="cerrarEpisodio(episodio)" data-toggle="tooltip" data-placement="bottom"
                                    title="Cerrar el episodio" *ngIf="episodio.fecha_egreso == null">
                                    <span style="color:rgb(26, 25, 25)">Cerrar episodio</span>
                                </a>
                            </div>
                        </td>
                    </tr>
                </tbody>

            </table>
        </div>
    </div>

</div>
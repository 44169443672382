import { Component, OnInit } from '@angular/core';

import { UsuarioService} from '../../services/usuario.service';
import { Router} from '@angular/router'

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [ UsuarioService]
})
export class HomeComponent implements OnInit {

  title;
  hospital;
  identity;
  token;
  cargarGrafico: boolean = false;
  
  constructor(
    private _usuarioService: UsuarioService,
    private _router: Router
  ) { }

  ngOnInit(): void {
    this.hospital = this._usuarioService.getHospital();
    this.identity = this._usuarioService.getIdentity();

    if(!this.identity){
      this._router.navigate(['/'])
    }
    this.title = this.hospital.nombre;
    setTimeout(()=>{
      this.cargarGrafico = true;
    }, 500)
  
  }

 



}

import { Component, OnInit } from '@angular/core';

import { UsuarioService } from './services/usuario.service';
import { HospitalService } from './services/hospital.service';

import { Usuario} from './models/usuario';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [UsuarioService, HospitalService, ToastrService]
})
export class AppComponent implements OnInit{
  public title = 'Covindex';
  public user: Usuario;
  public identity;
  public token;
  public errorMessage = '';
  public hospital;
  public hospitales;
  public roles;

  constructor(
    public _usuSvc: UsuarioService,
    private _hospitalService: HospitalService,
    private _toastr: ToastrService,
  ){
    this.errorMessage = '';
    this.user = new Usuario('','','','','','');
  }

  ngOnInit(){

    this.identity = this._usuSvc.getIdentity();
    this.token = this._usuSvc.getToken();
    this.hospital = this._usuSvc.getHospital();
    this.hospitales = JSON.parse(localStorage.getItem('hospitales'));

    this._usuSvc.connect(this.hospital.idHospital, this.hospital.sectores[0].idSector).subscribe(
      resp => {
        if(resp['resp'] == "403 Forbidden"){
          this.identity = null;
        }
        
      }, error => console.log(error)
      
    )

  }
  
  getHospital(){
    this._hospitalService.getAll().subscribe(
      resp => {
        this.hospital = resp['result'].data[0];
        localStorage.setItem('hospital', JSON.stringify(this.hospital))
        console.log('respuesta getHospital', resp);
        

      }, error => {
        console.log(error);
        this._toastr.warning('La sesión expiró. Por favor ingrese nuevamente')
        this.logout();
        
      }
    )
  }

  public onSubmit(){

    console.log('Datos enviados al login', this.user);

    /* Identificar el usuario si las credenciales son correctas obtnemos un token y los datos del usuario */
    this._usuSvc.singUp(this.user).subscribe(
      response => { 
        console.log('Respuesta loguin', response);
        
        if(response['resp'].includes('200')){ 
          console.log('=========llega=========', response['resp']);
               
        this.identity = response['result'].usuario;
        this.token = response['result'].token; //response['token'];
        
        this.hospitales = response['result'].hospitales; //response['hospital'];
        
       
        this.hospital = this.hospitales[0]; //response['hospital'];
        
        
        //this.errorMessage = '';
        localStorage.setItem('identity', JSON.stringify(this.identity));
        localStorage.setItem('token', this.token);      
        localStorage.setItem('hospital', JSON.stringify(this.hospital));
        localStorage.setItem('hospitales', JSON.stringify(this.hospitales));
        this._usuSvc.token = this.token;
          
        // this._usuSvc.connect(this.hospital.idHospital, this.hospital.islas[0].sectores[0].idSector, this.hospital.islas[0].idIsla).subscribe(
        this._usuSvc.connect(this.hospital.idHospital, this.hospital.sectores[0].idSector).subscribe(
          resp => {
            console.log("Respuesta del Connect==>",resp);
            
          }, error => console.log(error)
          
        )
      }else{
      
        this.errorMessage = 'El email o la contraseña no son correctos.'
      }



    },
    error => {
      this.errorMessage = <any>error.error.message;
      if (this.errorMessage != null) {
        console.log(error.error);
      }
    }
  );   
    
  }

  public logout(){
    this._usuSvc.logout();

    this.identity = null;
    this.token = null;
    this.user = new Usuario('','','','','','');
    
  }

  onHospitalChange(e){
    console.log(e);
    
  }
}

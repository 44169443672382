<!-- Enfermero puede cargar un usuario -->
<div *ngIf="identity">
    <form #createIslaForm="ngForm" (ngSubmit)="onSubmit()">
        <div class="card">
            <div class="card-header">
                <div class="page-head py-3">
                    <h3>{{title}}</h3>
                </div>
            </div>
            <div class="card-body">
                <div class="alert alert-danger" *ngIf="errorMessage">
                    {{errorMessage}}
                </div>

                <div class="row">
                    <div class="col">
                        <div class="form-group">
                            <label for="idHospital">Hospital: {{hospital.nombre}}</label>
                            <input type="text" hidden class="form-control" #idHospital="ngModel" name="idHospital" [(ngModel)]="isla.idHospital"
                                readonly>
                            <!-- <span *ngIf="!idHospital.valid && idHospital.touched" class="text-danger">
                                El hospital es obligatorio
                            </span> -->
                        </div>
                    </div>
                    <div class="col">
                        <div class="form-group">
                            <label for="idIsla">Isla:</label>
                            <input type="text" class="form-control" #idIsla="ngModel" name="idIsla" [(ngModel)]="isla.idIsla"
                                required>
                            <span *ngIf="!idIsla.valid && idIsla.touched" class="text-danger">
                                La isla es obligatoria
                            </span>
                        </div>
                    </div>
    
                </div>
            </div>
            <div class="card-footer">      
                <div class="form-group d-flex justify-content-end">
                    <input type="submit" class="btn btn-primary" value="Ingresar">
                    <a [routerLink]="[ '/islas' ]" class="btn btn-secondary ml-3">Cancelar</a>
                </div>
            </div>
        </div>
        
    </form>
   
</div>
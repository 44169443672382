export class Sector {
    constructor(
      public idHospital: number,
      public idSector: string,
      public descripcion: string,
      public sync_id?: number,
      public idIsla?: string,
      public actual?: number,
      public cantCamas?: number,
      public cantCamasLibres?: number,

    ){}
}

import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UsuarioService } from '../services/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class IsJefeGuard implements CanActivate {
  constructor(
    private _usuarioSvc: UsuarioService
  ){}
  canActivate(): boolean {

    var resp = false;
    if(this._usuarioSvc.isJefeClinicaMedica || this._usuarioSvc.isJefeEnfermeria || this._usuarioSvc.isJefeLaboratorio || this._usuarioSvc.isAdmin) resp = true;
    console.log('usuario jefe==>', resp);
    
    return resp
  }
  
}

<div class="card shadow">
    <div class="card-body">

      <div class="d-flex flex-column justify-content-center align items-center">
        <p class="text-center free-camas-title">Camas disponibles</p>
        <div class="text-center free-camas-counter"><a [routerLink]='["/camas"]'>{{libres}}</a></div>
        <h6 class="text-center total-camas-counter">Total: {{total}}</h6>
      </div>

      <div style="display: block;" *ngIf="camas;else loading">
        <highcharts-chart
          [Highcharts]="Highcharts"
          [options]="chartOptions"

          style="width: 100%; height: 200px; display: block;"
        ></highcharts-chart>
      </div>

    </div>
  </div>

  <ng-template class="text-center" #loading>
    <div class="d-flex justify-content-center align-items-center p-5">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Cargando...</span>
      </div>
    </div>
  </ng-template>

import { Component, OnInit } from '@angular/core';
import { UsuarioService } from 'src/app/services/usuario.service';
import { Episodio } from 'src/app/models/episodio';
import { EpisodioService } from 'src/app/services/episodio.service';
import { Paciente } from 'src/app/models/paciente';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { isNull } from 'lodash';
import { ABMBaseService } from 'src/app/services/ABMBase.service';

@Component({
  selector: 'app-create-episodio',
  templateUrl: './create-episodio.component.html',
  styleUrls: ['./create-episodio.component.css'],
  providers: [ UsuarioService, ABMBaseService, EpisodioService, ToastrService]
})
export class CreateEpisodioComponent implements OnInit {

  title: string;
  errorMessage: string = '';
  identity;
  hospital;
  pacientes: any[];
  paciente: Paciente = null;
  episodios: Episodio[];
  episodio: Episodio;
  dni;
  edad: number;
  cargarPaciente: boolean = false;
  verPaciente: boolean = false;
  internado: boolean = false;
  showIngresoButton: boolean = false;
  allowAceptar: boolean = false;
  numeroHC: any;

  constructor(
    private _usuarioService: UsuarioService,
    public _abmSvc: ABMBaseService,
    private _episodioService: EpisodioService,
    private _router: Router,
    private _toastr: ToastrService
  ) { }

  ngOnInit() {

   
    this.title = 'Ingreso de Paciente';
    this.identity = this._usuarioService.getIdentity();
    this.hospital = this._usuarioService.getHospital();
    console.log(this.hospital);
    
    this.episodio = new Episodio(this.hospital.idHospital,'',null,null,'',this.identity.cuil)
    this.getPacientes();

  }

  /**
   * Obtiene todos los pacientes cargados en la base de datos
   */
  getPacientes(){
    this._abmSvc.getAll('hcpacientes').subscribe(
      response => {
        console.log('respuesta de todos los pacientes', response);
        
        if (response['result'].data) {
          this.pacientes = response['result'].data;

          this.getEpisodios();

        }else{
          //this._usuarioService.logout()
        }
      }, error => {
        this.errorMessage = 'Error al guardar episodio';
        console.log(error.message)
        //this._usuarioService.logout();
      }
    )
  }

  /**
   * Obtiene todos los episodios cargados en la base de datos
   */
  getEpisodios(){
    this._episodioService.getAll('episodios').subscribe(
      response => {
        this.episodios = response['result'].data;
      }, error => {
        this.errorMessage = 'Error al buscar los episodios';
        console.log(error.message)
      }
    )
  }

  /**
   * Si ya esta seleccionado un paciente, abre un episodio y lo registra en la base de datos
   */
  onSubmit(){
    if (!this.paciente || this.paciente.numeroHC == '') {
      this.errorMessage = 'Por favor, seleccionar un paciente';
    } else {

      this.errorMessage = '';
      
      var fecha = moment().unix();

      this.episodio = new Episodio(this.hospital.idHospital, this.paciente.numeroHC,fecha,null,'',this.identity.cuil);
      console.log('Episodio en carga==>', this.episodio);
      

      this._episodioService.create('episodio', this.episodio).subscribe(
        response => {
          this._router.navigate(['/pacientes']);
        }, error => {
          this.errorMessage = 'Error al guardar el episodio';
          console.log(error.message);
        }
      )
    }
  }

  /**
   * Carga un paciente nuevo u lo deja seleccionado para que se pueda generar un episodio
   */
  onSubmitPaciente(){
    
    console.log('paciente a guardar', this.paciente);
    
    var val = this.validatePaciente(this.paciente);

    if(val.verified){

      var unPaciente = this.pacientes.filter(item => item.numeroHC == this.paciente.numeroHC);
      console.log('Encontro un paciente->', unPaciente);
      
      if(unPaciente.length > 0){

        this._toastr.error('Ya existe esa Historia Clínica');
        
      }else{


        const fechaIngresada: any = this.paciente.fechaNac;

        this.paciente.fechaNac = moment(this.paciente.fechaNac).unix();
        
        this._abmSvc.create('hcpaciente',this.paciente).subscribe(
          response => {
            console.log('respuesta de la carga de paciente', response);
            if(response['result'].sync_id){
              var id = response['result'].sync_id;
              this._abmSvc.getAll('pacientes', id).subscribe(
                response => {
  
                  console.log('respuesta de recupero de paciente', response);
                  
                  this.paciente = response['result'].data[0];
                  this.edad = this._abmSvc.getEdad(this.paciente.fechaNac);
                  this.verPaciente = true;
                  this.cargarPaciente = false;
                  this.onSubmit();

                }, error => console.log(error)
              )

            }else{
              this._toastr.error('Error en el guardado del paciente');
              if(response['result'].error) this._toastr.error(response['result'].error);
              this.paciente.fechaNac = fechaIngresada;
            }
            
          }, error => {
              this.errorMessage = 'Error al guardar el paciente';
              console.log(error.message);
            }
        )

      }
    }else{
      this._toastr.error(val.msg);
    }
}

  validatePaciente(paciente: Paciente){
    if(!paciente.numeroHC || paciente.numeroHC == ''){return {verified: false, msg: 'Falta ingresar el número de Historia clínica'}}
    if(!paciente.dni || paciente.dni == null) {return {verified: false, msg: 'Falta ingresar el número de documento'}}
    if(!(/^[0-9]+$/i).test(paciente.dni.toString())) {return {verified: false, msg: 'El dni no tiene un formato válido'}}
    if(!paciente.paisExp || paciente.paisExp == null) {return {verified: false, msg: 'Falta ingresar el país de expedición'}}
    if(!paciente.fechaNac || paciente.fechaNac == null) {return {verified: false, msg: 'Falta ingresar la fecha de nacimiento'}}
    if(!paciente.nombre || paciente.nombre == null) {return {verified: false, msg: 'Falta ingresar el nombre'}}
    if(!paciente.apellido || paciente.apellido == null) {return {verified: false, msg: 'Falta ingresar el apellido'}}
    if(!paciente.nacionalidad || paciente.nacionalidad == null) {return {verified: false, msg: 'Falta ingresar la nacionalidad'}}
    if(!paciente.genero || paciente.genero == null) {return {verified: false, msg: 'Falta ingresar el género'}}
    if(!paciente.calle || paciente.calle == null) {return {verified: false, msg: 'Falta ingresar la calle'}}
    if(!paciente.numero || paciente.numero == null) {return {verified: false, msg: 'Falta ingresar el número'}}
    if(!paciente.CP || paciente.CP == null) {return {verified: false, msg: 'Falta ingresar el código postal'}}
    if(!paciente.telefono || paciente.telefono == null) {return {verified: false, msg: 'Falta ingresar el teléfono'}}

    return {verified: true, msg: 'Datos del paciente completos'}
  }

  /**
   * Al ingresar el dni del paciente, chequea si ya esta cargado y muestra los datos,
   * de lo contrario, muestra el formulario de carga de un paciente.
   */
  dniChange(): void {

    this.paciente = null;
    if (this.dni != '' && !isNull(this.dni)) {

      this.pacientes.forEach(element => {
        if(element.dni == this.dni){
          this.paciente = element;
          this.edad = this._abmSvc.getEdad(element.fechaNac);
        }
      });

      if(this.paciente){

        var episodioAbierto = this.episodios.filter(item => item.numeroHC == this.paciente.numeroHC && item.fechaEgreso == null);
        
        if (episodioAbierto.length > 0) {
          this.internado = true;
          this.showIngresoButton = false
        }else{
          this.internado = false;
          this.showIngresoButton = true
        }
      
        this.verPaciente = true,
        this.cargarPaciente = false;


      }else{
        this.cargarPaciente = true;
        this.verPaciente = false;
        this.paciente = new Paciente(this.hospital.idHospital,'','DNI','Argentina',this.dni,'','','Argentina','','',null,'',null,null,'','','','',null,null,null,this.identity.cuil,null,null,null,null,null,null)
        console.log('Paciente-->', this.paciente);
      }
    }else{
      this.verPaciente = false;
      this.cargarPaciente = false;
    }
  }
  
  
}

import { Component, OnInit } from '@angular/core';

import { CamaService } from 'src/app/services/cama.service'
import { UsuarioService } from 'src/app/services/usuario.service'
import { ToastrService } from 'ngx-toastr'

import { Cama } from 'src/app/models/cama'
import { ActivatedRoute } from '@angular/router';
import { Sector } from 'src/app/models/sector';
import { SectorService } from 'src/app/services/sector.service';
import { ABMBaseService } from 'src/app/services/ABMBase.service';

@Component({
	selector: 'app-cama',
	templateUrl: './cama.component.html',
	styleUrls: ['./cama.component.css'],
	providers: [UsuarioService, CamaService, ToastrService, SectorService]
})

export class CamaComponent implements OnInit {

	identity: any;
	hospital: any;
	camas: Cama[];
	filtrarCama: string = '';
	title: string;

	verTodas:  boolean;

	orderAsc: boolean;
	orderColumn: string;
	orderDirection: string;

	page: any;
	itemsPerPage: number;
	totalItems: number;
	verPag:boolean = false;


	idSector: string;
	sector: Sector;

	constructor(
		private _usaurioService: UsuarioService,
		private _camaService: CamaService,
		private _toastr: ToastrService,
		private _activeRoute: ActivatedRoute,
		private _sectorService: SectorService,
		private _abmBaseSvc: ABMBaseService
	) { }

	ngOnInit(): void {
		this.verTodas = false;
		this.idSector = this._activeRoute.snapshot.params.idSector;
		
		this.identity = this._usaurioService.getIdentity();
		this.hospital = this._usaurioService.getHospital();
		
		if(!this.idSector){

			this.verTodas = true;
			this.title = "Listado de Camas";
		} else {
			this.title = `Camas - Sector: ${this.idSector}`
			this.getSector();
		}

		this.getCamas();

		this.orderAsc = false;
		this.page = 1;
		this.itemsPerPage = 15;
		
	}

	getSector(){

		this._abmBaseSvc.getAll('sectores').subscribe(
			resp => {
				this.sector = resp['result'].data.filter(item => item.idSector == this.idSector )[0];
			
			}, error => {console.log(error)}

		)
	}


	getCamas() {
		this._abmBaseSvc.getAll('camas').subscribe(
			camas => {

				if (camas['result'].data) {
					console.log('camas',camas['result'].data);

					if(this.verTodas){
						this.camas = camas['result'].data;
					}else{
						this.camas = camas['result'].data.filter(item => item.idSector == this.idSector);
					}
					
					this.totalItems = this.camas.length
					if(this.itemsPerPage < this.totalItems) { this.verPag = true}
				} else {
					this._toastr.warning('no hay camas cargadas')
				}

			}, error => {
				this._toastr.error('Error al obtener las camas')
			}
		)
	}


	/** Ordena los datos de acurdo a las columnas que se seleccione
	*/
	order(column: string) {
		this.orderAsc = !this.orderAsc;

		if (this.orderAsc) {
			this.orderDirection = 'asc'
		} else {
			this.orderDirection = 'desc'
		}
		
		this.orderColumn = column;

	}

	idCama: string;
	mas:boolean = true;

	onCamaSubmit(){
		
		if(this.camas.length > 0 && this.camas.filter(item => item.idCama == this.idCama).length > 0){
			this._toastr.error('Ya existe esta cama en este sector');
			this.idCama = '';
		}else{
			var newCama = {
				"idHospitalCama":this.hospital.idHospital,
				"idSector": this.idSector,
				"idCama": this.idCama,
				"numeroHCPac": '',
				"ubicacionX": null,
				"ubicacionY": null,
				"orientacion": '',
				"estado": 1,
				"sync_id": null
			  }
			  var laCama = new Cama(
				this.hospital.idHospital,
			   this.idSector,
			  this.idCama,
			  '',
			  null,
			 null,
			 null,
			 1,
			 null)
	
			console.log('cama a cargar', laCama);
			
			this._abmBaseSvc.create('cama', laCama).subscribe(
				resp => {

					this.getCamas();
					this.idCama = ''

				}, error => {
					console.log(error);
					
				}
			)
		}
		
	}

}
